var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.artistName,
            expression: "artistName"
          }
        ],
        staticClass: "input has-icons-left",
        attrs: { type: "text", placeholder: "Artist Name" },
        domProps: { value: _vm.artistName },
        on: {
          keyup: _vm.searchArtists,
          blur: _vm.addArtist,
          input: function($event) {
            if ($event.target.composing) return
            _vm.artistName = $event.target.value
          }
        }
      })
    ]),
    _vm.foundArtists && (_vm.foundArtists.length > 0 || _vm.artistName)
      ? _c(
          "div",
          { staticClass: "results" },
          [
            _vm._l(_vm.foundArtists, function(artist) {
              return _c(
                "div",
                {
                  key: artist.id,
                  staticClass: "columns selectable",
                  on: {
                    click: function($event) {
                      return _vm.setArtist(artist)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "column is-narrow" }, [
                    _c("div", { staticClass: "avatar-generic" }, [
                      _c("img", { attrs: { src: artist.image, alt: "" } })
                    ])
                  ]),
                  _c("div", { staticClass: "column" }, [
                    _c("p", { staticClass: "artistname" }, [
                      _c("strong", [_vm._v(_vm._s(artist.name))])
                    ])
                  ]),
                  _c("div", { staticClass: "column" }, [
                    _c("p", { staticClass: "managedby" }, [
                      _vm._v(" " + _vm._s(_vm.getowners(artist)) + " ")
                    ])
                  ])
                ]
              )
            }),
            _vm.artistName != ""
              ? _c(
                  "div",
                  {
                    staticClass: "columns selectable",
                    on: { click: _vm.addArtist }
                  },
                  [
                    _vm._m(0),
                    _c("div", { staticClass: "column" }, [
                      _c("p", { staticClass: "artistname" }, [
                        _c("strong", [_vm._v(_vm._s(_vm.artistName))])
                      ])
                    ]),
                    _vm._m(1)
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("div", { staticClass: "avatar-generic" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column" }, [
      _c("p", { staticClass: "managedby" }, [_vm._v("New Artist Profile")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }