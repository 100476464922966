var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tier-row" }, [
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "field" }, [
              _vm.type != "donation"
                ? _c("label", { staticClass: "label" }, [
                    _vm._v("Name of Tier")
                  ])
                : _vm._e(),
              _vm.type == "donation"
                ? _c("label", { staticClass: "label" }, [
                    _vm._v("Donation Title")
                  ])
                : _vm._e(),
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tier.name,
                      expression: "tier.name"
                    }
                  ],
                  staticClass: "input is-primary",
                  attrs: {
                    type: "text",
                    placeholder: "Name of Tier",
                    disabled: _vm.disable
                  },
                  domProps: { value: _vm.tier.name },
                  on: {
                    change: _vm.changed,
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.tier, "name", $event.target.value)
                    }
                  }
                })
              ]),
              false
                ? _c("p", { staticClass: "help is-danger" }, [
                    _vm._v(" Please enter a name for this tier. ")
                  ])
                : _vm._e()
            ])
          ]),
          _vm.type != "donation"
            ? _c("div", { staticClass: "column is-one-quarter" }, [
                _c("div", { staticClass: "field" }, [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Quantity Type")
                  ]),
                  _c("div", { staticClass: "select is-fullwidth" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tier.quantityType,
                            expression: "tier.quantityType"
                          }
                        ],
                        attrs: { disabled: _vm.disable },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.tier,
                                "quantityType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.changed
                          ]
                        }
                      },
                      [
                        !_vm.tier.feeType
                          ? _c("option", { attrs: { value: "" } })
                          : _vm._e(),
                        _c("option", { attrs: { value: "fixed" } }, [
                          _vm._v("Reserved")
                        ]),
                        _c("option", { attrs: { value: "flexible" } }, [
                          _vm._v("Shared")
                        ])
                      ]
                    )
                  ]),
                  false
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(" Please select a quantity type. ")
                      ])
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm.type != "donation"
            ? _c("div", { staticClass: "column is-one-quarter" }, [
                _c("div", { staticClass: "field" }, [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Tickets Available")
                  ]),
                  _c("div", { staticClass: "control" }, [
                    _vm.tier.quantityType == "fixed"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tier.capacity,
                              expression: "tier.capacity"
                            }
                          ],
                          staticClass: "input is-primary",
                          attrs: {
                            type: "number",
                            min: "1",
                            max: "10000",
                            step: "1",
                            placeholder: "# available",
                            disabled: _vm.disable
                          },
                          domProps: { value: _vm.tier.capacity },
                          on: {
                            change: _vm.changed,
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.tier,
                                "capacity",
                                $event.target.value
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.tier.quantityType == "flexible"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.flexible,
                              expression: "flexible",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "input is-primary",
                          attrs: {
                            disabled: "true",
                            type: "number",
                            min: "1",
                            max: "10000",
                            step: "1",
                            placeholder: "# available"
                          },
                          domProps: { value: _vm.flexible },
                          on: {
                            change: _vm.changed,
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.flexible = _vm._n($event.target.value)
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      : _vm._e()
                  ]),
                  false
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(" Please enter a name for this tier. ")
                      ])
                    : _vm._e()
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column is-one-half" }, [
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Tier Description (optional)")
              ]),
              _c("div", { staticClass: "control" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tier.description,
                      expression: "tier.description"
                    }
                  ],
                  staticClass: "textarea is-fullwidth",
                  attrs: { disabled: _vm.disable },
                  domProps: { value: _vm.tier.description },
                  on: {
                    change: _vm.changed,
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.tier, "description", $event.target.value)
                    }
                  }
                })
              ]),
              false
                ? _c("p", { staticClass: "help is-danger" }, [
                    _vm._v(" Please enter a name for this tier. ")
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "column is-one-quarter" }, [
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Fee Type")]),
              _c("div", { staticClass: "select is-fullwidth" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tier.feeType,
                        expression: "tier.feeType"
                      }
                    ],
                    attrs: { disabled: _vm.disable },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.tier,
                            "feeType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.changed
                      ]
                    }
                  },
                  [
                    !_vm.tier.feeType
                      ? _c("option", { attrs: { value: "" } })
                      : _vm._e(),
                    _vm.type != "donation"
                      ? _c("option", { attrs: { value: "fixed" } }, [
                          _vm._v("Fixed price")
                        ])
                      : _vm._e(),
                    _c("option", { attrs: { value: "sliding" } }, [
                      _vm._v("Sliding Scale")
                    ]),
                    _vm.type != "donation"
                      ? _c("option", { attrs: { value: "free" } }, [
                          _vm._v("Free")
                        ])
                      : _vm._e(),
                    _vm.type == "donation"
                      ? _c("option", { attrs: { value: "free" } }, [
                          _vm._v("Preset Tiers")
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              false
                ? _c("p", { staticClass: "help is-danger" }, [
                    _vm._v(" Please select a fee type. ")
                  ])
                : _vm._e()
            ]),
            _vm.tier.feeType == "sliding"
              ? _c("div", { staticClass: "field" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Suggested")]),
                  _c("div", { staticClass: "control has-icons-left" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.tier.price,
                          expression: "tier.price",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "input is-primary",
                      attrs: {
                        type: "number",
                        min: "1",
                        max: "10000",
                        step: "1",
                        placeholder: "price",
                        disabled: _vm.disable
                      },
                      domProps: { value: _vm.tier.price },
                      on: {
                        change: _vm.changed,
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.tier,
                            "price",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._m(0)
                  ])
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "column is-one-quarter" }, [
            _vm.tier.feeType == "fixed"
              ? _c("div", { staticClass: "field" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Price")]),
                  _c("div", { staticClass: "control has-icons-left" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number.lazy",
                          value: _vm.tier.price,
                          expression: "tier.price",
                          modifiers: { number: true, lazy: true }
                        }
                      ],
                      staticClass: "input is-primary",
                      attrs: {
                        type: "number",
                        min: "1",
                        max: "10000",
                        step: "1",
                        placeholder: "price",
                        disabled: _vm.disable
                      },
                      domProps: { value: _vm.tier.price },
                      on: {
                        change: [
                          function($event) {
                            _vm.$set(
                              _vm.tier,
                              "price",
                              _vm._n($event.target.value)
                            )
                          },
                          _vm.changed
                        ],
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._m(1)
                  ]),
                  _vm.tier.price && _vm.tier.price < 5
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(" Minimum ticket price is $5 (for now). ")
                      ])
                    : _vm._e(),
                  false
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(" Please enter a valid price. ")
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.tier.feeType == "sliding"
              ? _c("div", { staticClass: "field" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Minimum")]),
                  _c("div", { staticClass: "control has-icons-left" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number.lazy",
                          value: _vm.tier.minPrice,
                          expression: "tier.minPrice",
                          modifiers: { number: true, lazy: true }
                        }
                      ],
                      staticClass: "input is-primary",
                      attrs: {
                        type: "number",
                        min: "5",
                        max: "10000",
                        step: "1",
                        placeholder: "price",
                        disabled: _vm.disable
                      },
                      domProps: { value: _vm.tier.minPrice },
                      on: {
                        change: [
                          function($event) {
                            _vm.$set(
                              _vm.tier,
                              "minPrice",
                              _vm._n($event.target.value)
                            )
                          },
                          _vm.changed
                        ],
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._m(2)
                  ]),
                  _vm.tier.minPrice && _vm.tier.minPrice < 5
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(" Minimum ticket price is $5 (for now). ")
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.tier.feeType == "sliding"
              ? _c("div", { staticClass: "field" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Maximum")]),
                  _c("div", { staticClass: "control has-icons-left" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number.lazy",
                          value: _vm.tier.maxPrice,
                          expression: "tier.maxPrice",
                          modifiers: { number: true, lazy: true }
                        }
                      ],
                      staticClass: "input is-primary",
                      attrs: {
                        type: "number",
                        min: "1",
                        max: "10000",
                        step: ".01",
                        placeholder: "price",
                        disabled: _vm.disable
                      },
                      domProps: { value: _vm.tier.maxPrice },
                      on: {
                        change: [
                          function($event) {
                            _vm.$set(
                              _vm.tier,
                              "maxPrice",
                              _vm._n($event.target.value)
                            )
                          },
                          _vm.changed
                        ],
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._m(3)
                  ]),
                  _vm.tier.maxPrice && _vm.tier.maxPrice <= _vm.tier.minPrice
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(
                          " Maximum price must be more than the minimum price. "
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm.type != "donation"
        ? _c("div", { staticClass: "column is-narrow" }, [
            _c("div", { staticClass: "column is-narrow" }, [
              !_vm.disable
                ? _c(
                    "span",
                    {
                      staticClass: "icon is-medium delete",
                      on: { click: _vm.deleted }
                    },
                    [_c("i", { staticClass: "fas fa-lg fa-trash" })]
                  )
                : _vm._e()
            ])
          ])
        : _vm._e()
    ]),
    _c("div", [
      _c("label", { staticClass: "checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.tier.selectDates,
              expression: "tier.selectDates"
            }
          ],
          attrs: { type: "checkbox", disabled: _vm.disable },
          domProps: {
            checked: Array.isArray(_vm.tier.selectDates)
              ? _vm._i(_vm.tier.selectDates, null) > -1
              : _vm.tier.selectDates
          },
          on: {
            change: function($event) {
              var $$a = _vm.tier.selectDates,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(_vm.tier, "selectDates", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.tier,
                      "selectDates",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.tier, "selectDates", $$c)
              }
            }
          }
        }),
        _vm._v(" Apply tier to select dates only ")
      ])
    ]),
    _vm.tier.selectDates
      ? _c(
          "div",
          { staticClass: "dates" },
          _vm._l(_vm.dates, function(date) {
            return _c(
              "div",
              {
                key: date.id,
                staticClass: "date",
                class: {
                  selected: _vm.tier.selectedDates.indexOf(date.id) > -1
                },
                on: {
                  click: function($event) {
                    return _vm.toggleDate(date)
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.shortDate(date.startDate)) + " ")]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }