<template>
  <div class="control has-icons-left">
    <GmapAutocomplete
      class="input"
      @place_changed="setPlace"
      :fields="fields"
    />
    <span class="icon is-small is-left">
      <i class="fas fa-search"></i>
    </span>
  </div>
</template>

<script>
import Bugsnag from "@bugsnag/browser";

export default {
  name: "venueFinder",
  data() {
    return {
      locationError: "",
      lastName: "",
      email: "",
      quantity: 1,
      note: "",
      emailValid: true,
      fields: [
        "place_id",
        "name",
        "formatted_phone_number",
        "website",
        "formatted_address",
        "address_components",
        "geometry"
      ]
    };
  },
  methods: {
    setPlace(e) {
      console.log(e);
      if (!e.address_components || e.address_components.length < 7) {
        this.locationError =
          "Invalid location. Location must have a valid address.";
        this.$analytics.logEvent("invalid_event_address", { address: e });
        Bugsnag.notify("Invalid address response.", e);
      } else {
        // An eight-part address contains a neighborhood. A seven-part does not.
        var offset = e.address_components.length == 7 ? 2 : 3;
        this.locationError = "";
        const venue = {
          id: e.place_id,
          name: e.name,
          phone: e.formatted_phone_number ? e.formatted_phone_number : null,
          website: e.website ? e.website : null,
          fullAddress: e.formatted_address,
          location: {
            lat: e.geometry.location.lat(),
            lng: e.geometry.location.lng()
          },
          address:
            e.address_components[0].long_name +
            " " +
            e.address_components[1].long_name,
          city: e.address_components[offset].long_name,
          state: e.address_components[offset + 2].long_name,
          zip: e.address_components[offset + 4].long_name
        };

        this.$emit("venueFound", venue);
      }
    }
  }
};
</script>

<style></style>
