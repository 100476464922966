<template>
    <div>
        <h2>Orders</h2>
        <div style="margin-bottom: 20px; display: flex">
          <div style="flex:1; margin-right: 20px;" class="control has-icons-left">
            <span class="icon is-small is-right">
              <i class="fas fa-search"></i>
            </span>
            <input
              class="input has-icons-left"
              type="text"
              v-model="orderSearch"
              v-on:keyup="searchOrders"
              placeholder="Search by name or email"
            />
          </div>
          <div><button class="button" @click="exportCsv">Export CSV</button></div>
        </div>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Order Created</th>
              <th>Name</th>
              <th>Email</th>
              <th>Discount Code</th>
              <th>Qty</th>
              <th>Donation</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in visibleOrders" :key="order.id">
              <td>{{ (order.created) ? moment(order.created.toDate()).format("M/D/YY LT") : "" }}</td>
              <td>{{ order.firstName }} {{ order.lastName }}</td>
              <td>{{ order.email }}</td>
              <td>{{ order.discountCode }}</td>
              <td>{{ order.quantity }}</td>
              <td>{{ order.donation }}</td>
              <td>${{ order.total.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
        <Paginator @pageChanged="setOrdersPage" :pageSize="10" :itemCount="(filteredOrders.length > 0) ? filteredOrders.length : orders.length" />
    </div>
</template>

<script>
import moment from "moment";
import Paginator from "@/components/paginator";

export default {
  data: function() {
    return {
        moment: moment,
        orderPage: 1,
        orderSearch: "",
        filteredOrders: [],
    };
  },
  props: {
    orders: Array,
    pageSize: Number,
    exportName: String,
  },
  components: {
    Paginator,
  },
  computed: {
    visibleOrders() {
      const showorders = (this.orderSearch != "") ? this.filteredOrders : this.orders;
      const begin = (this.orderPage - 1) * 10;
      // console.log(begin);
      const lastorder = ((begin + 10) < (showorders.length - 1)) ? begin + 10 : showorders.length;
      console.log("slicing", begin, lastorder);
      return showorders.slice(begin, lastorder);
    }
  },
  methods: {
    setOrdersPage(page) {
      // console.log("pageset", page);
      this.orderPage = page;
    },
    exportCsv() {
      console.log("exporrting");
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Order Created,Order ID,First Name,Last Name,Email,Quantity,Discount Code,Donation,Subtotal,Total,Total After Fees,Payment Intent ID\r\n";
      this.orders.forEach(function(line) {
        const net = (Math.round(line.total * 95) / 100).toFixed(2);
        csvContent += line.created.toDate() + "," + line.orderId + "," + line.firstName + "," + line.lastName + "," + line.email + ","+ line.quantity + ","
         + line.discountCode + "," + line.donation + ","  + line.subtotal + "," + line.total + "," + net + "," + line.paymentIntent + "\r\n";
      });
      // console.log(csvContent);
      // const exportname = this.event.title.replace(/ /g,"") + "_orders";
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.exportName + ".csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    searchOrders() {
      console.log("find orders", this.orderSearch);
      if (!this.orderSearch) this.filteredOrders = [];
      else {
        var foundorders = this.orders.filter(order => {
          if (
            order.email &&
            (order.email + "")
              .toUpperCase()
              .indexOf(this.orderSearch.toUpperCase()) > -1
          )
            return true;
          if (
            order.firstName &&
            (order.firstName + "")
              .toUpperCase()
              .indexOf(this.orderSearch.toUpperCase()) > -1
          )
            return true;
          if (
            order.lastName &&
            (order.lastName + "")
              .toUpperCase()
              .indexOf(this.orderSearch.toUpperCase()) > -1
          )
          return true;
          return false;
          //   string email = pass.email ? pass.email.toUpperCase : "";
          //   const first = pass.firstName ? pass.firstName.toUpperCase : "";
          //   const last = pass.lastName ? pass.lastName.toUpperCase : "";
          //   return (
          //     email.indexOf(this.passSearch.toUpperCase()) > -1 ||
          //     first.indexOf(this.passSearch.toUpperCase()) > -1 ||
          //     last.toUpperCase().indexOf(this.passSearch.toUpperCase()) > -1
          //   );
        });
        this.filteredOrders = foundorders;
      }
    },
  }
};
</script>