var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.event
    ? _c("div", { staticClass: "body-wrapper" }, [
        _c("div", { staticClass: "body-content gray" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-one-quarter" }, [
                !_vm.user.eventPublished
                  ? _c("h1", [
                      _vm._v(" Your "),
                      _c("br"),
                      _vm._v(" first "),
                      _c("br"),
                      _vm._v(" event! ")
                    ])
                  : _vm._e(),
                _vm.user.eventPublished
                  ? _c("h1", [
                      _vm._v(" Create "),
                      _c("br"),
                      _vm._v(" an "),
                      _c("br"),
                      _vm._v(" event! ")
                    ])
                  : _vm._e(),
                _vm.event.seriesId
                  ? _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "editEventSeries",
                                params: { id: _vm.event.seriesId }
                              })
                            }
                          }
                        },
                        [_vm._v("Return to series")]
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "box is-hidden-mobile" }, [
                  _c("div", { staticClass: "block" }, [
                    _c("aside", { staticClass: "menu" }, [
                      _c("p", { staticClass: "menu-label" }, [
                        _vm._v(" Event Set Up ")
                      ]),
                      _c("ul", { staticClass: "menu-list" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 1 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(1)
                                }
                              }
                            },
                            [_vm._v("Basic Info")]
                          )
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 2 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(2)
                                }
                              }
                            },
                            [_vm._v("Dates and Times")]
                          )
                        ]),
                        _c("li", [
                          !_vm.event.seriesId
                            ? _c(
                                "a",
                                {
                                  class: { "is-active": _vm.step == 3 },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStep(3)
                                    }
                                  }
                                },
                                [_vm._v("Location")]
                              )
                            : _vm._e()
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 4 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(4)
                                }
                              }
                            },
                            [_vm._v("Ticket Info")]
                          )
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 5 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(5)
                                }
                              }
                            },
                            [_vm._v("Discounts")]
                          )
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 6 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(6)
                                }
                              }
                            },
                            [_vm._v("Email Settings")]
                          )
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 7 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(7)
                                }
                              }
                            },
                            [_vm._v("Preview and Publish")]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 1,
                      expression: "step == 1"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h3", [_vm._v("Basic Info")]),
                  _c("div", { staticClass: "box" }, [
                    _c(
                      "div",
                      { ref: "croppiecont", staticClass: "field" },
                      [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Event Image")
                        ]),
                        _vm.event.image
                          ? _c("img", {
                              staticClass: "eventimageheader",
                              attrs: { src: _vm.event.image, alt: "" }
                            })
                          : _vm._e(),
                        _vm.event.image && _vm.event.status != "complete"
                          ? _c(
                              "button",
                              {
                                staticClass: "button",
                                on: {
                                  click: function($event) {
                                    _vm.event.image = null
                                  }
                                }
                              },
                              [_vm._v(" Use a Different Image ")]
                            )
                          : _vm._e(),
                        !_vm.event.image &&
                        !_vm.cropping &&
                        !_vm.showImageLoading
                          ? _c("UploadImage", {
                              attrs: {
                                id: "event",
                                outputWidth: 800,
                                outputHeight: 360
                              },
                              on: { imageCropped: _vm.crop }
                            })
                          : _vm._e(),
                        _vm.showImageLoading
                          ? _c("div", [
                              _c(
                                "progress",
                                {
                                  staticClass: "progress is-primary",
                                  attrs: { max: "100" },
                                  domProps: { value: _vm.percentComplete }
                                },
                                [_vm._v(_vm._s(_vm.percentComplete))]
                              ),
                              _c("p", [_vm._v("Uploading image...")])
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column" }, [
                        _c("div", { staticClass: "field" }, [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Event Title")
                          ]),
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.lazy",
                                  value: _vm.event.title,
                                  expression: "event.title",
                                  modifiers: { lazy: true }
                                }
                              ],
                              staticClass: "input is-primary",
                              class: { "is-danger": _vm.errors.title },
                              attrs: {
                                type: "text",
                                placeholder: "Event Title",
                                disabled: _vm.event.status == "complete"
                              },
                              domProps: { value: _vm.event.title },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.event,
                                      "title",
                                      $event.target.value
                                    )
                                  },
                                  function($event) {
                                    return _vm.saveEvent()
                                  }
                                ]
                              }
                            })
                          ]),
                          _vm.errors.title
                            ? _c("p", { staticClass: "help is-danger" }, [
                                _vm._v(" " + _vm._s(_vm.errors.title) + " ")
                              ])
                            : _vm._e()
                        ]),
                        !_vm.event.seriesId
                          ? _c("div", { staticClass: "field" }, [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("Event Type")
                              ]),
                              _c("div", { staticClass: "select" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.eventType,
                                        expression: "eventType"
                                      }
                                    ],
                                    attrs: {
                                      disabled: _vm.event.status == "complete"
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.eventType = $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function($event) {
                                          return _vm.eventTypeChange()
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "music" } },
                                      [_vm._v("Music")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "theater" } },
                                      [_vm._v("Theater")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "dance" } },
                                      [_vm._v("Dance")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "improv" } },
                                      [_vm._v("Improv")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "storytelling" } },
                                      [_vm._v("Storytelling")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "standup" } },
                                      [_vm._v("Standup Comedy")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "conferences" } },
                                      [_vm._v("Conferences/Exhibitions")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "opera" } },
                                      [_vm._v("Opera")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "music theater" } },
                                      [_vm._v("Music Theater")]
                                    ),
                                    _c("option", { attrs: { value: "art" } }, [
                                      _vm._v("Visual Art")
                                    ]),
                                    _c(
                                      "option",
                                      { attrs: { value: "gatherings" } },
                                      [_vm._v("Social Gatherings")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "sports" } },
                                      [_vm._v("Sports")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "children" } },
                                      [_vm._v("Children's Programs")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "other" } },
                                      [_vm._v("Other")]
                                    )
                                  ]
                                )
                              ]),
                              false
                                ? _c("p", { staticClass: "help is-danger" }, [
                                    _vm._v(" Please enter a name. ")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e(),
                        _vm.eventType == "other"
                          ? _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.lazy",
                                      value: _vm.event.genre,
                                      expression: "event.genre",
                                      modifiers: { lazy: true }
                                    }
                                  ],
                                  staticClass: "input is-primary",
                                  class: { "is-danger": _vm.errors.genre },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Event Type",
                                    disabled: _vm.event.status == "complete"
                                  },
                                  domProps: { value: _vm.event.genre },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.event,
                                        "genre",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm.errors.genre
                                ? _c("p", { staticClass: "help is-danger" }, [
                                    _vm._v(" " + _vm._s(_vm.errors.genre) + " ")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Artist")
                        ]),
                        _vm._l(_vm.event.artists, function(artist, index) {
                          return _c("div", { key: index }, [
                            _vm.addingArtistImage == index
                              ? _c(
                                  "div",
                                  { staticStyle: { margin: "20px 0 10px" } },
                                  [
                                    _c("UploadImage", {
                                      attrs: {
                                        outputWidth: 400,
                                        outputHeight: 400,
                                        id: "artist-" + index
                                      },
                                      on: { imageCropped: _vm.saveArtistImage }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "columns" }, [
                              _c("div", { staticClass: "column is-narrow" }, [
                                _c("div", { staticClass: "avatar-generic" }, [
                                  artist.image
                                    ? _c("img", {
                                        attrs: { src: artist.image, alt: "" }
                                      })
                                    : _vm._e(),
                                  !artist.image
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "missing-avatar",
                                          on: {
                                            click: function($event) {
                                              return _vm.addArtistImage(index)
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/img/icons/missing-image.png",
                                              alt: "add artist image"
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]),
                              _c("div", { staticClass: "column" }, [
                                _c("p", { staticClass: "artistname" }, [
                                  _c("strong", [_vm._v(_vm._s(artist.name))]),
                                  _vm._v("  |  " + _vm._s(artist.type) + " ")
                                ])
                              ]),
                              _c(
                                "div",
                                { staticClass: "column" },
                                [
                                  artist.owners
                                    ? _c("p", { staticClass: "managedby" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getowners(artist)) +
                                            " "
                                        )
                                      ])
                                    : _vm._e(),
                                  !artist.owners
                                    ? _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(artist.name) +
                                            " is not using Gobo yet. Invite them to help sell tickets with you. "
                                        )
                                      ])
                                    : _vm._e(),
                                  !artist.owners
                                    ? _c("InviteUserStarter", {
                                        attrs: {
                                          inviterName: _vm.user.name,
                                          inviterId: _vm.user.uid,
                                          profileName: artist.name,
                                          actId: artist.id,
                                          newProfile: true
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("div", { staticClass: "column is-narrow" }, [
                                _vm.event.status != "published"
                                  ? _c("span", {
                                      staticClass: "icon is-medium delete",
                                      on: {
                                        click: function($event) {
                                          return _vm.removeArtist(index)
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm.event.status == "published"
                                  ? _c(
                                      "span",
                                      { staticClass: "icon is-medium info" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-lg fa-info-circle"
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ])
                        }),
                        (!_vm.profiles ||
                          _vm.profiles.length == 0 ||
                          Object.keys(_vm.event.artists).length > 0) &&
                        !_vm.addingArtist
                          ? _c("div", { staticClass: "buttons addartist" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  attrs: {
                                    disabled:
                                      _vm.profiles &&
                                      _vm.profiles[0] &&
                                      !_vm.profiles[_vm.profiles.length - 1].id
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.addingArtist = true
                                    }
                                  }
                                },
                                [_vm._m(0), _c("span", [_vm._v("Add Artist")])]
                              )
                            ])
                          : _vm._e(),
                        _vm.addingArtist
                          ? _c("AddArtist", {
                              on: { artistAdded: _vm.addArtist }
                            })
                          : _vm._e(),
                        !_vm.event.actId &&
                        _vm.profiles &&
                        _vm.profiles.length > 0
                          ? _c("div", [
                              _c("div", { staticClass: "select" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.event.actId,
                                        expression: "event.actId"
                                      }
                                    ],
                                    attrs: {
                                      disabled: _vm.event.status == "complete"
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.event,
                                            "actId",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          return _vm.actChanged()
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: { disabled: "", selected: "" },
                                        domProps: { value: null }
                                      },
                                      [_vm._v("Select Artist")]
                                    ),
                                    _vm._l(_vm.profiles, function(profile) {
                                      return _c(
                                        "option",
                                        {
                                          key: profile.id,
                                          domProps: { value: profile.id }
                                        },
                                        [_vm._v(_vm._s(profile.name))]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            ])
                          : _vm._e()
                      ],
                      2
                    ),
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Event Description")
                      ]),
                      _c("label", { staticClass: "label" }, [
                        _vm._v(
                          "Add details like what to expect, list of performers or sponsors."
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("quill-editor", {
                            ref: "myQuillEditor",
                            class: { "ql-focus": _vm.editorHasFocus },
                            attrs: {
                              content: _vm.descriptionContent,
                              options: _vm.editorOption
                            },
                            on: {
                              blur: function($event) {
                                _vm.editorHasFocus = false
                              },
                              focus: function($event) {
                                _vm.editorHasFocus = true
                              },
                              change: function($event) {
                                return _vm.saveEventDescription($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.errors.description
                        ? _c("p", { staticClass: "help is-danger" }, [
                            _vm._v(" " + _vm._s(_vm.errors.description) + " ")
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 2,
                      expression: "step == 2"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h3", [_vm._v("Dates + Times")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _vm._l(_vm.eventDates, function(date) {
                        return _c("EventDate", {
                          key: date.id,
                          attrs: {
                            date: date,
                            defaultDuration: _vm.duration,
                            editing: true,
                            disable: _vm.event.status == "complete"
                          },
                          on: {
                            dateSet: _vm.updateDate,
                            deleted: _vm.deleteDate
                          }
                        })
                      }),
                      _c("p", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { disabled: _vm.newDate },
                            on: { click: _vm.newEvent }
                          },
                          [_vm._m(1), _c("span", [_vm._v("Add date")])]
                        )
                      ])
                    ],
                    2
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 3,
                      expression: "step == 3"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h3", [_vm._v("Location")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _vm.event.status != "published" &&
                      _vm.event.status != "complete"
                        ? _c("div", { staticClass: "field" }, [
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Venue Location")
                            ]),
                            !_vm.venue
                              ? _c(
                                  "div",
                                  { staticClass: "control has-icons-left" },
                                  [
                                    _c("GmapAutocomplete", {
                                      staticClass: "input",
                                      attrs: { fields: _vm.fields },
                                      on: { place_changed: _vm.setPlace }
                                    }),
                                    _vm._m(2)
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.locationError
                              ? _c(
                                  "p",
                                  { staticClass: "notification is-danger" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.locationError) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm.venue &&
                      (!_vm.event.mapDisplay ||
                        !_vm.event.venueIsAlternative ||
                        _vm.event.mapDisplay != "hidden")
                        ? _c("VenueMap", { attrs: { venue: _vm.venue } })
                        : _vm._e(),
                      _vm.venue && _vm.venue.owner
                        ? _c(
                            "div",
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.venue.name) + " is using Gobo!"
                                )
                              ]),
                              _c("Venue", {
                                attrs: { profile: _vm.venue, editable: false }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.venue && !_vm.venue.owner
                        ? _c(
                            "div",
                            [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.venue.name) +
                                    " is not using Gobo yet. Invite them to help sell tickets with you. "
                                )
                              ]),
                              _c("InviteUserStarter", {
                                attrs: {
                                  inviterName: _vm.user.name,
                                  inviterId: _vm.user.uid,
                                  profileName: _vm.venue.name,
                                  venueId: _vm.venue.id,
                                  newProfile: true
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "control" }, [
                        _c("label", { staticClass: "checkbox" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.event.venueIsAlternative,
                                expression: "event.venueIsAlternative"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.event.venueIsAlternative
                              )
                                ? _vm._i(_vm.event.venueIsAlternative, null) >
                                  -1
                                : _vm.event.venueIsAlternative
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.event.venueIsAlternative,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.event,
                                          "venueIsAlternative",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.event,
                                          "venueIsAlternative",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.event,
                                      "venueIsAlternative",
                                      $$c
                                    )
                                  }
                                },
                                function($event) {
                                  return _vm.saveEvent()
                                }
                              ]
                            }
                          }),
                          _vm._v(
                            " Alternative Venue. Obscure location until purchase. "
                          )
                        ])
                      ]),
                      _vm.event.venueIsAlternative
                        ? _c(
                            "div",
                            {
                              staticClass: "control",
                              staticStyle: { "margin-top": "10px" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.event.mapDisplay,
                                    expression: "event.mapDisplay"
                                  }
                                ],
                                staticClass: "is-checkradio",
                                attrs: {
                                  id: "alternativeObscure",
                                  type: "radio",
                                  name: "alternativeObscure",
                                  value: "obscured"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.event.mapDisplay,
                                    "obscured"
                                  )
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      return _vm.$set(
                                        _vm.event,
                                        "mapDisplay",
                                        "obscured"
                                      )
                                    },
                                    _vm.saveEvent
                                  ]
                                }
                              }),
                              _c(
                                "label",
                                { attrs: { for: "alternativeObscure" } },
                                [_vm._v("Show map with obscured location")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.event.mapDisplay,
                                    expression: "event.mapDisplay"
                                  }
                                ],
                                staticClass: "is-checkradio",
                                attrs: {
                                  id: "alternativeHide",
                                  type: "radio",
                                  value: "hidden"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.event.mapDisplay,
                                    "hidden"
                                  )
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      return _vm.$set(
                                        _vm.event,
                                        "mapDisplay",
                                        "hidden"
                                      )
                                    },
                                    _vm.saveEvent
                                  ]
                                }
                              }),
                              _c(
                                "label",
                                { attrs: { for: "alternativeHide" } },
                                [_vm._v("Hide map")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.venue ? _c("hr") : _vm._e(),
                      _vm.venue
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                on: { click: _vm.clearVenue }
                              },
                              [_vm._v(" Use a Different Venue ")]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 4,
                      expression: "step == 4"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h3", [_vm._v("Ticket Info")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("p", [
                        _vm._v(
                          " You can create up to five named tiers of tickets (e.g., Pre-sale, VIP, General Admission, Guest List, etc.) "
                        )
                      ]),
                      _c("nav", { staticClass: "level" }, [
                        _c("div", { staticClass: "level-left" }, [
                          _c("div", { staticClass: "field" }, [
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Total tickets per performance")
                            ]),
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.event.capacity,
                                    expression: "event.capacity",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "input is-primary",
                                class: { "is-danger": _vm.errors.capacity },
                                attrs: {
                                  type: "number",
                                  placeholder: "Number of tickets",
                                  disabled: _vm.event.status == "complete"
                                },
                                domProps: { value: _vm.event.capacity },
                                on: {
                                  change: function($event) {
                                    return _vm.saveEvent()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.event,
                                      "capacity",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]),
                            _vm.errors.capacity
                              ? _c("p", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.errors.capacity) + " "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _c("div", { staticClass: "level-right" }, [
                          _c("div", { staticClass: "field" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.event.donation,
                                  expression: "event.donation"
                                }
                              ],
                              staticClass: "switch",
                              attrs: {
                                id: "donation",
                                type: "checkbox",
                                name: "switchExample"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.event.donation)
                                  ? _vm._i(_vm.event.donation, null) > -1
                                  : _vm.event.donation
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.event.donation,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.event,
                                            "donation",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.event,
                                            "donation",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.event, "donation", $$c)
                                    }
                                  },
                                  function($event) {
                                    return _vm.setDonation()
                                  }
                                ]
                              }
                            }),
                            _c("label", { attrs: { for: "donation" } }, [
                              _vm._v("Artist Donation")
                            ])
                          ])
                        ])
                      ]),
                      _c("hr"),
                      _vm._l(_vm.priceTiers, function(tier) {
                        return _c("PricingTier", {
                          key: tier.id,
                          attrs: {
                            tier: tier,
                            dates: _vm.eventDates,
                            flexible: _vm.flexibleQuantity,
                            disable: _vm.event.status == "complete"
                          },
                          on: { tierSet: _vm.saveTier, deleted: _vm.deleteTier }
                        })
                      }),
                      _vm.newtier
                        ? _c("PricingTier", {
                            attrs: {
                              tier: _vm.newtier,
                              dates: _vm.eventDates,
                              flexible: _vm.flexibleQuantity
                            },
                            on: {
                              tierSet: _vm.saveTier,
                              deleted: _vm.deleteTier
                            }
                          })
                        : _vm._e(),
                      _c("p", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: {
                              disabled: _vm.newtier || _vm.priceTiers.length > 4
                            },
                            on: { click: _vm.newPriceTier }
                          },
                          [_vm._m(3), _c("span", [_vm._v("Add ticket tier")])]
                        )
                      ]),
                      _vm.priceTiers &&
                      _vm.priceTiers.length > 0 &&
                      _vm.priceTiers.filter(t => t.feeType != "free").length > 0
                        ? _c("div")
                        : _vm._e(),
                      _c("hr"),
                      _c("div", { staticClass: "field" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.event.donation,
                              expression: "event.donation"
                            }
                          ],
                          staticClass: "switch",
                          attrs: {
                            id: "donation",
                            type: "checkbox",
                            name: "switchExample"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.event.donation)
                              ? _vm._i(_vm.event.donation, null) > -1
                              : _vm.event.donation
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.event.donation,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.event,
                                        "donation",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.event,
                                        "donation",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.event, "donation", $$c)
                                }
                              },
                              function($event) {
                                return _vm.saveEvent()
                              }
                            ]
                          }
                        }),
                        _c("label", { attrs: { for: "donation" } }, [
                          _vm._v("Add Donation Option")
                        ])
                      ]),
                      _vm.event.donation
                        ? _c("PricingTier", {
                            attrs: {
                              tier: _vm.event.donationDetails,
                              type: "donation",
                              dates: _vm.eventDates,
                              flexible: _vm.flexibleQuantity
                            },
                            on: { tierSet: _vm.saveDonation }
                          })
                        : _vm._e(),
                      _c("div", [
                        _c("hr"),
                        _vm.user.venueManager
                          ? _c("div", { staticClass: "field" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.event.onSale,
                                    expression: "event.onSale"
                                  }
                                ],
                                staticClass: "switch",
                                attrs: {
                                  id: "onsale",
                                  type: "checkbox",
                                  name: "switchExample"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.event.onSale)
                                    ? _vm._i(_vm.event.onSale, null) > -1
                                    : _vm.event.onSale
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.event.onSale,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.event,
                                              "onSale",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.event,
                                              "onSale",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.event, "onSale", $$c)
                                      }
                                    },
                                    function($event) {
                                      return _vm.saveEvent()
                                    }
                                  ]
                                }
                              }),
                              _c("label", { attrs: { for: "onsale" } }, [
                                _vm._v("On Sale Date")
                              ])
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v(
                            "If enabled, tickets will not be sold before the specified date and time."
                          )
                        ]),
                        _vm.event.onSale
                          ? _c("div", { staticClass: "columns" }, [
                              _c(
                                "div",
                                { staticClass: "column" },
                                [
                                  _c("DateField", {
                                    attrs: { date: _vm.event.onSaleDate },
                                    on: { changed: _vm.onSaleDateSet }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "column" },
                                [
                                  _c("TimeField", {
                                    attrs: { time: _vm.event.onSaleTime },
                                    on: {
                                      "update:time": function($event) {
                                        return _vm.$set(
                                          _vm.event,
                                          "onSaleTime",
                                          $event
                                        )
                                      },
                                      change: _vm.saveEvent
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    2
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 5,
                      expression: "step == 5"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h3", [_vm._v("Discounts")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("DiscountCodes", {
                        attrs: {
                          discounts: _vm.discounts,
                          dates: _vm.eventDates,
                          priceTiers: _vm.priceTiers,
                          disabled: _vm.event.status == "complete",
                          eventId: _vm.event.id
                        },
                        on: {
                          hasDiscountsChange: _vm.setHasDiscount,
                          newDiscount: _vm.newDiscount,
                          codeChanged: _vm.saveDiscount
                        }
                      }),
                      _c("hr"),
                      _c("h4", [_vm._v("Early Purchase Discount")]),
                      _c("p", [
                        _vm._v(
                          "Early Purchase Discounts give special pricing for a limited time when the event goes live. You can discount some or all pricing tiers and passes, but only the discounted items will be available until the early purchase period closes. "
                        )
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.event.earlyPurchaseDiscount,
                              expression: "event.earlyPurchaseDiscount"
                            }
                          ],
                          staticClass: "is-checkradio is-success",
                          class: {
                            "has-background-color":
                              _vm.event.earlyPurchaseDiscount
                          },
                          attrs: { type: "checkbox", id: "sendThankyouEmails" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.event.earlyPurchaseDiscount
                            )
                              ? _vm._i(_vm.event.earlyPurchaseDiscount, null) >
                                -1
                              : _vm.event.earlyPurchaseDiscount
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.event.earlyPurchaseDiscount,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.event,
                                        "earlyPurchaseDiscount",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.event,
                                        "earlyPurchaseDiscount",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.event,
                                    "earlyPurchaseDiscount",
                                    $$c
                                  )
                                }
                              },
                              _vm.saveEvent
                            ]
                          }
                        }),
                        _c("label", { attrs: { for: "sendThankyouEmails" } }, [
                          _vm._v(" Enable Early Purchase Discount ")
                        ])
                      ]),
                      _vm.event.earlyPurchaseDiscount
                        ? _c("div", { staticClass: "earlyPurchaseDetails" }, [
                            _c("div", { staticClass: "field" }, [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("Early Purchase End Date")
                              ]),
                              _c(
                                "div",
                                { staticClass: "control" },
                                [
                                  _c("DateField", {
                                    attrs: {
                                      date: _vm.event.earlyDiscountEndDate
                                    },
                                    on: { changed: _vm.earlyDiscountDateSet }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "availableDiscounts" },
                              [
                                _c("h5", [_vm._v("Available Discounts")]),
                                _vm._l(_vm.priceTiers, function(tier) {
                                  return _c(
                                    "div",
                                    {
                                      key: tier.id,
                                      staticClass: "discountLine"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "discountSelector" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: tier.hasEarlyDiscount,
                                                expression:
                                                  "tier.hasEarlyDiscount"
                                              }
                                            ],
                                            staticClass:
                                              "is-checkradio is-success",
                                            class: {
                                              "has-background-color":
                                                tier.hasEarlyDiscount
                                            },
                                            attrs: {
                                              type: "checkbox",
                                              id: "tierdiscount-" + tier.id
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                tier.hasEarlyDiscount
                                              )
                                                ? _vm._i(
                                                    tier.hasEarlyDiscount,
                                                    null
                                                  ) > -1
                                                : tier.hasEarlyDiscount
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  var $$a =
                                                      tier.hasEarlyDiscount,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          tier,
                                                          "hasEarlyDiscount",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          tier,
                                                          "hasEarlyDiscount",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      tier,
                                                      "hasEarlyDiscount",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function($event) {
                                                  return _vm.saveTier(tier)
                                                }
                                              ]
                                            }
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "tierdiscount-" + tier.id
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(tier.name) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("div", [
                                        _c("label", { staticClass: "label" }, [
                                          _vm._v("Early Purchase Price")
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "control has-icons-left"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName:
                                                    "v-model.number.lazy",
                                                  value: tier.earlyPrice,
                                                  expression: "tier.earlyPrice",
                                                  modifiers: {
                                                    number: true,
                                                    lazy: true
                                                  }
                                                }
                                              ],
                                              staticClass: "input is-primary",
                                              attrs: {
                                                type: "number",
                                                min: "1",
                                                max: "10000",
                                                step: "1",
                                                placeholder: "early price",
                                                disabled: !tier.hasEarlyDiscount
                                              },
                                              domProps: {
                                                value: tier.earlyPrice
                                              },
                                              on: {
                                                change: [
                                                  function($event) {
                                                    _vm.$set(
                                                      tier,
                                                      "earlyPrice",
                                                      _vm._n(
                                                        $event.target.value
                                                      )
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.saveTier(tier)
                                                  }
                                                ],
                                                blur: function($event) {
                                                  return _vm.$forceUpdate()
                                                }
                                              }
                                            }),
                                            _vm._m(4, true)
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 6,
                      expression: "step == 6"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h3", [_vm._v("Email Settings")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("div", { staticClass: "field" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Email Message")
                        ]),
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            "Add any extra information you would like to include about the event to be added to the end of the email confirmation (parking, transportation, doors open time, etc.)."
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("quill-editor", {
                              ref: "emailMessageEditor",
                              class: { "ql-focus": _vm.emailEditorHasFocus },
                              attrs: {
                                content: _vm.emailContent,
                                options: _vm.editorOption
                              },
                              on: {
                                blur: function($event) {
                                  _vm.emailEditorHasFocus = false
                                },
                                focus: function($event) {
                                  _vm.emailEditorHasFocus = true
                                },
                                change: function($event) {
                                  return _vm.updateEmail($event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("TestEmailSender", {
                        attrs: { type: "confirmation", eventId: _vm.event.id }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.settings.sendReminderEmails,
                            expression: "settings.sendReminderEmails"
                          }
                        ],
                        staticClass: "is-checkradio is-success",
                        class: {
                          "has-background-color":
                            _vm.settings.sendReminderEmails
                        },
                        attrs: { type: "checkbox", id: "showPreshowReminder" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.settings.sendReminderEmails
                          )
                            ? _vm._i(_vm.settings.sendReminderEmails, null) > -1
                            : _vm.settings.sendReminderEmails
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.settings.sendReminderEmails,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.settings,
                                      "sendReminderEmails",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.settings,
                                      "sendReminderEmails",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.settings,
                                  "sendReminderEmails",
                                  $$c
                                )
                              }
                            },
                            _vm.saveSettings
                          ]
                        }
                      }),
                      _c("label", { attrs: { for: "showPreshowReminder" } }, [
                        _vm._v(" Send Preshow Reminder Emails ")
                      ])
                    ]),
                    _c("div", [
                      _vm._m(5),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.settings.reminderDaysBefore,
                              expression: "settings.reminderDaysBefore",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "is-checkradio",
                          attrs: {
                            id: "reminderDayOf",
                            type: "radio",
                            name: "reminderDayOf",
                            value: "0"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.settings.reminderDaysBefore,
                              _vm._n("0")
                            )
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.$set(
                                  _vm.settings,
                                  "reminderDaysBefore",
                                  _vm._n("0")
                                )
                              },
                              _vm.saveSettings
                            ]
                          }
                        }),
                        _c("label", { attrs: { for: "reminderDayOf" } }, [
                          _vm._v("Day of the Event")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.settings.reminderDaysBefore,
                              expression: "settings.reminderDaysBefore",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "is-checkradio",
                          attrs: {
                            id: "reminderOneDay",
                            type: "radio",
                            value: "1"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.settings.reminderDaysBefore,
                              _vm._n("1")
                            )
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.$set(
                                  _vm.settings,
                                  "reminderDaysBefore",
                                  _vm._n("1")
                                )
                              },
                              _vm.saveSettings
                            ]
                          }
                        }),
                        _c("label", { attrs: { for: "reminderOneDay" } }, [
                          _vm._v("1 day before")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.settings.reminderDaysBefore,
                              expression: "settings.reminderDaysBefore",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "is-checkradio",
                          attrs: {
                            id: "reminderTwoDay",
                            type: "radio",
                            name: "reminderTwoDay",
                            value: "2"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.settings.reminderDaysBefore,
                              _vm._n("2")
                            )
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.$set(
                                  _vm.settings,
                                  "reminderDaysBefore",
                                  _vm._n("2")
                                )
                              },
                              _vm.saveSettings
                            ]
                          }
                        }),
                        _c("label", { attrs: { for: "reminderTwoDay" } }, [
                          _vm._v("2 days before")
                        ])
                      ])
                    ]),
                    _vm.settings && _vm.settings.sendReminderEmails
                      ? _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Preshow Reminder Email")
                            ]),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(
                                "Add any extra information you would like to include about the event to be added to the end of the preshow reminder email (parking, transportation, doors open time, etc.)."
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("quill-editor", {
                                  ref: "emailMessageEditor",
                                  class: {
                                    "ql-focus": _vm.emailReminderEditorHasFocus
                                  },
                                  attrs: {
                                    content: _vm.emailReminderContent,
                                    options: _vm.editorOption
                                  },
                                  on: {
                                    blur: function($event) {
                                      _vm.emailReminderEditorHasFocus = false
                                    },
                                    focus: function($event) {
                                      _vm.emailReminderEditorHasFocus = true
                                    },
                                    change: function($event) {
                                      return _vm.updateReminderEmail($event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c("TestEmailSender", {
                              attrs: { type: "reminder", eventId: _vm.event.id }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.settings.sendThankyouEmails,
                              expression: "settings.sendThankyouEmails"
                            }
                          ],
                          staticClass: "is-checkradio is-success",
                          class: {
                            "has-background-color":
                              _vm.settings.sendThankyouEmails
                          },
                          attrs: { type: "checkbox", id: "sendThankyouEmails" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.settings.sendThankyouEmails
                            )
                              ? _vm._i(_vm.settings.sendThankyouEmails, null) >
                                -1
                              : _vm.settings.sendThankyouEmails
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.settings.sendThankyouEmails,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.settings,
                                        "sendThankyouEmails",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.settings,
                                        "sendThankyouEmails",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.settings,
                                    "sendThankyouEmails",
                                    $$c
                                  )
                                }
                              },
                              _vm.saveSettings
                            ]
                          }
                        }),
                        _c("label", { attrs: { for: "sendThankyouEmails" } }, [
                          _vm._v(" Send Postshow Thank-You Emails ")
                        ])
                      ]),
                      _vm.settings && _vm.settings.sendThankyouEmails
                        ? _c("div", { staticClass: "field bumpadown" }, [
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Postshow Thank-You Email")
                            ]),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(
                                "Add any extra information you would like to include about the event to be added to the end of the postshow thank-you email (survey, request for feedback, etc.)."
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("quill-editor", {
                                  ref: "emailMessageEditor",
                                  class: {
                                    "ql-focus": _vm.emailThankyouEditorHasFocus
                                  },
                                  attrs: {
                                    content: _vm.emailThankyouContent,
                                    options: _vm.editorOption
                                  },
                                  on: {
                                    blur: function($event) {
                                      _vm.emailThankyouEditorHasFocus = false
                                    },
                                    focus: function($event) {
                                      _vm.emailThankyouEditorHasFocus = true
                                    },
                                    change: function($event) {
                                      return _vm.updateThankyouEmail($event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _c("TestEmailSender", {
                        attrs: { type: "thankyou", eventId: _vm.event.id }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 7,
                      expression: "step == 7"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h3", [_vm._v("Preview + Publish")]),
                  _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("div", { staticClass: "event-preview" }, [
                          _c("div", { staticClass: "event-preview-header" }, [
                            _vm.event.image
                              ? _c("img", {
                                  attrs: { src: _vm.event.image, alt: "" }
                                })
                              : _vm._e()
                          ]),
                          _c(
                            "div",
                            { staticClass: "event-preview-body" },
                            [
                              _c("EventDateline", {
                                attrs: { event: _vm.event }
                              }),
                              _c("h3", [_vm._v(_vm._s(_vm.event.title))]),
                              _vm.venue
                                ? _c("h5", [_vm._v(_vm._s(_vm.venue.name))])
                                : _vm._e(),
                              _c(
                                "button",
                                {
                                  staticClass: "button is-fullwidth",
                                  on: {
                                    click: function($event) {
                                      return _vm.preview()
                                    }
                                  }
                                },
                                [_vm._v(" Preview Event ")]
                              )
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "column is-half" }, [
                        _c("div", { staticClass: "control" }, [
                          _c("label", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.event.isPublic,
                                  expression: "event.isPublic"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "answer",
                                value: "true"
                              },
                              domProps: {
                                checked: _vm._q(_vm.event.isPublic, "true")
                              },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.event,
                                      "isPublic",
                                      "true"
                                    )
                                  },
                                  function($event) {
                                    return _vm.saveEvent()
                                  }
                                ]
                              }
                            }),
                            _vm._v(" Public ")
                          ]),
                          _c("p", { staticClass: "radio-label" }, [
                            _vm._v("Shared on Gobo and searchable")
                          ]),
                          _c("label", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.event.isPublic,
                                  expression: "event.isPublic"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "answer",
                                value: "false"
                              },
                              domProps: {
                                checked: _vm._q(_vm.event.isPublic, "false")
                              },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.event,
                                      "isPublic",
                                      "false"
                                    )
                                  },
                                  function($event) {
                                    return _vm.saveEvent()
                                  }
                                ]
                              }
                            }),
                            _vm._v(" Private ")
                          ]),
                          _c("p", { staticClass: "radio-label" }, [
                            _vm._v(
                              " Only available to you and the people you share it with. "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm.cantPublish && _vm.event.status == "draft"
                    ? _c("div", { staticClass: "box" }, [
                        _c("h4", [_vm._v("Before you publish")]),
                        _c("p", [
                          _vm._v(
                            " There's a few things you need to do before you can publish your event. "
                          )
                        ]),
                        _c("ul", [
                          !_vm.event.title
                            ? _c("li", [
                                _vm._v("Please give your event a title")
                              ])
                            : _vm._e(),
                          !_vm.event.genre
                            ? _c("li", [_vm._v("Set an event type")])
                            : _vm._e(),
                          !_vm.event.act
                            ? _c("li", [
                                _vm._v("Specify an artist or presenting group")
                              ])
                            : _vm._e(),
                          !_vm.event.description
                            ? _c("li", [
                                _vm._v("Give your event a description")
                              ])
                            : _vm._e(),
                          !_vm.event.image
                            ? _c("li", [_vm._v("Add an event image")])
                            : _vm._e(),
                          !_vm.eventDates ||
                          _vm.eventDates.length < 1 ||
                          (_vm.eventDates.length == 1 && !_vm.eventDates[0].id)
                            ? _c("li", [_vm._v(" Add at least one date ")])
                            : _vm._e(),
                          !_vm.event.venue
                            ? _c("li", [
                                _vm._v("Set a location for your event")
                              ])
                            : _vm._e(),
                          !_vm.event.capacity
                            ? _c("li", [_vm._v("Specify your event capacity")])
                            : _vm._e(),
                          !_vm.priceTiers ||
                          _vm.priceTiers.length < 1 ||
                          (_vm.priceTiers.length == 1 && !_vm.priceTiers[0].id)
                            ? _c("li", [
                                _vm._v(
                                  " Set at least one pricing tier for your event "
                                )
                              ])
                            : _vm._e(),
                          _vm.event.maxPrice > 0 &&
                          (!_vm.user.stripeStatus ||
                            _vm.user.stripeStatus != "connected")
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/profile/payment" } },
                                    [
                                      _vm._v(
                                        "Connect your bank account to recieve payments"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c("div", {
                staticClass:
                  "column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "box stepbar" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }, [
                _c("div", { staticClass: "buttons is-right" }, [
                  _vm.step > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              _vm.step = _vm.step - 1
                            }
                          }
                        },
                        [_vm._v(" Back ")]
                      )
                    : _vm._e(),
                  _vm.step < 6
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          attrs: { disabled: _vm.cropping },
                          on: {
                            click: function($event) {
                              return _vm.next()
                            }
                          }
                        },
                        [_vm._v(" Next ")]
                      )
                    : _vm._e(),
                  _vm.step == 6
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          on: {
                            click: function($event) {
                              return _vm.next()
                            }
                          }
                        },
                        [_vm._v(" Preview ")]
                      )
                    : _vm._e(),
                  _vm.event.status == "draft" && _vm.step == 7
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          attrs: { disabled: _vm.cantPublish },
                          on: {
                            click: function($event) {
                              return _vm.publish()
                            }
                          }
                        },
                        [_vm._v(" Publish ")]
                      )
                    : _vm._e()
                ])
              ]),
              _c("div", {
                staticClass:
                  "column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
              })
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-medium" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-search" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-medium" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "control bumpadown" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Timing")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }