var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", [
      _c(
        "div",
        { staticClass: "synopsis" },
        [
          _c("div", { staticClass: "eventimagerapper" }, [
            _c("div", { staticClass: "imageholder" }, [
              _c("img", {
                staticClass: "eventimage",
                attrs: { src: _vm.summary.event.image, alt: "" }
              })
            ])
          ]),
          _c("EventDateline", { attrs: { event: _vm.summary.event } }),
          _c(
            "div",
            { staticClass: "eventTitle" },
            [
              _vm.summary.event.status &&
              (_vm.summary.event.status == "published" ||
                _vm.summary.event.status == "complete")
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "eventSummary",
                          params: { id: _vm.summary.event.id }
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.summary.event.title))]
                  )
                : _vm._e(),
              !_vm.summary.event.status ||
              (_vm.summary.event.status != "published" &&
                _vm.summary.event.status != "complete")
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: _vm.summary.event.seasonId
                            ? "editFestivalEvent"
                            : "editEvent",
                          params: { id: _vm.summary.event.id }
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.summary.event.title))]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "venue" }, [
            _vm._v(_vm._s(_vm.summary.event.venueName))
          ]),
          _c("div", { staticClass: "is-clearfix" }),
          _vm.summary.audienceReport
            ? _c("div", { staticClass: "stats is-hidden-tablet" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Sold")]),
                    _c("div", { staticClass: "control" }, [
                      _vm.summary.audienceReport &&
                      _vm.summary.audienceReport.totalSold
                        ? _c("span", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.summary.audienceReport.totalSold)
                              )
                            ]),
                            _vm._v(
                              " /" + _vm._s(_vm.summary.audienceReport.capacity)
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Gross")]),
                    _c("div", { staticClass: "control" }, [
                      _vm.summary.audienceReport
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(_vm.summary.audienceReport.salesTotal)
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "status is-hidden-tablet" }, [
            _c("label", { staticClass: "label" }, [_vm._v("Status")]),
            _c("span", { staticClass: "statusstatus" }, [
              _vm._v(_vm._s(_vm.summary.event.status))
            ])
          ])
        ],
        1
      ),
      _c("div", { staticClass: "menubar is-hidden-tablet" }, [
        _c("div", { staticClass: "buttons mobilebar" }, [
          _c(
            "button",
            {
              staticClass: "button is-info",
              attrs: { disabled: _vm.summary.event.status != "draft" },
              on: {
                click: function($event) {
                  return _vm.deleteDate(_vm.summary.event)
                }
              }
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "button",
            {
              staticClass: "button is-info",
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: _vm.summary.event.seasonId
                      ? "editFestivalEvent"
                      : "editEvent",
                    params: { id: _vm.summary.event.id }
                  })
                }
              }
            },
            [_vm._v(" Edit ")]
          ),
          _c(
            "button",
            {
              staticClass: "button is-info",
              on: {
                click: function($event) {
                  return _vm.duplicateEvent(_vm.summary.event.id)
                }
              }
            },
            [_vm._v(" Duplicate ")]
          )
        ])
      ])
    ]),
    _c("td", { staticClass: "results is-hidden-mobile" }, [
      _vm.summary.audienceReport && _vm.summary.audienceReport.totalSold
        ? _c("span", [
            _c("strong", [
              _vm._v(_vm._s(_vm.summary.audienceReport.totalSold))
            ]),
            _vm._v(" /" + _vm._s(_vm.summary.audienceReport.capacity))
          ])
        : _vm._e()
    ]),
    _c("td", { staticClass: "results is-hidden-mobile" }, [
      _vm.summary.audienceReport
        ? _c("span", [
            _vm._v("$" + _vm._s(_vm.summary.audienceReport.salesTotal))
          ])
        : _vm._e()
    ]),
    _c("td", { staticClass: "results is-hidden-mobile" }, [
      _vm._v(_vm._s(_vm.summary.event.status))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }