var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile" }, [
    !_vm.editing
      ? _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column is-narrow" }, [
            _c("div", { staticClass: "avatar-generic" }, [
              _vm.profile.image
                ? _c("img", { attrs: { src: _vm.profile.image, alt: "" } })
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "column" }, [
            _c("p", { staticClass: "artistname" }, [
              _c("strong", [_vm._v(_vm._s(_vm.profile.name))]),
              _vm._v("  |  "),
              _c("span", { staticClass: "city" }, [
                _vm._v(_vm._s(_vm.profile.city))
              ])
            ])
          ]),
          _c("div", { staticClass: "column is-narrow" }, [
            _c("p", { staticClass: "managedby" }, [
              _vm._v("Managed by " + _vm._s(_vm.owners))
            ])
          ]),
          _vm.editable
            ? _c("div", { staticClass: "column is-narrow" }, [
                _c(
                  "div",
                  {
                    staticClass: "dropdown",
                    class: { "is-active": _vm.showMenu },
                    on: {
                      click: function($event) {
                        _vm.showMenu = true
                      }
                    }
                  },
                  [
                    _vm._m(0),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        attrs: { id: "dropdown-menu3", role: "menu" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-content",
                            on: {
                              blur: function($event) {
                                _vm.showMenu = false
                              }
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                on: {
                                  click: function($event) {
                                    return _vm.edit()
                                  }
                                }
                              },
                              [_vm._v("Edit Venue")]
                            ),
                            false
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteDate()
                                      }
                                    }
                                  },
                                  [_vm._v("Delete Venue")]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ]
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm.editing
      ? _c(
          "div",
          [
            _vm.profile && _vm.profile.location
              ? _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Venue Name")
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.lazy",
                              value: _vm.profile.name,
                              expression: "profile.name",
                              modifiers: { lazy: true }
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "text",
                            placeholder: "Artist name",
                            readonly: _vm.profile.useMyName
                          },
                          domProps: { value: _vm.profile.name },
                          on: {
                            change: [
                              function($event) {
                                return _vm.$set(
                                  _vm.profile,
                                  "name",
                                  $event.target.value
                                )
                              },
                              _vm.save
                            ]
                          }
                        })
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.profile && _vm.profile.location
              ? _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Venue Image")
                        ]),
                        false ? _c("div", [_c("Avatar")], 1) : _vm._e(),
                        _vm.profile.image
                          ? _c("div", { staticClass: "imagefield" }, [
                              _vm.profile.image
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.profile.image,
                                      alt: "User Image"
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function($event) {
                                      _vm.profile.image = null
                                    }
                                  }
                                },
                                [_vm._v(" Change Image ")]
                              )
                            ])
                          : _vm._e(),
                        !_vm.profile.image
                          ? _c("UploadImage", {
                              attrs: { outputWidth: 400, outputHeight: 400 },
                              on: { imageCropped: _vm.saveImage }
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "buttons" }),
                        _vm.showImageLoading
                          ? _c("div", [
                              _c(
                                "progress",
                                {
                                  staticClass: "progress is-primary",
                                  attrs: { max: "100" },
                                  domProps: { value: _vm.percentComplete }
                                },
                                [_vm._v(_vm._s(_vm.percentComplete))]
                              ),
                              _c("p", [_vm._v("Uploading image...")])
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm._m(1),
            !_vm.profile.location
              ? _c("VenueFinder", { on: { venueFound: _vm.addVenue } })
              : _vm._e(),
            _vm.profile && _vm.profile.location
              ? _c("VenueMap", { attrs: { venue: _vm.profile } })
              : _vm._e(),
            _c("div", { staticClass: "field taxes" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Tax Status")]),
              _c("div", { staticClass: "field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.profile.tax,
                      expression: "profile.tax"
                    }
                  ],
                  staticClass: "is-checkradio",
                  attrs: {
                    type: "radio",
                    id: "taxable",
                    name: "tax",
                    value: "remit"
                  },
                  domProps: { checked: _vm._q(_vm.profile.tax, "remit") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.profile, "tax", "remit")
                    }
                  }
                }),
                _c(
                  "label",
                  { staticClass: "radio", attrs: { for: "taxable" } },
                  [_vm._v(" Collect and Remit Sales Tax (default) ")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.profile.tax,
                      expression: "profile.tax"
                    }
                  ],
                  staticClass: "is-checkradio",
                  attrs: {
                    type: "radio",
                    id: "taxexempt",
                    name: "tax",
                    value: "exempt"
                  },
                  domProps: { checked: _vm._q(_vm.profile.tax, "exempt") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.profile, "tax", "exempt")
                    }
                  }
                }),
                _c(
                  "label",
                  { staticClass: "radio", attrs: { for: "taxexempt" } },
                  [_vm._v(" Tax Exempt (we may ask for documentation) ")]
                )
              ])
            ]),
            _vm.profile && _vm.profile.location
              ? _c("p", [
                  _vm._v(
                    " (Optional) Add co-owners now or you can always add them later. If they don't currently have a Gobo account, they will need to create one. "
                  )
                ])
              : _vm._e(),
            _vm.profile && _vm.profile.location
              ? _c("InviteUserStarter", {
                  attrs: {
                    inviterName: _vm.user.name,
                    inviterId: _vm.user.uid,
                    profileName: _vm.profile.name,
                    profileType: _vm.Venue,
                    venueId: _vm.profile.id,
                    newProfile: false
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button dot-menu",
          attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu3" }
        },
        [
          _c("span", { staticClass: "icon is-small" }, [
            _c("i", {
              staticClass: "fas fa-ellipsis-v",
              attrs: { "aria-hidden": "true" }
            })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Venue Location")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }