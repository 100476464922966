var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("p", [
        _vm._v(
          " You can create up to five discount codes for your event (e.g., FREE, % off, fixed $ amount and/or BOGO). First type the discount code word, ideally nothing too obvious and keep to under 10 characters, then choose the discount type. "
        )
      ]),
      _vm._l(_vm.discounts, function(discount, index) {
        return _c("DiscountCode", {
          key: index,
          attrs: {
            codeIndex: index,
            discount: discount,
            disabled: _vm.disabled,
            dates: _vm.dates,
            eventId: _vm.eventId
          },
          on: { codeChanged: _vm.codeChanged, deleted: _vm.deleteDiscount }
        })
      }),
      _c("p", { staticClass: "buttons" }, [
        !_vm.disabled
          ? _c(
              "button",
              {
                staticClass: "button",
                attrs: {
                  disabled:
                    _vm.hasNew || (_vm.discounts && _vm.discounts.length > 4)
                },
                on: { click: _vm.newDiscountCode }
              },
              [_vm._m(0), _c("span", [_vm._v("Add discount code")])]
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-medium" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }