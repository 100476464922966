var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.festival
    ? _c(
        "div",
        { staticClass: "profile", class: { selectable: _vm.selectable } },
        [
          !_vm.editing
            ? _c(
                "div",
                {
                  staticClass: "columns",
                  class: { selectable: _vm.selectable },
                  on: { click: _vm.profileClicked }
                },
                [
                  _vm.selectable
                    ? _c("div", { staticClass: "column is-narrow" }, [
                        _c("div", { staticClass: "field selectitem" }, [
                          _c("input", {
                            staticClass: "is-checkradio",
                            attrs: {
                              id: _vm.festival.id,
                              type: "radio",
                              value: "festival.id",
                              name: "selectedFestival"
                            },
                            domProps: { checked: _vm.selected }
                          }),
                          _c(
                            "label",
                            { staticClass: "radio", attrs: { for: "taxable" } },
                            [_vm._v(" ")]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "column is-narrow" }, [
                    _c("div", { staticClass: "avatar-generic" }, [
                      _vm.festival.image
                        ? _c("img", {
                            attrs: { src: _vm.festival.image, alt: "" }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "column" }, [
                    _c("p", { staticClass: "artistname" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.festival.name))]),
                      _vm._v("  |  "),
                      _c("span", { staticClass: "city" }, [
                        _vm._v(_vm._s(_vm.festival.type))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "column is-narrow" }, [
                    _c("p", { staticClass: "managedby" }, [
                      _vm._v("Managed by " + _vm._s(_vm.owners))
                    ])
                  ]),
                  _vm.editable
                    ? _c("div", { staticClass: "column is-narrow" }, [
                        _c(
                          "div",
                          {
                            staticClass: "dropdown",
                            class: { "is-active": _vm.showMenu },
                            on: {
                              click: function($event) {
                                _vm.showMenu = true
                              }
                            }
                          },
                          [
                            _vm._m(0),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-menu",
                                attrs: { id: "dropdown-menu3", role: "menu" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-content",
                                    on: {
                                      blur: function($event) {
                                        _vm.showMenu = false
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.edit()
                                          }
                                        }
                                      },
                                      [_vm._v("Edit Festival")]
                                    ),
                                    false
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteDate()
                                              }
                                            }
                                          },
                                          [_vm._v("Delete Festival")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.editing
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Festival Name")
                        ]),
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.lazy",
                                value: _vm.festival.name,
                                expression: "festival.name",
                                modifiers: { lazy: true }
                              }
                            ],
                            staticClass: "input",
                            attrs: {
                              type: "text",
                              placeholder: "Festival name",
                              readonly: _vm.festival.useMyName
                            },
                            domProps: { value: _vm.festival.name },
                            on: {
                              change: [
                                function($event) {
                                  return _vm.$set(
                                    _vm.festival,
                                    "name",
                                    $event.target.value
                                  )
                                },
                                _vm.save
                              ]
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "column is-one-third" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", { staticClass: "label" }, [_vm._v(" ")]),
                        _c("div", { staticClass: "select is-full-width" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.festival.type,
                                  expression: "festival.type"
                                }
                              ],
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.festival,
                                      "type",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  _vm.save
                                ]
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    selected: ""
                                  }
                                },
                                [_vm._v("Select category")]
                              ),
                              _c("option", { attrs: { value: "music" } }, [
                                _vm._v("Music")
                              ]),
                              _c("option", { attrs: { value: "theater" } }, [
                                _vm._v("Theater")
                              ]),
                              _c("option", { attrs: { value: "dance" } }, [
                                _vm._v("Dance")
                              ]),
                              _c("option", { attrs: { value: "improv" } }, [
                                _vm._v("Improv")
                              ]),
                              _c(
                                "option",
                                { attrs: { value: "storytelling" } },
                                [_vm._v("Storytelling")]
                              ),
                              _c("option", { attrs: { value: "standup" } }, [
                                _vm._v("Standup Comedy")
                              ]),
                              _c(
                                "option",
                                { attrs: { value: "conferences" } },
                                [_vm._v("Conferences/Exhibitions")]
                              ),
                              _c("option", { attrs: { value: "opera" } }, [
                                _vm._v("Opera")
                              ]),
                              _c(
                                "option",
                                { attrs: { value: "music theater" } },
                                [_vm._v("Music Theater")]
                              ),
                              _c("option", { attrs: { value: "art" } }, [
                                _vm._v("Visual Art")
                              ]),
                              _c("option", { attrs: { value: "gatherings" } }, [
                                _vm._v("Social Gatherings")
                              ]),
                              _c("option", { attrs: { value: "sports" } }, [
                                _vm._v("Sports")
                              ]),
                              _c("option", { attrs: { value: "children" } }, [
                                _vm._v("Children's Programs")
                              ])
                            ]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Festival Image")
                          ]),
                          false ? _c("div", [_c("Avatar")], 1) : _vm._e(),
                          _vm.festival.image
                            ? _c("div", { staticClass: "imagefield" }, [
                                _vm.festival.image
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.festival.image,
                                        alt: "User Image"
                                      }
                                    })
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function($event) {
                                        _vm.festival.image = null
                                      }
                                    }
                                  },
                                  [_vm._v(" Change Image ")]
                                )
                              ])
                            : _vm._e(),
                          !_vm.festival.image
                            ? _c("UploadImage", {
                                attrs: { outputWidth: 400, outputHeight: 400 },
                                on: { imageCropped: _vm.saveImage }
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "buttons" }),
                          _vm.showImageLoading
                            ? _c("div", [
                                _c(
                                  "progress",
                                  {
                                    staticClass: "progress is-primary",
                                    attrs: { max: "100" },
                                    domProps: { value: _vm.percentComplete }
                                  },
                                  [_vm._v(_vm._s(_vm.percentComplete))]
                                ),
                                _c("p", [_vm._v("Uploading image...")])
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "field" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Tax Status")
                    ]),
                    _c("div", { staticClass: "field" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.festival.tax,
                            expression: "festival.tax"
                          }
                        ],
                        staticClass: "is-checkradio",
                        attrs: {
                          type: "radio",
                          id: "taxable",
                          name: "tax",
                          value: "remit"
                        },
                        domProps: {
                          checked: _vm._q(_vm.festival.tax, "remit")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.festival, "tax", "remit")
                          }
                        }
                      }),
                      _c(
                        "label",
                        { staticClass: "radio", attrs: { for: "taxable" } },
                        [_vm._v(" Collect and Remit Sales Tax (default) ")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.festival.tax,
                            expression: "festival.tax"
                          }
                        ],
                        staticClass: "is-checkradio",
                        attrs: {
                          type: "radio",
                          id: "taxexempt",
                          name: "tax",
                          value: "exempt"
                        },
                        domProps: {
                          checked: _vm._q(_vm.festival.tax, "exempt")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.festival, "tax", "exempt")
                          }
                        }
                      }),
                      _c(
                        "label",
                        { staticClass: "radio", attrs: { for: "taxexempt" } },
                        [_vm._v(" Tax Exempt (we may ask for documentation) ")]
                      )
                    ])
                  ]),
                  _vm.festival.id
                    ? _c("p", [
                        _vm._v(
                          " (Optional) Add co-owners now or you can always add them later. If they don't currently have a Gobo account, they will need to create one. "
                        )
                      ])
                    : _vm._e(),
                  _vm.festival && _vm.festival.id
                    ? _c("InviteUserStarter", {
                        attrs: {
                          inviterName: _vm.user.name,
                          inviterId: _vm.user.uid,
                          profileName: _vm.festival.name,
                          profileType: _vm.Festival,
                          festivalId: _vm.festival.id,
                          newProfile: false
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button dot-menu",
          attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu3" }
        },
        [
          _c("span", { staticClass: "icon is-small" }, [
            _c("i", {
              staticClass: "fas fa-ellipsis-v",
              attrs: { "aria-hidden": "true" }
            })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }