var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "pagination is-centered",
      attrs: { role: "navigation", "aria-label": "pagination" }
    },
    [
      _c(
        "a",
        {
          staticClass: "pagination-previous",
          class: { "is-disabled": _vm.page == 1 },
          on: {
            click: function($event) {
              return _vm.setPage(_vm.page - 1)
            }
          }
        },
        [_vm._v("Previous")]
      ),
      _c(
        "a",
        {
          staticClass: "pagination-next",
          on: {
            click: function($event) {
              return _vm.setPage(_vm.page + 1)
            }
          }
        },
        [_vm._v("Next")]
      ),
      _c(
        "ul",
        { staticClass: "pagination-list" },
        [
          _c("li", [
            _vm.page > 4
              ? _c("span", { staticClass: "pagination-ellipsis" }, [
                  _vm._v("…")
                ])
              : _vm._e()
          ]),
          _vm._l(_vm.pages, function(n) {
            return _c("li", { key: n }, [
              _c(
                "a",
                {
                  staticClass: "pagination-link",
                  class: { "is-current": _vm.getPage(n) == _vm.page },
                  attrs: { "aria-label": "Goto page 1" },
                  on: {
                    click: function($event) {
                      _vm.setPage(_vm.getPage(n))
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getPage(n)))]
              )
            ])
          }),
          _vm._m(0)
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", { staticClass: "pagination-ellipsis" }, [_vm._v("…")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }