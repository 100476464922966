<template>
  <tbody>
    <tr>
      <td>
        <span class="icon" v-if="!expanded" @click="expanded = true">
          <i class="fas fa-plus"></i>
        </span>
        <span class="icon" v-if="expanded" @click="expanded = false">
          <i class="fas fa-minus"></i>
        </span>
      </td>
      <td>
        <a
          :href="'https://tix.gobo.show/pass/' + pass.id + '/' + pass.email"
          target="_blank"
          >{{ pass.firstName }} {{ pass.lastName }}</a
        >
      </td>
      <td>{{ pass.email }}</td>
      <td>{{ pass.status }}</td>
      <td>
        {{ pass.usedCount ? pass.usedCount : 0 }} /
        {{ pass.passType == "unlimited" ? "unlimited" : pass.limit }}
      </td>
      <td>${{ price }}</td>
    </tr>
    <tr v-for="(use, index) in pass.uses" :key="index">
      <td v-if="expanded"></td>
      <td v-if="expanded" colspan="3">
        {{ use.date }} {{ use.time }} - {{ use.eventTitle }} -
        {{ use.description }}
      </td>
      <td v-if="expanded">{{ use.passUse }}</td>
      <td v-if="expanded"></td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: "PassRow",
  props: {
    pass: Object,
    price: String
  },
  data() {
    return {
      expanded: false
    };
  }
};
</script>
