<template>
  <div>
    <div class="cal-body">
      <div class="month">{{ eventStartMonth }}</div>
      <div class="day">{{ eventStartDay }}</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    date: Date
  },
  computed: {
    eventStartMonth() {
      return moment(this.date).format("MMM");
    },
    eventStartDay() {
      return moment(this.date).format("D");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.cal-body {
  border: 2px solid $gray5;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  border-top: none;
  padding: 10px 20px;
  text-align: center;
}
.month {
  font-weight: bold;
  text-align: center;
}
.day {
  font-size: 2.5em;
  line-height: 1em;
  text-align: center;
}
</style>
