<template>
  <div class="profile">
    <div class="columns" v-if="!editing">
      <div class="column is-narrow">
        <div class="avatar-generic">
          <img v-if="profile.image" :src="profile.image" alt="" />
        </div>
      </div>
      <div class="column">
        <p class="artistname">
          <strong>{{ profile.name }}</strong
          >&nbsp;&nbsp;|&nbsp;&nbsp;<span class="city">{{ profile.city }}</span>
        </p>
      </div>
      <div class="column is-narrow">
        <p class="managedby">Managed by {{ owners }}</p>
      </div>
      <div class="column is-narrow" v-if="editable">
        <div
          class="dropdown"
          :class="{ 'is-active': showMenu }"
          @click="showMenu = true"
        >
          <div class="dropdown-trigger">
            <button
              class="button dot-menu"
              aria-haspopup="true"
              aria-controls="dropdown-menu3"
            >
              <span class="icon is-small">
                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu3" role="menu">
            <div class="dropdown-content" @blur="showMenu = false">
              <a class="dropdown-item" @click="edit()">Edit Venue</a>
              <a v-if="false" class="dropdown-item" @click="deleteDate()"
                >Delete Venue</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- EDIT MODE -->
    <div v-if="editing">
      <div class="columns" v-if="profile && profile.location">
        <div class="column">
          <div class="field">
            <label class="label">Venue Name</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Artist name"
                v-model.lazy="profile.name"
                @change="save"
                :readonly="profile.useMyName"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns" v-if="profile && profile.location">
        <div class="column">
          <div class="field">
            <label class="label">Venue Image</label>
            <div v-if="false">
              <Avatar />
            </div>
            <div class="imagefield" v-if="profile.image">
              <img :src="profile.image" v-if="profile.image" alt="User Image" />
              <button class="button" @click="profile.image = null">
                Change Image
              </button>
            </div>
            <UploadImage
              v-if="!profile.image"
              :outputWidth="400"
              :outputHeight="400"
              v-on:imageCropped="saveImage"
            />
            <div class="buttons"></div>
            <div v-if="showImageLoading">
              <progress
                class="progress is-primary"
                :value="percentComplete"
                max="100"
                >{{ percentComplete }}</progress
              >
              <p>Uploading image...</p>
            </div>
          </div>
        </div>
      </div>
      <div class="field"><label class="label">Venue Location</label></div>
      <VenueFinder v-on:venueFound="addVenue" v-if="!profile.location" />
      <VenueMap :venue="profile" v-if="profile && profile.location" />
      <div class="field taxes">
        <label class="label">Tax Status</label>
        <div class="field">
          <input
            type="radio"
            id="taxable"
            name="tax"
            v-model="profile.tax"
            value="remit"
            class="is-checkradio"
          />
          <label class="radio" for="taxable">
            Collect and Remit Sales Tax (default)
          </label>
          <input
            type="radio"
            id="taxexempt"
            name="tax"
            v-model="profile.tax"
            value="exempt"
            class="is-checkradio"
          />
          <label class="radio" for="taxexempt">
            Tax Exempt (we may ask for documentation)
          </label>
        </div>
      </div>
      <p v-if="profile && profile.location">
        (Optional) Add co-owners now or you can always add them later. If they
        don't currently have a Gobo account, they will need to create one.
      </p>
      <InviteUserStarter
        v-if="profile && profile.location"
        :inviterName="user.name"
        :inviterId="user.uid"
        :profileName="profile.name"
        :profileType="Venue"
        :venueId="profile.id"
        :newProfile="false"
      />
      <!-- <div class="field">
              <div class="control">
                <label class="checkbox">
                  <div>
                    <input type="checkbox" @change="useMyName()" />
                  </div>
                  I use my own name as listed above.
                </label>
              </div>
            </div> -->
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import store from "@/store";
import UploadImage from "@/components/upload-image";
import VenueMap from "@/components/venue-map";
import VenueFinder from "@/components/venue/venue-finder";
import InviteUserStarter from "@/components/invite-user-starter";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showImagePicker: true,
      showImageLoading: false,
      percentComplete: 0,
      showMenu: false,
      email: null,
      emailValid: false
    };
  },
  props: {
    profile: Object,
    editing: Boolean,
    editable: Boolean
  },
  components: { UploadImage, VenueMap, VenueFinder, InviteUserStarter },
  computed: {
    ...mapGetters({
      user: "user",
      profiles: "getProfiles"
    }),
    owners() {
      if (this.profile.owners) {
        let names = "";
        let x = 0;
        for (const owner in this.profile.owners) {
          if (x == 1) names += ", ";
          console.log("owner: ", owner);
          names += this.profile.owners[owner].name;
          x = 1;
        }
        return names;
      } else return "Ted";
    }
  },
  methods: {
    async save() {
      this.$emit("profileSet", this.profile);
    },
    addVenue(venue) {
      venue.owner = this.user.uid;
      venue.owners = {};
      venue.owners[this.user.uid] = { name: this.user.name };
      console.log("Logging this baby", venue);
      store.dispatch("addVenueProfile", venue);
      this.$emit("venueFound", this.venue.id);
    },
    edit() {
      this.$emit("edit", this.profile.id);
    },
    inviteVenueManager() {
      store.dispatch("newVenueManagerInvite", {
        email: this.email,
        profileName: this.profile.name,
        userName: this.user.name,
        festivalId: this.profile.id,
        owner: this.user.uid
      });
    },
    async saveImage(image) {
      var storageRef = firebase.storage().ref();
      var imagestr = "venues/" + this.profile.id + ".jpg";
      var imageRef = storageRef.child(imagestr);
      this.showImagePicker = false;
      this.showImageLoading = true;
      var upload = imageRef.put(image);

      // Update percent complete
      upload.on(
        "state_changed",
        upload => {
          var progress = (this.percentComplete =
            (upload.bytesTransferred / upload.totalBytes) * 100);
          console.log("Upload is " + progress + "% done");
        },
        e => {
          console.log("Upload error: ", e);
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(url => {
            this.image = url;
            console.log("url: ", url);
            this.showImageLoading = false;

            // Update profile
            this.profile.image = url;
            store.dispatch("saveVenueProfileImage", this.profile);
          });
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.imagefield {
  background-color: $gray6;
  border-radius: $radius;
  img {
    max-width: 230px;
    margin: 20px;
  }
  .button {
    margin-top: 18%;
  }
}
.managedby {
  font-size: 12px;
  margin: 12.5px;
}
p.artistname {
  margin: 12.5px;
}
.city {
  font-size: 12px;
}
.taxes {
  margin-top: 30px;
}
</style>
