<template>
  <tr>
    <td>
      <div class="synopsis">
        <div class="eventimagerapper">
          <div class="imageholder">
            <img :src="summary.event.image" alt="" class="eventimage" />
          </div>
        </div>
        <EventDateline :event="summary.event" />
        <div class="eventTitle">
          <router-link
            :to="{
              name: 'eventSummary',
              params: { id: summary.event.id }
            }"
            v-if="
              summary.event.status &&
                (summary.event.status == 'published' ||
                  summary.event.status == 'complete')
            "
            >{{ summary.event.title }}</router-link
          >
          <router-link
            :to="{
              name: summary.event.seasonId ? 'editFestivalEvent' : 'editEvent',
              params: { id: summary.event.id }
            }"
            v-if="
              !summary.event.status ||
                (summary.event.status != 'published' &&
                  summary.event.status != 'complete')
            "
            >{{ summary.event.title }}</router-link
          >
        </div>
        <div class="venue">{{ summary.event.venueName }}</div>
        <div class="is-clearfix"></div>
        <div class="stats is-hidden-tablet" v-if="summary.audienceReport">
          <div class="col">
            <div class="field">
              <label class="label">Sold</label>
              <div class="control">
                <span
                  v-if="
                    summary.audienceReport && summary.audienceReport.totalSold
                  "
                  ><strong>{{ summary.audienceReport.totalSold }}</strong> /{{
                    summary.audienceReport.capacity
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="col">
            <div class="field">
              <label class="label">Gross</label>
              <div class="control">
                <span v-if="summary.audienceReport"
                  >${{ summary.audienceReport.salesTotal }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="status is-hidden-tablet">
          <label class="label">Status</label>
          <span class="statusstatus">{{ summary.event.status }}</span>
        </div>
      </div>
      <div class="menubar is-hidden-tablet">
        <div class="buttons mobilebar">
          <button
            class="button is-info"
            :disabled="summary.event.status != 'draft'"
            @click="deleteDate(summary.event)"
          >
            Cancel
          </button>
          <button
            class="button is-info"
            @click="
              $router.push({
                name: summary.event.seasonId
                  ? 'editFestivalEvent'
                  : 'editEvent',
                params: { id: summary.event.id }
              })
            "
          >
            Edit
          </button>
          <button
            class="button is-info"
            @click="duplicateEvent(summary.event.id)"
          >
            Duplicate
          </button>
        </div>
      </div>
    </td>
    <td class="results is-hidden-mobile">
      <span v-if="summary.audienceReport && summary.audienceReport.totalSold"
        ><strong>{{ summary.audienceReport.totalSold }}</strong> /{{
          summary.audienceReport.capacity
        }}</span
      >
    </td>
    <td class="results is-hidden-mobile">
      <span v-if="summary.audienceReport"
        >${{ summary.audienceReport.salesTotal }}</span
      >
    </td>
    <td class="results is-hidden-mobile">{{ summary.event.status }}</td>
  </tr>
</template>

<script>
import EventDateline from "@/components/event-dateline";

export default {
  name: "EventSummary",
  props: {
    summary: Object
  },
  components: {
    EventDateline
  },
  // data() {
  //   return {
  //     expanded: false
  //   };
  // }
};
</script>
