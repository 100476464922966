var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.season
    ? _c("div", { staticClass: "body-wrapper" }, [
        _c("div", { staticClass: "body-content gray" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-one-quarter" }, [
                _vm._m(0),
                _vm.step > 0
                  ? _c("div", { staticClass: "box is-hidden-mobile" }, [
                      _c("div", { staticClass: "block" }, [
                        _c("aside", { staticClass: "menu" }, [
                          _c("p", { staticClass: "menu-label" }, [
                            _vm._v(" Festival Set Up ")
                          ]),
                          _c("ul", { staticClass: "menu-list" }, [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: { "is-active": _vm.step == 1 },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStep(1)
                                    }
                                  }
                                },
                                [_vm._v("Basic Info")]
                              )
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: { "is-active": _vm.step == 2 },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStep(2)
                                    }
                                  }
                                },
                                [_vm._v("Location")]
                              )
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: { "is-active": _vm.step == 3 },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStep(3)
                                    }
                                  }
                                },
                                [_vm._v("Ticket Info")]
                              )
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: { "is-active": _vm.step == 4 },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStep(4)
                                    }
                                  }
                                },
                                [_vm._v("Discount Codes")]
                              )
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: { "is-active": _vm.step == 5 },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStep(5)
                                    }
                                  }
                                },
                                [_vm._v("Passes")]
                              )
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: { "is-active": _vm.step == 6 },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStep(6)
                                    }
                                  }
                                },
                                [_vm._v("Email Settings")]
                              )
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: { "is-active": _vm.step == 7 },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStep(7)
                                    }
                                  }
                                },
                                [_vm._v("Shows")]
                              )
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: { "is-active": _vm.step == 8 },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStep(8)
                                    }
                                  }
                                },
                                [_vm._v("Preview and Publish")]
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 0,
                      expression: "step == 0"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Choose Festival Profile")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("p", [
                        _vm._v(
                          " Choose a festival profile who is presenting this festival season. "
                        )
                      ]),
                      _vm._l(_vm.festivals, function(festival, index) {
                        return _c("Festival", {
                          key: index,
                          attrs: {
                            festival: festival,
                            selectable: true,
                            selected: _vm.season.festivalId == festival.id
                          },
                          on: { selected: _vm.selectFestival }
                        })
                      }),
                      _vm._m(1)
                    ],
                    2
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 1,
                      expression: "step == 1"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Basic Info")]),
                  _c("div", { staticClass: "box" }, [
                    _c(
                      "div",
                      { ref: "croppiecont", staticClass: "field" },
                      [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Festival Image")
                        ]),
                        _vm.season.image
                          ? _c("img", {
                              staticClass: "eventimageheader",
                              attrs: { src: _vm.season.image, alt: "" }
                            })
                          : _vm._e(),
                        _vm.season.image && _vm.season.status != "complete"
                          ? _c(
                              "button",
                              {
                                staticClass: "button",
                                on: {
                                  click: function($event) {
                                    _vm.season.image = null
                                  }
                                }
                              },
                              [_vm._v(" Use a Different Image ")]
                            )
                          : _vm._e(),
                        !_vm.season.image &&
                        !_vm.cropping &&
                        !_vm.showImageLoading
                          ? _c("UploadImage", {
                              attrs: { outputWidth: 800, outputHeight: 360 },
                              on: { imageCropped: _vm.crop }
                            })
                          : _vm._e(),
                        _vm.showImageLoading
                          ? _c("div", [
                              _c(
                                "progress",
                                {
                                  staticClass: "progress is-primary",
                                  attrs: { max: "100" },
                                  domProps: { value: _vm.percentComplete }
                                },
                                [_vm._v(_vm._s(_vm.percentComplete))]
                              ),
                              _c("p", [_vm._v("Uploading image...")])
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column" }, [
                        _c("div", { staticClass: "field" }, [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Festival Title")
                          ]),
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.lazy",
                                  value: _vm.season.title,
                                  expression: "season.title",
                                  modifiers: { lazy: true }
                                }
                              ],
                              staticClass: "input is-primary",
                              class: { "is-danger": _vm.errors.title },
                              attrs: {
                                type: "text",
                                placeholder: "Festival Title",
                                disabled: _vm.season.status == "complete"
                              },
                              domProps: { value: _vm.season.title },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.season,
                                      "title",
                                      $event.target.value
                                    )
                                  },
                                  function($event) {
                                    return _vm.saveFestival()
                                  }
                                ]
                              }
                            })
                          ]),
                          _vm.errors.title
                            ? _c("p", { staticClass: "help is-danger" }, [
                                _vm._v(" " + _vm._s(_vm.errors.title) + " ")
                              ])
                            : _vm._e()
                        ]),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Festival Profile")
                            ]),
                            _vm.season.festivalId
                              ? _c("Festival", {
                                  attrs: {
                                    festival: _vm.festival,
                                    editable: false
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("div", { staticClass: "field" }, [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Festival Type")
                          ]),
                          _c("div", { staticClass: "select" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.season.type,
                                    expression: "season.type"
                                  }
                                ],
                                attrs: {
                                  disabled: _vm.season.status == "complete"
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.season,
                                        "type",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function($event) {
                                      return _vm.eventTypeChange()
                                    }
                                  ]
                                }
                              },
                              [
                                _c("option", { attrs: { value: "music" } }, [
                                  _vm._v("Music")
                                ]),
                                _c("option", { attrs: { value: "theater" } }, [
                                  _vm._v("Theater")
                                ]),
                                _c("option", { attrs: { value: "dance" } }, [
                                  _vm._v("Dance")
                                ]),
                                _c("option", { attrs: { value: "improv" } }, [
                                  _vm._v("Improv")
                                ]),
                                _c(
                                  "option",
                                  { attrs: { value: "storytelling" } },
                                  [_vm._v("Storytelling")]
                                ),
                                _c("option", { attrs: { value: "standup" } }, [
                                  _vm._v("Standup Comedy")
                                ]),
                                _c(
                                  "option",
                                  { attrs: { value: "conferences" } },
                                  [_vm._v("Conferences/Exhibitions")]
                                ),
                                _c("option", { attrs: { value: "opera" } }, [
                                  _vm._v("Opera")
                                ]),
                                _c(
                                  "option",
                                  { attrs: { value: "music theater" } },
                                  [_vm._v("Music Theater")]
                                ),
                                _c("option", { attrs: { value: "art" } }, [
                                  _vm._v("Visual Art")
                                ]),
                                _c(
                                  "option",
                                  { attrs: { value: "gatherings" } },
                                  [_vm._v("Social Gatherings")]
                                ),
                                _c("option", { attrs: { value: "sports" } }, [
                                  _vm._v("Sports")
                                ]),
                                _c("option", { attrs: { value: "children" } }, [
                                  _vm._v("Children's Programs")
                                ]),
                                _c("option", { attrs: { value: "other" } }, [
                                  _vm._v("Other")
                                ])
                              ]
                            )
                          ]),
                          false
                            ? _c("p", { staticClass: "help is-danger" }, [
                                _vm._v(" Please choose a type. ")
                              ])
                            : _vm._e()
                        ]),
                        _vm.season.type == "other"
                          ? _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.lazy",
                                      value: _vm.season.typeOther,
                                      expression: "season.typeOther",
                                      modifiers: { lazy: true }
                                    }
                                  ],
                                  staticClass: "input is-primary",
                                  class: { "is-danger": _vm.errors.genre },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Event Type",
                                    disabled: _vm.season.status == "complete"
                                  },
                                  domProps: { value: _vm.season.typeOther },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.season,
                                        "typeOther",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm.errors.genre
                                ? _c("p", { staticClass: "help is-danger" }, [
                                    _vm._v(" " + _vm._s(_vm.errors.genre) + " ")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Festival Description")
                      ]),
                      _c("label", { staticClass: "label" }, [
                        _vm._v(
                          "Add details like what to expect, list of performers or sponsors."
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("quill-editor", {
                            ref: "myQuillEditor",
                            class: { "ql-focus": _vm.editorHasFocus },
                            attrs: {
                              content: _vm.seasonDescription,
                              options: _vm.editorOption
                            },
                            on: {
                              blur: function($event) {
                                _vm.editorHasFocus = false
                              },
                              focus: function($event) {
                                _vm.editorHasFocus = true
                              },
                              change: function($event) {
                                return _vm.saveSeasonDescription($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.errors.description
                        ? _c("p", { staticClass: "help is-danger" }, [
                            _vm._v(" " + _vm._s(_vm.errors.description) + " ")
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 2,
                      expression: "step == 2"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Location")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _vm.season.status != "published" &&
                      _vm.season.status != "complete"
                        ? _c("div", { staticClass: "field" }, [
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Venue Location")
                            ]),
                            !_vm.venue
                              ? _c(
                                  "div",
                                  { staticClass: "control has-icons-left" },
                                  [
                                    _c("GmapAutocomplete", {
                                      staticClass: "input",
                                      attrs: { fields: _vm.fields },
                                      on: { place_changed: _vm.setPlace }
                                    }),
                                    _vm._m(2)
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.locationError
                              ? _c(
                                  "p",
                                  { staticClass: "notification is-danger" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.locationError) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm.venue
                        ? _c("VenueMap", { attrs: { venue: _vm.venue } })
                        : _vm._e(),
                      _vm.venue && _vm.venue.owner
                        ? _c(
                            "div",
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.venue.name) + " is using Gobo!"
                                )
                              ]),
                              _c("Venue", {
                                attrs: { profile: _vm.venue, editable: false }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.venue && !_vm.venue.owner
                        ? _c(
                            "div",
                            [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.venue.name) +
                                    " is not using Gobo yet. Invite them to help sell tickets with you. "
                                )
                              ]),
                              _c("InviteUserStarter", {
                                attrs: {
                                  inviterName: _vm.user.name,
                                  inviterId: _vm.user.uid,
                                  profileName: _vm.venue.name,
                                  venueId: _vm.venue.id,
                                  newProfile: true
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.venue ? _c("hr") : _vm._e(),
                      _vm.venue
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                on: { click: _vm.clearVenue }
                              },
                              [_vm._v(" Use a Different Venue ")]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 3,
                      expression: "step == 3"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Ticket Info")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("p", [
                        _vm._v(
                          " You can create up to five named tiers of tickets (e.g., Pre-sale, VIP, General Admission, Guest List, etc.) "
                        )
                      ]),
                      _c("nav", { staticClass: "level" }, [
                        _c("div", { staticClass: "level-left" }, [
                          _c("div", { staticClass: "field" }, [
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Total tickets available")
                            ]),
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.season.capacity,
                                    expression: "season.capacity",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "input is-primary",
                                class: { "is-danger": _vm.errors.capacity },
                                attrs: {
                                  type: "number",
                                  placeholder: "Number of tickets",
                                  disabled: _vm.season.status == "complete"
                                },
                                domProps: { value: _vm.season.capacity },
                                on: {
                                  change: function($event) {
                                    return _vm.saveFestival()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.season,
                                      "capacity",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]),
                            _vm.errors.capacity
                              ? _c("p", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.errors.capacity) + " "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _c("div", { staticClass: "level-right" }, [
                          _c("div", { staticClass: "field" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.season.donation,
                                  expression: "season.donation"
                                }
                              ],
                              staticClass: "switch",
                              attrs: {
                                id: "donation",
                                type: "checkbox",
                                name: "switchExample"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.season.donation)
                                  ? _vm._i(_vm.season.donation, null) > -1
                                  : _vm.season.donation
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.season.donation,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.season,
                                            "donation",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.season,
                                            "donation",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.season, "donation", $$c)
                                    }
                                  },
                                  function($event) {
                                    return _vm.saveFestival()
                                  }
                                ]
                              }
                            }),
                            _c("label", { attrs: { for: "donation" } }, [
                              _vm._v("Artist Donation")
                            ])
                          ])
                        ])
                      ]),
                      _c("hr"),
                      _vm._l(_vm.season.priceTiers, function(tier) {
                        return _c("PricingTier", {
                          key: tier.id,
                          attrs: {
                            tier: tier,
                            flexible: _vm.season.capacity,
                            disable: _vm.season.status == "complete"
                          },
                          on: { tierSet: _vm.saveTier, deleted: _vm.deleteTier }
                        })
                      }),
                      _vm.unsavedTier
                        ? _c("PricingTier", {
                            attrs: {
                              tier: _vm.unsavedTier,
                              flexible: _vm.season.capacity
                            },
                            on: {
                              tierSet: _vm.saveTier,
                              deleted: _vm.deleteTier
                            }
                          })
                        : _vm._e(),
                      _c("p", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: {
                              disabled:
                                _vm.unsavedTier ||
                                (_vm.season.priceTiers &&
                                  _vm.season.priceTiers.length > 4)
                            },
                            on: { click: _vm.newPriceTier }
                          },
                          [_vm._m(3), _c("span", [_vm._v("Add ticket tier")])]
                        )
                      ]),
                      _vm.season.priceTiers &&
                      _vm.season.priceTiers.length > 0 &&
                      _vm.season.priceTiers.filter(t => t.feeType != "free")
                        .length > 0
                        ? _c("div")
                        : _vm._e()
                    ],
                    2
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 4,
                      expression: "step == 4"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Discount Codes")]),
                  _vm.seasonSettings && _vm.seasonSettings.discounts
                    ? _c("DiscountCodes", {
                        attrs: {
                          discounts: _vm.seasonSettings.discounts,
                          disabled: _vm.season.status == "complete",
                          festivalId: _vm.season.id
                        },
                        on: {
                          newDiscount: _vm.newDiscount,
                          codeChanged: _vm.saveDiscount
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 5,
                      expression: "step == 5"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Passes")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("p", [
                        _vm._v(
                          " Passes allow patrons access to more than one show with a single purchase. Allow unlimited access or access to a set number of shows. "
                        )
                      ]),
                      _vm._l(_vm.season.passes, function(pass, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "columns" },
                          [
                            _c("div", { staticClass: "column" }, [
                              _c("div", { staticClass: "field" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Pass Name")
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: pass.name,
                                        expression: "pass.name"
                                      }
                                    ],
                                    staticClass: "input is-primary",
                                    class: { "is-danger": _vm.errors.capacity },
                                    attrs: {
                                      type: "text",
                                      placeholder: "Pass Name",
                                      disabled: _vm.season.status == "complete"
                                    },
                                    domProps: { value: pass.name },
                                    on: {
                                      change: function($event) {
                                        return _vm.saveFestival()
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          pass,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm.errors.capacity
                                  ? _c(
                                      "p",
                                      { staticClass: "help is-dange r" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.errors.capacity) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "field" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Pass Description")
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: pass.description,
                                        expression: "pass.description"
                                      }
                                    ],
                                    staticClass: "textarea is-fullwidth",
                                    attrs: {
                                      disabled: _vm.season.status == "complete"
                                    },
                                    domProps: { value: pass.description },
                                    on: {
                                      change: function($event) {
                                        return _vm.saveFestival()
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          pass,
                                          "description",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm.errors.capacity
                                  ? _c("p", { staticClass: "help is-danger" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.errors.capacity) + " "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _c("div", { staticClass: "field" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Price")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "control has-icons-left" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: pass.price,
                                          expression: "pass.price"
                                        }
                                      ],
                                      staticClass: "input is-primary",
                                      class: {
                                        "is-danger": _vm.errors.capacity
                                      },
                                      attrs: {
                                        type: "number",
                                        placeholder: "Price",
                                        disabled:
                                          _vm.season.status == "complete"
                                      },
                                      domProps: { value: pass.price },
                                      on: {
                                        change: function($event) {
                                          return _vm.saveFestival()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            pass,
                                            "price",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._m(4, true)
                                  ]
                                ),
                                _vm.errors.capacity
                                  ? _c("p", { staticClass: "help is-danger" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.errors.capacity) + " "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _c("div", { staticClass: "field" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Type")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "select is-fullwidth" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: pass.type,
                                            expression: "pass.type"
                                          }
                                        ],
                                        attrs: {
                                          disabled:
                                            _vm.season.status == "complete"
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                pass,
                                                "type",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            _vm.saveFestival
                                          ]
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { value: "unlimited" } },
                                          [_vm._v("Unlimited")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "limited" } },
                                          [_vm._v("Limited")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              pass.type == "limited"
                                ? _c("div", { staticClass: "field" }, [
                                    _c("label", { staticClass: "label" }, [
                                      _vm._v("Limit")
                                    ]),
                                    _c("div", { staticClass: "control" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: pass.limit,
                                            expression: "pass.limit"
                                          }
                                        ],
                                        staticClass: "input is-primary",
                                        class: {
                                          "is-danger": _vm.errors.capacity
                                        },
                                        attrs: {
                                          type: "number",
                                          placeholder: "Limit",
                                          disabled:
                                            _vm.season.status == "complete"
                                        },
                                        domProps: { value: pass.limit },
                                        on: {
                                          change: function($event) {
                                            return _vm.saveFestival()
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              pass,
                                              "limit",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]),
                                    _vm.errors.capacity
                                      ? _c(
                                          "p",
                                          { staticClass: "help is-danger" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.errors.capacity) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "column is-narrow" }, [
                              _c("div", { staticClass: "column is-narrow" }, [
                                _vm.season.status != "complete"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "icon is-medium delete",
                                        on: {
                                          click: function($event) {
                                            return _vm.deletePass(index)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-lg fa-trash"
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]
                        )
                      }),
                      _c("p", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: {
                              disabled:
                                _vm.unsavedTier ||
                                (_vm.season.priceTiers &&
                                  _vm.season.priceTiers.length > 4)
                            },
                            on: { click: _vm.newPass }
                          },
                          [_vm._m(5), _c("span", [_vm._v("Add pass")])]
                        )
                      ])
                    ],
                    2
                  )
                ]
              ),
              _vm.seasonSettings
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.step == 6,
                          expression: "step == 6"
                        }
                      ],
                      staticClass: "column"
                    },
                    [
                      _c("h4", [_vm._v("Email Settings")]),
                      _c(
                        "div",
                        { staticClass: "box" },
                        [
                          _c("div", { staticClass: "field" }, [
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Email Message")
                            ]),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(
                                "Add any extra information you would like to include about the event to be added to the end of the email confirmation (parking, transportation, doors open time, etc.)."
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("quill-editor", {
                                  ref: "emailMessageEditor",
                                  class: {
                                    "ql-focus": _vm.emailEditorHasFocus
                                  },
                                  attrs: {
                                    content: _vm.seasonSettings.emailMessage,
                                    options: _vm.editorOption
                                  },
                                  on: {
                                    blur: function($event) {
                                      _vm.emailEditorHasFocus = false
                                    },
                                    focus: function($event) {
                                      _vm.emailEditorHasFocus = true
                                    },
                                    change: function($event) {
                                      return _vm.updateEmail($event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          false
                            ? _c("TestEmailSender", {
                                attrs: {
                                  type: "confirmation",
                                  eventId: _vm.event.id
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.seasonSettings.sendReminderEmails,
                                expression: "seasonSettings.sendReminderEmails"
                              }
                            ],
                            staticClass: "is-checkradio is-success",
                            class: {
                              "has-background-color":
                                _vm.seasonSettings.sendReminderEmails
                            },
                            attrs: {
                              type: "checkbox",
                              id: "showPreshowReminder"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.seasonSettings.sendReminderEmails
                              )
                                ? _vm._i(
                                    _vm.seasonSettings.sendReminderEmails,
                                    null
                                  ) > -1
                                : _vm.seasonSettings.sendReminderEmails
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a =
                                      _vm.seasonSettings.sendReminderEmails,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.seasonSettings,
                                          "sendReminderEmails",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.seasonSettings,
                                          "sendReminderEmails",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.seasonSettings,
                                      "sendReminderEmails",
                                      $$c
                                    )
                                  }
                                },
                                _vm.saveSettings
                              ]
                            }
                          }),
                          _c(
                            "label",
                            { attrs: { for: "showPreshowReminder" } },
                            [_vm._v(" Send Preshow Reminder Emails ")]
                          )
                        ]),
                        _vm.seasonSettings &&
                        _vm.seasonSettings.sendReminderEmails
                          ? _c(
                              "div",
                              { staticClass: "field bumpadown" },
                              [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Preshow Reminder Email")
                                ]),
                                _c("label", { staticClass: "label" }, [
                                  _vm._v(
                                    "Add any extra information you would like to include about the event to be added to the end of the preshow reminder email (parking, transportation, doors open time, etc.)."
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c("quill-editor", {
                                      ref: "emailMessageEditor",
                                      class: {
                                        "ql-focus":
                                          _vm.emailReminderEditorHasFocus
                                      },
                                      attrs: {
                                        content:
                                          _vm.seasonSettings
                                            .emailReminderMessage,
                                        options: _vm.editorOption
                                      },
                                      on: {
                                        blur: function($event) {
                                          _vm.emailReminderEditorHasFocus = false
                                        },
                                        focus: function($event) {
                                          _vm.emailReminderEditorHasFocus = true
                                        },
                                        change: function($event) {
                                          return _vm.updateReminderEmail($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                false
                                  ? _c("TestEmailSender", {
                                      attrs: {
                                        type: "reminder",
                                        eventId: _vm.event.id
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        { staticClass: "box" },
                        [
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.seasonSettings.sendThankyouEmails,
                                  expression:
                                    "seasonSettings.sendThankyouEmails"
                                }
                              ],
                              staticClass: "is-checkradio is-success",
                              class: {
                                "has-background-color":
                                  _vm.seasonSettings.sendThankyouEmails
                              },
                              attrs: {
                                type: "checkbox",
                                id: "sendThankyouEmails"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.seasonSettings.sendThankyouEmails
                                )
                                  ? _vm._i(
                                      _vm.seasonSettings.sendThankyouEmails,
                                      null
                                    ) > -1
                                  : _vm.seasonSettings.sendThankyouEmails
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a =
                                        _vm.seasonSettings.sendThankyouEmails,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.seasonSettings,
                                            "sendThankyouEmails",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.seasonSettings,
                                            "sendThankyouEmails",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.seasonSettings,
                                        "sendThankyouEmails",
                                        $$c
                                      )
                                    }
                                  },
                                  _vm.saveSettings
                                ]
                              }
                            }),
                            _c(
                              "label",
                              { attrs: { for: "sendThankyouEmails" } },
                              [_vm._v(" Send Postshow Thank-You Emails ")]
                            )
                          ]),
                          _vm.seasonSettings &&
                          _vm.seasonSettings.sendThankyouEmails
                            ? _c("div", { staticClass: "field bumpadown" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Postshow Thank-You Email")
                                ]),
                                _c("label", { staticClass: "label" }, [
                                  _vm._v(
                                    "Add any extra information you would like to include about the event to be added to the end of the postshow thank-you email (survey, request for feedback, etc.)."
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c("quill-editor", {
                                      ref: "emailMessageEditor",
                                      class: {
                                        "ql-focus":
                                          _vm.emailThankyouEditorHasFocus
                                      },
                                      attrs: {
                                        content:
                                          _vm.seasonSettings
                                            .emailThankyouMessage,
                                        options: _vm.editorOption
                                      },
                                      on: {
                                        blur: function($event) {
                                          _vm.emailThankyouEditorHasFocus = false
                                        },
                                        focus: function($event) {
                                          _vm.emailThankyouEditorHasFocus = true
                                        },
                                        change: function($event) {
                                          return _vm.updateThankyouEmail($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          false
                            ? _c("TestEmailSender", {
                                attrs: {
                                  type: "thankyou",
                                  eventId: _vm.event.id
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 7,
                      expression: "step == 7"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Shows")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("p", [
                        _vm._v(
                          " Add shows to your festival. Shows are events within the festival that tickets are sold to on an individual or group basis. "
                        )
                      ]),
                      _vm._l(_vm.events, function(event) {
                        return _c(
                          "div",
                          { key: event.id, staticClass: "columns" },
                          [
                            _c("div", { staticClass: "column is-narrow" }, [
                              _c("div", { staticClass: "avatar-generic" }, [
                                _c("img", {
                                  staticClass: "eventimage",
                                  attrs: { src: event.image, alt: "" }
                                })
                              ])
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _c("p", { staticClass: "artistname" }, [
                                _c(
                                  "strong",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "editFestivalEvent",
                                            params: { id: event.id }
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(event.title))]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v("   |   "),
                                _c("span", { staticClass: "city" }, [
                                  _vm._v(_vm._s(event.act))
                                ])
                              ])
                            ])
                          ]
                        )
                      }),
                      _c("p", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          { staticClass: "button", on: { click: _vm.newShow } },
                          [_vm._m(6), _c("span", [_vm._v("Add show")])]
                        )
                      ])
                    ],
                    2
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 8,
                      expression: "step == 8"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Preview + Publish")]),
                  _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("div", { staticClass: "event-preview" }, [
                          _c("div", { staticClass: "event-preview-header" }, [
                            _vm.season.image
                              ? _c("img", {
                                  attrs: { src: _vm.season.image, alt: "" }
                                })
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "event-preview-body" }, [
                            _c("h3", [_vm._v(_vm._s(_vm.season.title))]),
                            _c(
                              "button",
                              {
                                staticClass: "button is-fullwidth",
                                on: {
                                  click: function($event) {
                                    return _vm.preview()
                                  }
                                }
                              },
                              [_vm._v(" Preview Festival ")]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "column is-half" }, [
                        _c("div", { staticClass: "control" }, [
                          _c("label", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.season.isPublic,
                                  expression: "season.isPublic"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "answer",
                                value: "true"
                              },
                              domProps: {
                                checked: _vm._q(_vm.season.isPublic, "true")
                              },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.season,
                                      "isPublic",
                                      "true"
                                    )
                                  },
                                  function($event) {
                                    return _vm.saveFestival()
                                  }
                                ]
                              }
                            }),
                            _vm._v(" Public ")
                          ]),
                          _c("p", { staticClass: "radio-label" }, [
                            _vm._v("Shared on Gobo and searchable")
                          ]),
                          _c("label", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.season.isPublic,
                                  expression: "season.isPublic"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "answer",
                                value: "false"
                              },
                              domProps: {
                                checked: _vm._q(_vm.season.isPublic, "false")
                              },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.season,
                                      "isPublic",
                                      "false"
                                    )
                                  },
                                  function($event) {
                                    return _vm.saveFestival()
                                  }
                                ]
                              }
                            }),
                            _vm._v(" Private ")
                          ]),
                          _c("p", { staticClass: "radio-label" }, [
                            _vm._v(
                              " Only available to you and the people you share it with. "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm.cantPublish && _vm.season.status == "draft"
                    ? _c("div", { staticClass: "box" }, [
                        _c("h4", [_vm._v("Before you publish")]),
                        _c("p", [
                          _vm._v(
                            " There's a few things you need to do before you can publish your festival. "
                          )
                        ]),
                        _c("ul", [
                          !_vm.season.title
                            ? _c("li", [
                                _vm._v(" Please give your festival a title ")
                              ])
                            : _vm._e(),
                          !_vm.season.genre
                            ? _c("li", [_vm._v("Set an festival type")])
                            : _vm._e(),
                          !_vm.season.act
                            ? _c("li", [_vm._v("Specify an act")])
                            : _vm._e(),
                          !_vm.season.description
                            ? _c("li", [
                                _vm._v(" Give your festival a description ")
                              ])
                            : _vm._e(),
                          !_vm.season.image
                            ? _c("li", [_vm._v("Add an festival image")])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c("div", {
                staticClass:
                  "column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "box stepbar" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }, [
                _c("div", { staticClass: "buttons is-right" }, [
                  _vm.step > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              _vm.step = _vm.step - 1
                            }
                          }
                        },
                        [_vm._v(" Back ")]
                      )
                    : _vm._e(),
                  _vm.step < 6
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          attrs: {
                            disabled:
                              _vm.cropping ||
                              (_vm.step == 0 && !_vm.season.festivalId)
                          },
                          on: {
                            click: function($event) {
                              return _vm.next()
                            }
                          }
                        },
                        [_vm._v(" Next ")]
                      )
                    : _vm._e(),
                  _vm.step == 6
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          on: {
                            click: function($event) {
                              return _vm.next()
                            }
                          }
                        },
                        [_vm._v(" Preview ")]
                      )
                    : _vm._e(),
                  _vm.season.status == "draft" && _vm.step == 7
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          attrs: { disabled: _vm.cantPublish },
                          on: {
                            click: function($event) {
                              return _vm.publish()
                            }
                          }
                        },
                        [_vm._v(" Publish ")]
                      )
                    : _vm._e()
                ])
              ]),
              _c("div", {
                staticClass:
                  "column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
              })
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_vm._v(" Create a"), _c("br"), _vm._v(" festival! ")])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "button" }, [
      _c("span", { staticClass: "icon is-small" }, [
        _c("i", { staticClass: "fas fa-plus" })
      ]),
      _c("span", [_vm._v("New Festival")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-search" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-medium" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-medium" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-medium" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }