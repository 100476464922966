var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "columns" }, [
      _c("div", {
        staticClass: "column is-3 is-2-desktop is-1-tablet is-hidden-mobile"
      }),
      _c("div", { staticClass: "column contentpad" }, [
        _c("h1", [_vm._v("Gobo Pilot Help")]),
        _c("p", [
          _vm._v(" We are in pilot mode. If you are having "),
          _c("strong", [_vm._v("ANY")]),
          _vm._v(
            " issues at this time, please call Josh at 612-237-9810. We will add tips and tricks to this page as we go. "
          )
        ])
      ]),
      _c("div", {
        staticClass: "column is-3 is-2-desktop is-1-tablet is-hidden-mobile"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }