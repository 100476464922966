<template>
  <div>
    <div class="field">
      <label for="" class="label">Send a test email</label>
      <div class="columns">
        <div class="column">
          <EmailField
            :disabled="sending"
            :email.sync="email"
            :valid.sync="valid"
          ></EmailField>
        </div>
        <div class="column is-narrow">
          <button
            :disabled="sending || !valid"
            class="button"
            :class="{
              'is-loading': sending
            }"
            @click="send"
          >
            Send Test Email
          </button>
        </div>
      </div>
    </div>
    <p v-if="sending">
      Sending email...
    </p>
    <p v-if="userRequest && userRequest.emailType == type && userRequest.status == 'complete'">
      Test email sent!
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import EmailField from "@/components/formControls/email-field";

export default {
  name: "TestEmailSender",
  props: ["type", "eventId"],
  data() {
    return {
      email: "",
      valid: false,
      emailConfirmationDisabled: false
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      userRequest: "userRequest",
      user: "user"
    }),
    sending() {
      if (this.userRequest)
        return (
          this.userRequest.status == "starting" ||
          this.userRequest.status == "submitted"
        );
      else return this.emailConfirmationDisabled;
    }
  },
  components: { EmailField },
  methods: {
    async send() {
      this.emailConfirmationDisabled = true;
      store.dispatch("sendTestEmail", {
        eventId: this.eventId,
        email: this.email,
        type: this.type,
        userId: this.user.uid
      });
    },
  }
};
</script>

<style></style>
