var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns" }, [
    _c(
      "div",
      { staticClass: "column" },
      [
        _c("EmailField", {
          attrs: {
            email: _vm.email,
            valid: _vm.emailValid,
            placeholder: _vm.placeholder
          },
          on: {
            "update:email": function($event) {
              _vm.email = $event
            },
            "update:valid": function($event) {
              _vm.emailValid = $event
            }
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "column is-narrow" }, [
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { disabled: !_vm.emailValid },
          on: { click: _vm.invite }
        },
        [_vm._v(" Invite ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }