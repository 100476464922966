<template>
  <div class="venue-map">
    <gmap-map
      :center="venue.location"
      :zoom="17"
      mapTypeControl="false"
      style="width: 100%; height: 300px"
      :options="options"
    >
      <gmap-marker
        :position="venue.location"
        :clickable="true"
        :draggable="true"
        :icon="'/img/icons/icon-pin.svg'"
        @click="center = venue.location"
      ></gmap-marker>
    </gmap-map>
    <div class="map-legend">
      <h3>{{ venue.name }}</h3>
      <p>{{ venue.fullAddress }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        disableDefaultUI: true,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",

            stylers: [
              {
                visibility: "off"
              }
            ]
          }
        ],
        mapId: "a7f1aa6c21b72aae"
      }
    };
  },
  props: {
    venue: Object
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.venue-map {
  border-radius: 6px;
  background-color: #f2f2f2;
}
.map-legend {
  border-radius: 3px;
  background-color: white;
  border: 3px solid #f2f2f2;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 1.5rem 2.5rem;
}
.map-legend h3 {
  font-size: 2rem;
}
.map-legend p {
  font-size: 20px;
  margin-bottom: 0px;
}
.vue-map-container .vue-map {
  border-radius: 6px;
}
</style>
