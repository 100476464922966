<template>
  <div class="body-wrapper">
    <div class="body-content gray">
      <div class="columns">
        <div class="column is-one-quarter">
          <h1 v-if="!user || !user.eventPublished">
            Let's <br />
            get <br />
            started
          </h1>
          <h1 v-if="user && user.eventPublished">
            Your<br />
            Profile
          </h1>
          <div class="box is-hidden-mobile">
            <div class="block">
              <aside class="menu">
                <p class="menu-label">
                  Account Set Up
                </p>
                <ul class="menu-list">
                  <li>
                    <a
                      v-bind:class="{ 'is-active': step == 1 }"
                      @click="step = 1"
                      >User Profile</a
                    >
                  </li>
                  <li v-if="user.performingArtist">
                    <a
                      v-bind:class="{ 'is-active': step == 2 }"
                      @click="step = 2"
                      >My Artists</a
                    >
                  </li>
                  <li v-if="user.venueManager">
                    <a
                      v-bind:class="{ 'is-active': step == 3 }"
                      @click="step = 3"
                      >My Venues</a
                    >
                  </li>
                  <li v-if="user.festivalManager">
                    <a
                      v-bind:class="{ 'is-active': step == 4 }"
                      @click="step = 4"
                      >My Festivals</a
                    >
                  </li>
                  <li>
                    <a
                      v-bind:class="{ 'is-active': step == 5 }"
                      @click="step = 5"
                      >Payment Information</a
                    >
                  </li>
                </ul>
              </aside>
            </div>
          </div>
        </div>
        <div class="column" v-show="step == 1">
          <h4>
            Finalize your account, review your user name and add an image.
          </h4>
          <div class="box">
            <p>
              What name should we use to refer to you? (not your artist name, venue name, or
              festival name, we'll take care of that later)
            </p>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">User Name</label>
                  <div class="control">
                    <input
                      class="input is-primary"
                      type="text"
                      placeholder="User Name"
                      v-model="user.name"
                      @change="saveUser"
                    />
                  </div>
                  <p class="help is-danger" v-if="false">
                    Please enter a name.
                  </p>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label">Roles</label>
              <div class="control">
                <input
                  type="checkbox"
                  id="isPerformingArtist"
                  :checked="profiles && profiles.length > 0"
                  :disabled="profiles && profiles.length > 0"
                  v-model="user.performingArtist"
                  :class="{
                    'has-background-color': user.performingArtist
                  }"
                  class="is-checkradio is-success"
                  @change="saveUser"
                />
                <label for="isPerformingArtist">
                  Performing Artist
                </label>
                <input
                  type="checkbox"
                  id="isVenueManager"
                  v-model="user.venueManager"
                  :class="{
                    'has-background-color': user.venueManager
                  }"
                  class="is-checkradio is-success"
                  @change="saveUser"
                />
                <label for="isVenueManager">
                  Venue Manager
                </label>
                <input
                  type="checkbox"
                  id="isFestivalManager"
                  v-model="user.festivalManager"
                  :class="{
                    'has-background-color': user.festivalManager
                  }"
                  class="is-checkradio is-success"
                  @change="saveUser"
                />
                <label for="isFestivalManager">
                  Festival Manager
                </label>
              </div>
            </div>
            <div>
              <button
                style="display: none;"
                class="button is-primary"
                @click="facebook"
              >
                Facebook Me
              </button>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">User Image</label>
                  <div v-if="false">
                    <Avatar />
                  </div>
                  <div
                    class="imagefield"
                    v-if="!showImagePicker && !showImageLoading"
                  >
                    <img
                      :src="user.avatar"
                      v-if="user.avatar"
                      alt="User Image"
                    />
                    <button class="button" @click="user.avatar = null">
                      Change Image
                    </button>
                  </div>
                  <UploadImage
                    v-if="showImagePicker"
                    :outputWidth="230"
                    :outputHeight="230"
                    v-on:imageCropped="saveImage"
                  />
                  <div class="buttons"></div>
                  <div v-if="showImageLoading">
                    <progress
                      class="progress is-primary"
                      :value="percentComplete"
                      max="100"
                      >{{ percentComplete }}</progress
                    >
                    <p>Uploading image...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="box">
            <p>
              Why are you here (if more than one applies, pick one to start
              with- you can add more profiles later)?
            </p>
            <div class="select is-fullwidth">
              <select>
                <option>I create live entertainment or art</option>
                <option>I produce live events</option>
                <option>I work for or run a venue</option>
                <option>I work for or run a festival</option>
                <option></option>
              </select>
            </div>
          </div> -->
        </div>
        <!-- ARTIST SETUP  -->
        <div class="column" v-show="step == 2">
          <div class="columns">
            <div class="column">
              <h4>Add artist profile(s) to our database</h4>
            </div>
          </div>
          <div class="box">
            <p v-if="false">
              By creating an artist profile, you will be the owner of that
              profile. That means that you will have access to change
              information in the profile or delete it and create events for it.
              You can also add co-owners, if you’d like, who will have the same
              access.
            </p>
            <p>My Artists</p>
            <ActEdit
              v-for="(profile, index) in profiles"
              :key="index"
              :profile="profile"
              :editing="editingArtist == profile.id"
              v-on:profileSet="saveProfile"
              v-on:edit="editArtist"
            />
            <div class="buttons">
              <button
                class="button"
                @click="addAct()"
                :disabled="
                  profiles && profiles[0] && !profiles[profiles.length - 1].id
                "
              >
                <span class="icon is-small">
                  <i class="fas fa-plus"></i>
                </span>
                <span>Add Artist</span>
              </button>
            </div>
          </div>
        </div>
        <!-- VENUE SETUP -->
        <div class="column" v-show="step == 3">
          <div class="columns">
            <div class="column">
              <h4>Add the venues that you manage.</h4>
            </div>
          </div>
          <div class="box">
            <Venue
              v-for="(profile, index) in venues"
              :key="index"
              :profile="profile"
              :editing="editingVenue == profile.id"
              :editable="true"
              v-on:profileSet="saveProfile"
              v-on:edit="editVenue"
            />
            <div class="buttons">
              <button
                class="button"
                @click="addVenue()"
                :disabled="
                  profiles && profiles[0] && !profiles[profiles.length - 1].id
                "
              >
                <span class="icon is-small">
                  <i class="fas fa-plus"></i>
                </span>
                <span>Add Venue</span>
              </button>
            </div>
          </div>
        </div>
        <!-- FESTIVAL SETUP -->
        <div class="column" v-show="step == 4">
          <div class="columns">
            <div class="column">
              <h4>Add the festivals that you manage.</h4>
            </div>
          </div>
          <div class="box">
            <Festival
              v-for="(festival, index) in festivals"
              :key="index"
              :festival="festival"
              :editing="editingFestival == festival.id"
              :editable="true"
              v-on:festivalChanged="saveFestival"
              v-on:edit="editFestival"
            />
            <div class="buttons">
              <button
                class="button"
                @click="addFestival()"
                :disabled="
                  profiles && profiles[0] && !profiles[profiles.length - 1].id
                "
              >
                <span class="icon is-small">
                  <i class="fas fa-plus"></i>
                </span>
                <span>Add Festival</span>
              </button>
            </div>
          </div>
        </div>
        <div class="column" v-show="step == 5">
          <h4>Finally, let's make sure you can get paid.</h4>
          <div class="box" v-if="status == 'payment' || (!status && user.stripeStatus != 'connected')">
            <p>In order to produce paid events, we will need to connect to your
              bank account to make deposits. By default, we pass payments to you on
              a nightly (bank business days) basis. If you are doing free events, 
              you can skip this step.
            </p>
            <p>
              Gobo uses Stripe to process payments. Stripe pays you directly -
              minus a flat 5% fee that includes credit card processing. If you
              have an existing Stripe account, you can use that. If not, create
              one now to get started!
            </p>
            <a
              v-if="!user.stripeLink"
              @click="createStripeLink"
              class="button is-primary"
              :class="{ 'is-loading': stripelinkStatus }"
              >Connect Stripe</a
            >
            <p v-if="stripelinkStatus && stripeProgress < 100">Requesting Stripe Link</p>
            <p v-if="stripelinkStatus && stripeProgress == 100">Stripe Link Created</p>
            <progress v-if="stripelinkStatus" class="progress is-primary" :value="stripeProgress" max="100">{{ stripeProgress }}%</progress>
            <p v-if="stripelinkStatus && stripeProgress == 100">Step 2. We have created a Stripe connection for your account. Click the button below to connect or create your Stripe account and link it to Gobo.</p>
            <a
              v-if="user.stripeLink"
              :href="user.stripeLink.url"
              class="button is-primary"
              >Complete Stripe Connection</a
            >
          </div>
          <div class="box" v-if="status && !user.stripeAccount">
            <progress class="progress is-small is-primary" max="100"
              >15%</progress
            >
            <p>Completing Stripe set up...</p>
          </div>
          <div class="box" v-if="user.stripeStatus == 'connected'">
            <p><i class="fas fa-check"></i> Stripe account connected!</p>
          </div>
        </div>
        <div
          class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
        ></div>
      </div>
    </div>
    <div class="box stepbar">
      <div class="columns">
        <div class="column">
          <div class="buttons is-right">
            <button class="button" v-if="step > 1" @click="step = step - 1">
              Back
            </button>
            <button class="button is-primary" @click="next()">
              Next
            </button>
          </div>
        </div>
        <div
          class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import Avatar from "@/components/avatar";
import UploadImage from "@/components/upload-image";
import ActEdit from "@/components/act-edit";
import Venue from "@/components/venue";
import Festival from "@/components/festival";
import store from "@/store";
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "profile",
  data() {
    return {
      step: 1,
      showImagePicker: true,
      showImageLoading: false,
      percentComplete: 0,
      image: "",
      stripeInProgress: false,
      editingArtist: null,
      editingVenue: null,
      editingFestival: null,
      creatingStripeAccount: false
    };
  },
  props: ["status"],
  components: { Avatar, UploadImage, ActEdit, Venue, Festival },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      profiles: "getProfiles",
      venues: "venues",
      festivals: "festivals",
      stripelinkStatus: "stripelinkStatus",
    }),
    stripeProgress: function() {
      if (this.stripelinkStatus && this.stripelinkStatus == "submitted") return 33;
      if (this.stripelinkStatus && this.stripelinkStatus == "starting") return 66;
      if (this.stripelinkStatus && this.stripelinkStatus == "complete") return 100;
      return 0;
    }
  },
  created() {
    console.log("Profile created.");
    if (this.user.avatar != "/img/icons/icon-profile.svg")
      this.showImagePicker = false;
    store.subscribe(mutation => {
      if (mutation.type === "SET_AVATAR") {
        if (this.user.avatar != "/img/icons/icon-profile.svg")
          this.showImagePicker = false;
      }
      if (mutation.type === "SET_USER") {
        if (this.status) {
          if (this.status == "payment") this.step = 5;
          if (!this.stripeInProgress) {
            this.stripeInProgress = true;
            if (this.status == "stripeSuccess")
              store.dispatch("refreshStripeUrl", "success");
            if (this.status == "stripeRefresh")
              store.dispatch("refreshStripeUrl", "refresh");
          }
        }
      }
    });
  },
  methods: {
    next() {
      if (this.step == 1) this.saveUser();
      else if (this.step == 4) router.push("/events/create");
      else this.step++;
    },
    addAct() {
      store.dispatch("addNewProfile");
      this.editingArtist = null;
    },
    addVenue() {
      store.dispatch("addNewVenueProfile");
      this.editingVenue = null;
    },
    addFestival() {
      store.dispatch("addNewFestivalProfile");
      this.editingFestival = null;
    },
    saveUser() {
      store.dispatch("saveUser", this.user);
      this.step = 2;
    },
    async saveProfile(profile) {
      console.log("saving profile", JSON.stringify(profile));
      if (profile.id) await store.dispatch("savePerformerProfile", profile);
      else {
        var newprof = await store.dispatch("createPerformerProfile", profile);
        this.$set(this.profiles, newprof.id, newprof);
      }
    },
    async saveFestival(festivalId) {
      console.log("saving festivla", JSON.stringify(festivalId));
      this.editingFestival = festivalId;
    },
    editArtist(id) {
      this.editingArtist = id;
    },
    editVenue(id) {
      this.editingVenue = id;
    },
    editFestival(id) {
      this.editingFestival = id;
    },
    async saveImage(image) {
      var storageRef = firebase.storage().ref();
      var imagestr = "avatars/" + this.user.uid + ".jpg";
      var imageRef = storageRef.child(imagestr);
      this.showImagePicker = false;
      this.showImageLoading = true;
      var upload = imageRef.put(image);

      upload.on(
        "state_changed",
        upload => {
          var progress = (this.percentComplete =
            (upload.bytesTransferred / upload.totalBytes) * 100);
          console.log("Upload is " + progress + "% done");
        },
        e => {
          console.log("Upload error: ", e);
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(url => {
            this.image = url;
            console.log("url: ", url);
            store.dispatch("setAvatar", this.image);
            this.showImageLoading = false;
          });
        }
      );
    },
    createStripeLink() {
      store.dispatch("createStripeLink", {
        userId: this.user.uid
      });
    },
    facebook() {
      throw new Error("Bugsnag test.");
      // console.log("Facebooking");
      // window.FB.getLoginStatus(function(response) {
      //   //statusChangeCallback(response);
      //   console.log("fb login check", response);
      //   if (response.status === "connected") {
      //     console.log("userId", response.authResponse.userID);
      //   } else if (response.status === "unknown") {
      //     window.FB.login(
      //       function(response) {
      //         console.log(response);
      //       },
      //       { scope: "public_profile,email" }
      //     );
      //   }
      // });
      // window.FB.login(
      //   function(response) {
      //     if (response.authResponse) {
      //       console.log("Welcome!  Fetching your information.... ");
      //       window.FB.api("/me", function(response) {
      //         console.log("Good to see you, " + response.name + ".");
      //         console.log("access token", response.authResponse.accessToken);
      //       });
      //     } else {
      //       console.log("User cancelled login or did not fully authorize.");
      //     }
      //   },
      //   {
      //     scope:
      //       "pages_manage_engagement,pages_manage_posts,pages_read_engagement,pages_read_user_content"
      //   }
      // );
    }
    //useMyName() {
    //  if (this.profile.useMyName) this.profile.name = this.user.name;
    //}
  }
};
</script>
<style>
.buttons {
  margin-top: 20px;
}
</style>
