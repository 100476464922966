var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body-wrapper" }, [
    _vm.event
      ? _c("div", { staticClass: "body-content" }, [
          _c("h1", [_vm._v(_vm._s(_vm.event.title))]),
          _c("h3", [
            _vm._v("Link to event page: "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://tix.gobo.show/events/event/" + _vm.event.id
                }
              },
              [
                _vm._v(
                  "https://tix.gobo.show/events/event/" + _vm._s(_vm.event.id)
                )
              ]
            )
          ]),
          _vm.analytics
            ? _c(
                "div",
                [
                  _c("h3", { staticClass: "chartlabel" }, [_vm._v("Traffic")]),
                  _c("LineChart", {
                    attrs: {
                      "chart-data": _vm.chartData,
                      height: _vm.chartHeight,
                      "chart-options": _vm.chartOptions
                    }
                  }),
                  _c("div", { staticClass: "chartspacer" }),
                  _c("h3", { staticClass: "chartlabel" }, [_vm._v("Sources")]),
                  _c("LineChart", {
                    attrs: {
                      "chart-data": _vm.sourcesByDay,
                      height: _vm.chartHeight,
                      "chart-options": _vm.chartOptions
                    }
                  }),
                  _c("div", { staticClass: "chartspacer" }),
                  _c("div", { staticClass: "columns" }, [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("h3", [_vm._v("Visits by Device")]),
                        _c("Doughnut", {
                          attrs: {
                            "chart-data": _vm.deviceData,
                            height: _vm.lowerChartHeight,
                            width: _vm.lowerChartHeight
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("h3", [_vm._v("Referrals by Source")]),
                        _c("Doughnut", {
                          attrs: {
                            "chart-data": _vm.sourceSummaryData,
                            height: _vm.lowerChartHeight
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("h3", [_vm._v("Social Traffic by Source")]),
                        _c("Doughnut", {
                          attrs: {
                            "chart-data": _vm.socialData,
                            height: _vm.lowerChartHeight
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "chartspacer" }),
          _c("h2", [_vm._v("Sales")]),
          _c(
            "table",
            { attrs: { id: "eventTable" } },
            [
              _vm._m(0),
              _vm._l(_vm.dateSummaries, function(summary) {
                return _c("tr", { key: summary.dateId }, [
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "is-hidden-mobile",
                          attrs: {
                            to: {
                              name: "willcall",
                              params: {
                                eventId: _vm.event.id,
                                id: summary.dateId
                              }
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(summary.start.toDate())
                                .format("MMMM Do h:mm a")
                            ) + " "
                          )
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "is-hidden-tablet",
                          attrs: {
                            to: {
                              name: "willcall",
                              params: {
                                eventId: _vm.event.id,
                                id: summary.dateId
                              }
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(summary.start.toDate())
                                .format("MMM Do h:mm a")
                            ) + " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("td", [
                    _c("strong", [_vm._v(_vm._s(summary.totalSold))]),
                    _vm._v("/" + _vm._s(summary.capacity) + " ")
                  ]),
                  _c("td", [_vm._v("$" + _vm._s(summary.salesTotal))]),
                  _c(
                    "td",
                    [
                      _c(
                        "ItemMenu",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                to: {
                                  name: "performanceSummary",
                                  params: {
                                    eventId: _vm.event.id,
                                    id: summary.dateId
                                  }
                                }
                              }
                            },
                            [_vm._v("Sales Report")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                to: {
                                  name: "willcall",
                                  params: {
                                    eventId: _vm.event.id,
                                    id: summary.dateId
                                  }
                                }
                              }
                            },
                            [_vm._v("Front of House")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              })
            ],
            2
          ),
          _vm.orders && _vm.orders.length > 0
            ? _c(
                "div",
                { staticStyle: { "margin-top": "50px" } },
                [
                  _c("OrdersList", {
                    attrs: {
                      orders: _vm.orders,
                      pageSize: 10,
                      exportName: _vm.event.title.replace(/ /g, "") + "_orders"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.waitlist && _vm.waitlist.length > 0
            ? _c("div", { staticClass: "waitlist" }, [
                _c("h2", [_vm._v("Waitlist")]),
                _c(
                  "table",
                  { staticClass: "table is-fullwidth" },
                  [
                    _vm._m(1),
                    _vm._l(_vm.waitlist, function(waitperson) {
                      return _c("tr", { key: waitperson.position }, [
                        _c("td", [_vm._v(_vm._s(waitperson.position))]),
                        _c("td", [_vm._v(_vm._s(waitperson.email))])
                      ])
                    })
                  ],
                  2
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Date")]),
      _c("th", [_vm._v("Sold")]),
      _c("th", [_vm._v("Gross")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("#")]),
      _c("th", [_vm._v("Email Address")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }