<template>
  <div class="profile" v-if="festival" :class="{ selectable: selectable }">
    <div class="columns" v-if="!editing" :class="{ selectable: selectable }" @click="profileClicked">
      <div class="column is-narrow" v-if="selectable">
        <div class="field selectitem">
          <input
            class="is-checkradio"
            :id="festival.id"
            type="radio"
            value="festival.id"
            name="selectedFestival"
            :checked="selected"
          />
          <label class="radio" for="taxable">&nbsp;</label>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="avatar-generic">
          <img v-if="festival.image" :src="festival.image" alt="" />
        </div>
      </div>
      <div class="column">
        <p class="artistname">
          <strong>{{ festival.name }}</strong
          >&nbsp;&nbsp;|&nbsp;&nbsp;<span class="city">{{
            festival.type
          }}</span>
        </p>
      </div>
      <div class="column is-narrow">
        <p class="managedby">Managed by {{ owners }}</p>
      </div>
      <div class="column is-narrow" v-if="editable">
        <div
          class="dropdown"
          :class="{ 'is-active': showMenu }"
          @click="showMenu = true"
        >
          <div class="dropdown-trigger">
            <button
              class="button dot-menu"
              aria-haspopup="true"
              aria-controls="dropdown-menu3"
            >
              <span class="icon is-small">
                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu3" role="menu">
            <div class="dropdown-content" @blur="showMenu = false">
              <a class="dropdown-item" @click="edit()">Edit Festival</a>
              <a v-if="false" class="dropdown-item" @click="deleteDate()"
                >Delete Festival</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- EDIT MODE -->
    <div v-if="editing">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Festival Name</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Festival name"
                v-model.lazy="festival.name"
                @change="save"
                :readonly="festival.useMyName"
              />
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="field">
            <label class="label">&nbsp;</label>
            <div class="select is-full-width">
              <select v-model="festival.type" @change="save">
                <option value="" disabled selected>Select category</option>
                <option value="music">Music</option>
                <option value="theater">Theater</option>
                <option value="dance">Dance</option>
                <option value="improv">Improv</option>
                <option value="storytelling">Storytelling</option>
                <option value="standup">Standup Comedy</option>
                <option value="conferences">Conferences/Exhibitions</option>
                <option value="opera">Opera</option>
                <option value="music theater">Music Theater</option>
                <option value="art">Visual Art</option>
                <option value="gatherings">Social Gatherings</option>
                <option value="sports">Sports</option>
                <option value="children">Children's Programs</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Festival Image</label>
            <div v-if="false">
              <Avatar />
            </div>
            <div class="imagefield" v-if="festival.image">
              <img
                :src="festival.image"
                v-if="festival.image"
                alt="User Image"
              />
              <button class="button" @click="festival.image = null">
                Change Image
              </button>
            </div>
            <UploadImage
              v-if="!festival.image"
              :outputWidth="400"
              :outputHeight="400"
              v-on:imageCropped="saveImage"
            />
            <div class="buttons"></div>
            <div v-if="showImageLoading">
              <progress
                class="progress is-primary"
                :value="percentComplete"
                max="100"
                >{{ percentComplete }}</progress
              >
              <p>Uploading image...</p>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Tax Status</label>
        <div class="field">
          <input
            type="radio"
            id="taxable"
            name="tax"
            v-model="festival.tax"
            value="remit"
            class="is-checkradio"
          />
          <label class="radio" for="taxable">
            Collect and Remit Sales Tax (default)
          </label>
          <input
            type="radio"
            id="taxexempt"
            name="tax"
            v-model="festival.tax"
            value="exempt"
            class="is-checkradio"
          />
          <label class="radio" for="taxexempt">
            Tax Exempt (we may ask for documentation)
          </label>
        </div>
      </div>
      <p v-if="festival.id">
        (Optional) Add co-owners now or you can always add them later. If they
        don't currently have a Gobo account, they will need to create one.
      </p>
      <InviteUserStarter
        v-if="festival && festival.id"
        :inviterName="user.name"
        :inviterId="user.uid"
        :profileName="festival.name"
        :profileType="Festival"
        :festivalId="festival.id"
        :newProfile="false"
      />
      <!-- <div class="field">
              <div class="control">
                <label class="checkbox">
                  <div>
                    <input type="checkbox" @change="useMyName()" />
                  </div>
                  I use my own name as listed above.
                </label>
              </div>
            </div> -->
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import store from "@/store";
import UploadImage from "@/components/upload-image";
import InviteUserStarter from "@/components/invite-user-starter";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showImagePicker: true,
      showImageLoading: false,
      percentComplete: 0,
      showMenu: false,
      email: null,
      emailValid: false
    };
  },
  props: {
    festival: Object,
    editing: Boolean,
    editable: Boolean,
    selectable: Boolean,
    selected: Boolean
  },
  components: { UploadImage, InviteUserStarter },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    owners() {
      if (this.festival.owners) {
        let names = "";
        let x = 0;
        for (const owner in this.festival.owners) {
          if (x == 1) names += ", ";
          console.log("owner: ", owner);
          names += this.festival.owners[owner].name;
          x = 1;
        }
        return names;
      } else return "Ted";
    }
  },
  methods: {
    async save() {
      if (!this.festival.id) {
        this.festival.owner = this.user.uid;
        this.festival.owners = {};
        this.festival.owners[this.user.uid] = { name: this.user.name };
        console.log("Logging this baby", this.festival);
        const newfest = await store.dispatch(
          "createFestivalProfile",
          this.festival
        );
        this.$emit("festivalChanged", newfest.id);
      } else {
        store.dispatch("saveFestival", this.festival);
        this.$emit("festivalChanged", this.festival.id);
      }
    },
    edit() {
      this.$emit("edit", this.festival.id);
    },
    profileClicked() {
      if (this.selectable && !this.selected) this.$emit("selected", this.festival.id);
    },
    inviteFestivalManager() {
      store.dispatch("newInvite", {
        email: this.email,
        profileName: this.festival.name,
        userName: this.user.name,
        festivalId: this.festival.id,
        owner: this.user.uid
      });
    },
    async saveImage(image) {
      var storageRef = firebase.storage().ref();
      var imagestr = "festivals/" + this.festival.id + ".jpg";
      var imageRef = storageRef.child(imagestr);
      this.showImagePicker = false;
      this.showImageLoading = true;
      var upload = imageRef.put(image);

      // Update percent complete
      upload.on(
        "state_changed",
        upload => {
          var progress = (this.percentComplete =
            (upload.bytesTransferred / upload.totalBytes) * 100);
          console.log("Upload is " + progress + "% done");
        },
        e => {
          console.log("Upload error: ", e);
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(url => {
            this.image = url;
            console.log("url: ", url);
            this.showImageLoading = false;

            // Update profile
            this.festival.image = url;
            store.dispatch("saveFestivalProfileImage", this.festival);
          });
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.imagefield {
  background-color: $gray6;
  border-radius: $radius;
  img {
    max-width: 230px;
    margin: 20px;
  }
  .button {
    margin-top: 18%;
  }
}
.managedby {
  font-size: 12px;
  margin: 12.5px;
}
p.artistname {
  margin: 12.5px;
}
.city {
  font-size: 12px;
}
.selectable:hover {
  background-color: $gray6;
  cursor: pointer;
}
.selectitem .is-checkradio[type="radio"] + label {
  line-height: 3.4rem;
  padding-left: 1rem;
}
.profile.selectable {
  padding-bottom: 0;
}
</style>
