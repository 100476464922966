import { render, staticRenderFns } from "./EventPublished.vue?vue&type=template&id=619d132e"
import script from "./EventPublished.vue?vue&type=script&lang=js"
export * from "./EventPublished.vue?vue&type=script&lang=js"
import style0 from "./EventPublished.vue?vue&type=style&index=0&id=619d132e&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/gobo-app/gobo-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('619d132e')) {
      api.createRecord('619d132e', component.options)
    } else {
      api.reload('619d132e', component.options)
    }
    module.hot.accept("./EventPublished.vue?vue&type=template&id=619d132e", function () {
      api.rerender('619d132e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/EventPublished.vue"
export default component.exports