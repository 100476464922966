<template>
  <div class="bodywrapper">
    <div class="columns">
      <div class="column is-one-fifth"></div>
      <div class="column">
        <h1>Event Published!</h1>
        <p>
          Event successfully published. You can find it at:
          <a :href="'https://tix.gobo.show/events/event/' + event.id">https://tix.gobo.show/events/event/{{ event.id }}</a>
        </p>
      </div>
      <div class="column is-one-fifth"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      event: "event"
    })
  }
};
</script>

<style>
.bodywrapper {
  margin: 100px 0;
}
</style>
