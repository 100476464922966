var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bodywrapper" }, [
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-one-fifth" }),
      _c("div", { staticClass: "column" }, [
        _c("h1", [_vm._v("Event Published!")]),
        _c("p", [
          _vm._v(" Event successfully published. You can find it at: "),
          _c(
            "a",
            {
              attrs: {
                href: "https://tix.gobo.show/events/event/" + _vm.event.id
              }
            },
            [
              _vm._v(
                "https://tix.gobo.show/events/event/" + _vm._s(_vm.event.id)
              )
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "column is-one-fifth" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }