<template>
  <div class="columns">
    <div class="column">
      <EmailField
        :email.sync="email"
        :valid.sync="emailValid"
        :placeholder="placeholder"
      />
    </div>
    <div class="column is-narrow">
      <button class="button" :disabled="!emailValid" @click="invite">
        Invite
      </button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import EmailField from "@/components/formControls/email-field";

export default {
  data() {
    return {
      email: null,
      emailValid: false
    };
  },
  props: {
    inviterName: String,
    inviterId: String,
    profileName: String,
    actId: String,
    venueId: String,
    festivalId: String,
    newProfile: Boolean,
    placeholderText: String
  },
  components: { EmailField },
  computed: {
    placeholder() {
      if (this.placeholderText) return this.placeholderText;
      else return "Co-Owner Email Address";
    }
  },
  methods: {
    async save() {
      this.$emit("profileSet", this.profile);
    },
    edit() {
      this.$emit("edit", this.profile.id);
    },
    invite() {
      var invite = {
        email: this.email,
        profileName: this.profileName,
        userName: this.inviterName,
        owner: this.inviterId,
        newProfile: this.newProfile,
        status: this.newProfile ? "new" : "existing"
      };
      if (this.festivalId) {
        invite.festivalId = this.festivalId;
        invite.role = "festivalManager";
      }
      if (this.venueId) {
        invite.venueId = this.venueId;
        invite.role = "venueManager";
      }
      if (this.actId) {
        invite.actId = this.actId;
        invite.role = "artist";
      }
      store.dispatch("newInvite", invite);
    }
  }
};
</script>
