var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label", attrs: { for: "" } }, [
        _vm._v("Send a test email")
      ]),
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column" },
          [
            _c("EmailField", {
              attrs: {
                disabled: _vm.sending,
                email: _vm.email,
                valid: _vm.valid
              },
              on: {
                "update:email": function($event) {
                  _vm.email = $event
                },
                "update:valid": function($event) {
                  _vm.valid = $event
                }
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "column is-narrow" }, [
          _c(
            "button",
            {
              staticClass: "button",
              class: {
                "is-loading": _vm.sending
              },
              attrs: { disabled: _vm.sending || !_vm.valid },
              on: { click: _vm.send }
            },
            [_vm._v(" Send Test Email ")]
          )
        ])
      ])
    ]),
    _vm.sending ? _c("p", [_vm._v(" Sending email... ")]) : _vm._e(),
    _vm.userRequest &&
    _vm.userRequest.emailType == _vm.type &&
    _vm.userRequest.status == "complete"
      ? _c("p", [_vm._v(" Test email sent! ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }