var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown is-right",
      class: { "is-active": _vm.showMenu },
      on: {
        click: function($event) {
          _vm.showMenu = true
        }
      }
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { id: "dropdown-menu", role: "menu" }
        },
        [_c("div", { staticClass: "dropdown-content" }, [_vm._t("default")], 2)]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button event-menu",
          attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu" }
        },
        [
          _c("span", { staticClass: "icon is-small" }, [
            _c("i", {
              staticClass: "fas fa-ellipsis-v",
              attrs: { "aria-hidden": "true" }
            })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }