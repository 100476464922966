<template>
  <div>
    <div class="control has-icons-right">
      <input
        type="text"
        class="input is-primary timeinput"
        :class="{'is-danger': !isValid}"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="timeString"
        @keyup="change"
        v-facade="'1?#?:## AA'"
      />
      <span class="icon is-right"><i class="far fa-clock"></i></span>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    valid: Boolean,
    time: String,
    disabled: Boolean,
    placeholder: { default: "0:00 PM", type: String }
  },
  emits: ["update:valid", "update:time", "change"],
  data() {
    return {
      isValid: true,
      timeString: '',
      mask: [/[1]?/, /\d/, ':', /\d/, /\d/, ' ', /[Pp]/, /[Mm]/]
    };
  },
  mounted: function() {
    if (/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.time)) {
      this.timeString = moment(this.time, ["HH:mm"]).format("h:mm A");
    } else console.log("Bad time bears", this.time);
    this.validate();
  },
  watch: {
    time: function() {
      // TODO: Fix duped code with mounted
      if (/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.time)) {
      this.timeString = moment(this.time, ["HH:mm"]).format("h:mm A");
    } else console.log("Bad time bears", this.time);
    }
  },
  methods: {
    change() {
      this.validate();
      if (this.isValid) { 
        this.$emit("update:time", moment(this.timeString, ["h:mm A"]).format("HH:mm"));
      }
      else this.$emit("update:time", this.timeString);
      this.$emit("change");
    },
    validate() {
      this.isValid = true;
      if (this.timeString) this.isValid = /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/.test(this.timeString);
      this.$emit("update:valid", this.isValid);
    }
  }
};
</script>
<style>
  .timeinput {
    width: 125px;
  }
</style>
