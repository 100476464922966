<template>
  <div
    class="dropdown is-right"
    :class="{ 'is-active': showMenu }"
    @click="showMenu = true"
  >
    <div class="dropdown-trigger">
      <button
        class="button event-menu"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      >
        <span class="icon is-small">
          <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      showMenu: false
    };
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

button.event-menu {
  width: 50px;
  min-width: 50px;
  height: 50px;
  &:hover {
    border-color: $active-green;
  }
}
</style>
