<template>
  <tr>
    <td class="is-hidden-mobile">
      <span class="icon">
        <span class="icon" v-if="!details" @click="details = true">
          <i class="fas fa-plus"></i>
        </span>
        <span class="icon" v-if="details" @click="details = false">
          <i class="fas fa-minus"></i>
        </span>
      </span>
    </td>
    <td v-if="!details" :class="{ deleting: deleting }">{{ audience.firstName }}</td>
    <td v-if="!details" :class="{ deleting: deleting }">{{ audience.lastName }}</td>
    <td colspan="3" v-if="moving">
      <DateSlider :dates="eventDates" :visible="true" @setDate="setDate" />
    </td>
    <td colspan="5" v-if="details">
      <table>
        <tr>
          <td><strong>First Name:</strong></td>
          <td>{{ audience.firstName }}</td>
          <td><strong>Last Name:</strong></td>
          <td>{{ audience.lastName }}</td>
        </tr>
        <tr>
          <td><strong>Email:</strong></td>
          <td>{{ audience.email }}</td>
          <td><strong>Order ID:</strong></td>
          <td>{{ audience.orderId }}</td>
        </tr>
        <tr>
          <td><strong>Tier:</strong></td>
          <td>{{ audience.tierName }}</td>
        </tr>
        <tr>
          <td><strong>Price:</strong></td>
          <td>${{ audience.price }}</td>
          <td><strong>Quantity:</strong></td>
          <td>{{ audience.quantity }}</td>
        </tr>
        <tr>
          <td><strong>Discount Code/Note:</strong></td>
          <td colspan="3">{{ audience.discountCode }}</td>
        </tr>
      </table>
    </td>
    <td v-if="!moving && !details" class="is-hidden-mobile" :class="{ deleting: deleting }">
      {{ audience.email }}
    </td>
    <td v-if="!moving && !details" :class="{ deleting: deleting }">
      <div class="select">
        <select v-model.number="checkqty" class="checkin">
          <option value="1">1</option>
          <option v-if="remaining >= 2" value="2">2</option>
          <option v-if="remaining >= 3" value="3">3</option>
          <option v-if="remaining >= 4" value="4">4</option>
          <option v-if="remaining >= 5" value="5">5</option>
          <option v-if="remaining >= 6" value="6">6</option>
          <option v-if="remaining >= 7" value="7">7</option>
          <option v-if="remaining >= 8" value="8">8</option>
        </select>
      </div>
    </td>
    <td
      v-if="!moving && !deleting && !details"
      :class="{ deleting: deleting }"
      class="is-hidden-mobile"
    >
      {{ audience.tierName }}
    </td>
    <td v-if="deleting" class="canceling">
      Cancelled
    </td>
    <td v-if="deleting">
      <button class="button" @click="undoCancel()" v-if="!deleted">Undo</button>
    </td>
    <td v-if="moving">
      <button
        class="button is-primary"
        @click="move()"
        v-if="!deleted"
        :disabled="selectedDate == null"
      >
        Move
      </button>
      <button class="button" @click="moving = false" v-if="!deleted">
        Cancel
      </button>
    </td>
    <td v-if="!moving && !deleting">
      <div class="select">
        <select v-model.number="checkqty" class="checkin">
          <option value="1">1</option>
          <option v-if="remaining >= 2" value="2">2</option>
          <option v-if="remaining >= 3" value="3">3</option>
          <option v-if="remaining >= 4" value="4">4</option>
          <option v-if="remaining >= 5" value="5">5</option>
          <option v-if="remaining >= 6" value="6">6</option>
          <option v-if="remaining >= 7" value="7">7</option>
          <option v-if="remaining >= 8" value="8">8</option>
        </select>
      </div>
      <button class="button checkin is-hidden-mobile" @click="checkin">Check In</button>
      <button class="button checkin checkinmob is-mobile" @click="checkin">
        <span class="icon is-large">
            <i class="fas fa-check"></i> </span>
        </button>
      <ItemMenu>
        <a class="dropdown-item" @click="moving = true">Move to another date</a>
        <a class="dropdown-item" @click="cancel()">Cancel</a>
      </ItemMenu>
    </td>
  </tr>
</template>

<script>
import firebase from "firebase/app";
import store from "@/store";
import { mapGetters } from "vuex";
import ItemMenu from "@/components/itemMenu";
import DateSlider from "@/components/date-slider";

export default {
  props: {
    audience: Object,
    eventId: String,
    dateId: String
  },
  components: {
    ItemMenu,
    DateSlider
  },
  data() {
    return {
      checkqty: 0,
      moving: false,
      deleting: false,
      deleted: false,
      details: false,
      timer: null,
      selectedDate: null
    };
  },
  computed: {
    ...mapGetters({
      eventDates: "eventDates"
    }),
    remaining() {
      return this.audience.quantity - this.audience.checkedin;
    }
  },
  created() {
    this.checkqty = this.remaining;
  },
  methods: {
    async checkin() {
      //checkin
      await store.dispatch("checkin", {
        eventId: this.eventId,
        dateId: this.dateId,
        audience: this.audience,
        checkqty: this.checkqty
      });
      if (this.remaining > 0) this.checkqty = this.remaining;
    },
    cancel() {
      this.deleting = true;
      this.timer = setTimeout(() => {
        if (this.deleting) {
          var db = firebase.firestore();
          db.collection("events")
            .doc(this.eventId)
            .collection("userData")
            .doc("audienceReport")
            .collection("dates")
            .doc(this.dateId)
            .collection("audience")
            .doc(this.audience.id)
            .update({ status: "cancelled" })
            .catch(function(error) {
              console.error("Error cancelling reservation: ", error);
            });
          console.log("beleted!");
          store.dispatch("cancelWillcalll", this.audience);
          this.deleted = true;
        }
      }, 5000);
    },
    async move() {
      var db = firebase.firestore();
      var resRef = await db
        .collection("events")
        .doc(this.eventId)
        .collection("userData")
        .doc("audienceReport")
        .collection("dates")
        .doc(this.selectedDate.id)
        .collection("audience")
        .add(this.audience)
        .catch(function(error) {
          console.error("Error moving patron: ", error);
        });
      resRef.update({ id: resRef.id });
      db.collection("events")
        .doc(this.eventId)
        .collection("userData")
        .doc("audienceReport")
        .collection("dates")
        .doc(this.dateId)
        .collection("audience")
        .doc(this.audience.id)
        .update({ status: "moved", movedTo: this.selectedDate.id })
        .catch(function(error) {
          console.error("Error marking patron moved: ", error);
        });
      console.log("Moved!");
    },
    undoCancel() {
      this.deleting = false;
      window.clearTimeout(this.timer);
    },
    setDate(date) {
      this.selectedDate = date;
    }
  }
};
</script>

<style>
button.event-menu {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-left: 10px;
}
.deleting {
  opacity: 0.1;
  transition: opacity 5s;
}
.canceling {
  opacity: 0.4;
  transition: opacity 5s;
}
.checkinmob {
  min-width: 0;
}
</style>
