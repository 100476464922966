var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "control has-icons-right" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.timeString,
            expression: "timeString"
          },
          {
            name: "facade",
            rawName: "v-facade",
            value: "1?#?:## AA",
            expression: "'1?#?:## AA'"
          }
        ],
        staticClass: "input is-primary timeinput",
        class: { "is-danger": !_vm.isValid },
        attrs: {
          type: "text",
          placeholder: _vm.placeholder,
          disabled: _vm.disabled
        },
        domProps: { value: _vm.timeString },
        on: {
          keyup: _vm.change,
          input: function($event) {
            if ($event.target.composing) return
            _vm.timeString = $event.target.value
          }
        }
      }),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-right" }, [
      _c("i", { staticClass: "far fa-clock" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }