var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    [
      _c("tr", [
        _c("td", [
          !_vm.expanded
            ? _c(
                "span",
                {
                  staticClass: "icon",
                  on: {
                    click: function($event) {
                      _vm.expanded = true
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-plus" })]
              )
            : _vm._e(),
          _vm.expanded
            ? _c(
                "span",
                {
                  staticClass: "icon",
                  on: {
                    click: function($event) {
                      _vm.expanded = false
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-minus" })]
              )
            : _vm._e()
        ]),
        _c("td", [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://tix.gobo.show/pass/" +
                  _vm.pass.id +
                  "/" +
                  _vm.pass.email,
                target: "_blank"
              }
            },
            [
              _vm._v(
                _vm._s(_vm.pass.firstName) + " " + _vm._s(_vm.pass.lastName)
              )
            ]
          )
        ]),
        _c("td", [_vm._v(_vm._s(_vm.pass.email))]),
        _c("td", [_vm._v(_vm._s(_vm.pass.status))]),
        _c("td", [
          _vm._v(
            " " +
              _vm._s(_vm.pass.usedCount ? _vm.pass.usedCount : 0) +
              " / " +
              _vm._s(
                _vm.pass.passType == "unlimited" ? "unlimited" : _vm.pass.limit
              ) +
              " "
          )
        ]),
        _c("td", [_vm._v("$" + _vm._s(_vm.price))])
      ]),
      _vm._l(_vm.pass.uses, function(use, index) {
        return _c("tr", { key: index }, [
          _vm.expanded ? _c("td") : _vm._e(),
          _vm.expanded
            ? _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(
                  " " +
                    _vm._s(use.date) +
                    " " +
                    _vm._s(use.time) +
                    " - " +
                    _vm._s(use.eventTitle) +
                    " - " +
                    _vm._s(use.description) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.expanded ? _c("td", [_vm._v(_vm._s(use.passUse))]) : _vm._e(),
          _vm.expanded ? _c("td") : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }