<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Event Date</label>
          <div class="control" :class="{ disabled: disable }">
            <duet-date-picker
              :identifier="datepickerId"
              @duetChange="updateStartDate"
              :localization.prop="localisation_us"
              :value="date.startDate"
              :disabled="disable"
              first-day-of-week="0"
            >
            </duet-date-picker>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="field">
          <label class="label">Start Time</label>
          <TimeField  :time.sync="date.startTime" :valid.sync="startTimeValid" @change="startChanged" />
        </div>
      </div>
      <div class="column is-narrow">
        <div class="field">
          <label class="label">End Time</label>
          <TimeField  :time.sync="date.endTime" :valid.sync="endTimeValid" @change="changed" />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Tags (optional)</label>
          <input
            class="input"
            type="text"
            placeholder="Add tag"
            v-on:keyup.enter="addTag"
            v-model="newTag"
          />
        </div>
      </div>
      <div class="column is-narrow">
        <span class="icon is-medium delete" @click="deleted" v-if="!disable"
          ><i class="fas fa-lg fa-trash"></i
        ></span>
        <span class="icon is-medium info" @click="deleted" v-if="false"
          ><i class="fas fa-lg fa-info-circle"></i
        ></span>
      </div>
    </div>
    <div class="columns">
      <div class="column" v-if="date.tags">
        <div class="tags">
          <span class="tag is-success" v-for="tag in date.tags" :key="tag">
            {{ tag }}
            <button class="delete is-small" @click="removeTag(tag)"></button>
          </span>
        </div>
      </div>
    </div>
    <p v-if="!startTimeValid" class="help is-danger">The start time is invalid. Use 12 hour format (eg 12:30 PM).</p>
    <p v-if="!endTimeValid" class="help is-danger">The end time is invalid. Use 12 hour format (eg 12:30 PM).</p>
    <hr />

  </div>
</template>

<script>
// Moment.js
import moment from "moment";
import TimeField from "@/components/formControls/time-field";

const localisation_us = {
  buttonLabel: "Choose date",
  placeholder: "MM/DD/YYYY",
  selectedDateMessage: "Selected date is",
  prevMonthLabel: "Previous month",
  nextMonthLabel: "Next month",
  monthSelectLabel: "Month",
  yearSelectLabel: "Year",
  closeLabel: "Close window",
  keyboardInstruction: "You can use arrow keys to navigate dates",
  calendarHeading: "Choose a date",
  dayNames: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]
};

export default {
  data() {
    return {
      datepickerId: "eventDate",
      times: [],
      newTag: null,
      startTimeValid: true,
      endTimeValid: true,
    };
  },
  components: { TimeField },
  created() {
    console.log("created dateline", this.date);
    if (this.date.id) this.datepickerId = "eventDate-" + this.date.id;
    else { 
      this.datepickerId = "newDate-" + (Math.random() + 1).toString(36).substring(7);
      this.date.startDate = "";
    }
  },
  mounted() {
    var hours, minutes, ampm;
    for (var i = 0; i <= 1420; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      this.times.push(hours + ":" + minutes + " " + ampm);
    }
  },
  props: {
    date: Object,
    editing: Boolean,
    defaultDuration: Number,
    localisation_us: localisation_us
  },
  methods: {
    updateStartDate(e) {
      console.log("Yeaaaaah", e);
      this.date.startDate = e.detail.value;
      this.changed();
    },
    startChanged() {
      if (this.defaultDuration && this.startTimeValid && !this.date.endTime) {
        console.log("setting end time");
        const start = moment(this.date.startTime, "HH:mm");
        this.date.endTime = start.add(this.defaultDuration, 'minutes').format("HH:mm");
      } else console.log("Not setting it", this.defaultDuration, this.date.endTime);
      this.changed();
    },
    changed() {
      console.log("changed: " + JSON.stringify(this.date));
      this.$emit("dateSet", this.date);
    },
    showTime() {
      console.log("Timez", this.startTime, this.endTime);
    },
    timeFromStart(time) {
      let start = moment(this.date.startTime, "HH:mm a");
      let end = moment(time, "HH:mm a");
      if (end < start) end.add(1, "days");
      return moment.duration(moment(end, "HH:mm a").diff(start)).asHours();
    },
    deleted() {
      console.log("deeting");
      this.$emit("deleted", this.date);
    },
    addTag() {
      console.log("Adding tag", this.newTag);
      if (!this.date.tags) this.date.tags = [this.newTag];
      else this.date.tags.push(this.newTag);
      this.newTag = null;
      this.$emit("dateSet", this.date);
    },
    removeTag(tag) {
      console.log("removing tag", tag);
      this.date.tags.splice(this.date.tags.indexOf(tag), 1);
      this.$emit("dateSet", this.date);
    }
  },
  computed: {
    // the end times are upon us
    endTimes() {
      let etimes = this.times;
      if (this.date.startTime) {
        let before = etimes.slice();
        let after = etimes.slice();
        before = before.filter(
          t => moment(t, "HH:mm a") > moment(this.date.startTime, "HH:mm a")
        );
        after = after.filter(
          t => moment(t, "HH:mm a") < moment(this.date.startTime, "HH:mm a")
        );
        let combined = [...before, ...after];
        return combined;
      } else return etimes;
    },
    disable() {
      if (
        moment(this.date.startDate) < Date.now() &&
        (this.date.status == "inprogress" ||
          this.date.status == "limited" ||
          this.date.status == "soldout")
      )
        return true;
      return false;
    }
  }
};
</script>

<style scoped>
.delete,
.info {
  margin-top: 40px;
}
.info {
  max-height: 24px;
  max-width: 24px;
  min-height: 24px;
  min-width: 24px;
  color: rgba(10, 10, 10, 0.2);
}
div.disabled duet-date-picker {
  opacity: 0.5;
}
.tag:not(body) .delete {
  margin-top: 0px;
}
.spacer {
  width: 24px;
}
hr {
  margin: 5px 0 10px;
}
</style>
