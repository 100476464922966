var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "cal-body" }, [
      _c("div", { staticClass: "month" }, [
        _vm._v(_vm._s(_vm.eventStartMonth))
      ]),
      _c("div", { staticClass: "day" }, [_vm._v(_vm._s(_vm.eventStartDay))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }