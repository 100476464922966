<template>
  <div :ref="'croppiecont' + id">
    <div
      v-if="!cropping"
      class="dropzone"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="croppie"
    >
      <div class="columns is-vcentered">
        <div class="column is-2 placeholder-img">
          <img src="/img/icon-image.png" alt="" />
        </div>
        <div class="column">
          <p>
            <strong>Drag + drop</strong> or click <strong>upload</strong> to add
            an image.
          </p>
          <p class="subtext">
            Use a high quality image: minimum 800 x 360 px, JPG or PNG, no
            larger than 10MB. Will save to PNG.
          </p>
        </div>
        <div class="column is-one-quarter">
          <input
            type="file"
            name="file"
            id="file"
            class="inputfile"
            accept="image/x-png,image/jpeg"
            @change="croppie"
          />
          <label for="file" class="button upload">
            Upload Image
          </label>
        </div>
      </div>
    </div>
    <div class="croppiecont" v-if="cropping">
      <vue-croppie
        :ref="'croppieRef' + id"
        :enableOrientation="true"
        :enableResize="false"
        :enforceBoundary="false"
        :boundary="{ width: croppieWidth, height: croppieHeight }"
        :viewport="{ width: cropWidth, height: cropHeight, type: 'square' }"
      >
      </vue-croppie>
      <div class="controlbar">
        <div class="imagecancel">
          <button class="button is-flex-mobile is-light" @click="cancel">
            Cancel
          </button>
        </div>
        <div class="imagesave">
          <button
            class="button is-light is-flex-mobile"
            @click="crop"
          >
            Save Image
          </button>
        </div>
      </div>
      <div class="bumper"></div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  background-color: $active-green;
}
.dropzone {
  padding: 15px;
  background-color: $gray6;
}
.croppiecont {
  padding: 0px;
  background-color: $gray6;
}
.dropzone,
.croppiecont {
  border-radius: $radius;
}
.dropzone p.subtext {
  font-size: 12px;
  line-height: 18px;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.placeholder-img {
  text-align: center;
}
.drop-active {
  background-color: $gray5;
}
.upload {
  font-family: $family-primary;
  display: flex;
}
.croppie-container .cr-boundary {
  background-color: $gray6;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  height: 310px;
}
.controlbar {
  padding: 0 15px;
  .imagecancel {
    float: left;
  }
  .imagesave {
    float: right;
  }
}
.cr-slider-wrap {
  margin-bottom: -50px !important;
  padding-bottom: 10px;
}
.cr-slider {
  color: $gray4;
}
.bumper {
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .is-flex-mobile {
    flex: 1;
  }
  .cr-slider-wrap {
    margin-bottom: 0px !important;
  }
  .controlbar {
    display: flex;
    .imagecancel, .imagesave {
      float: none;
      display: flex;
      flex: 1;
    }
  }
}
</style>

<script>
export default {
  name: "UploadImage",
  props: {
    width: Number,
    outputWidth: Number,
    outputHeight: Number,
    id: String
  },
  data() {
    return {
      cropping: false
    };
  },
  computed: {
    croppieWidth() {
      return this.$refs['croppiecont' + this.id].clientWidth * 1.2;
    },
    croppieHeight() {
      return this.$refs['croppiecont' + this.id].clientWidth * 0.42 * 1.2;
    },
    // TODO: These calcs will only work for a box that is longer horizontal.
    // Refactor to return a hight and width from one function regardless of ratio
    cropWidth() {
      console.log(
        "croppiewidth",
        this.$refs['croppiecont' + this.id].clientWidth,
        this.outputWidth
      );
      if (this.outputWidth > this.$refs['croppiecont' + this.id].clientWidth)
        return this.$refs['croppiecont' + this.id].clientWidth * 0.75;
      else return this.outputWidth * .75;
      //return (this.$refs['croppiecont' + this.id].clientWidth - 30) * 0.6;
    },
    cropHeight() {
      if (this.outputWidth > this.$refs['croppiecont' + this.id].clientWidth) {
        var width = this.$refs['croppiecont' + this.id].clientWidth * 0.75;
        var aspect = this.outputHeight / this.outputWidth;
        return width * aspect;
      } else return this.outputHeight * .75;
      //return (this.$refs['croppiecont' + this.id].clientWidth - 30) * 0.6;
    }
  },
  methods: {
    croppie(e) {
      console.log("crop!", e, this.$refs['croppieRef' + this.id]);
      e.preventDefault();
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      e.currentTarget.classList.remove("drop-active");
      this.cropping = true;

      var reader = new FileReader();
      reader.onload = e => {
        this.$refs['croppieRef' + this.id].bind({
          url: e.target.result
        });
      };

      reader.readAsDataURL(files[0]);
    },
    async crop() {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      let options = {
        type: "blob",
        size: { width: this.outputWidth, height: this.outputHeight },
        format: "png"
      };
      var output = await this.$refs['croppieRef' + this.id].result(options);
      this.$emit("imageCropped", output);

      this.cropping = false;
    },
    cancel() {
      this.cropping = false;
    },
    dragover(event) {
      event.preventDefault();
      event.currentTarget.classList.add("drop-active");
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    },
    drop(event) {
      event.preventDefault();
      var reader = new FileReader();
      reader.onload = e => {
        this.$refs['croppieRef' + this.id].bind({
          url: e.target.result
        });
      };
      //this.$refs['croppieRef' + this.id] = event.dataTransfer.files;
      //this.$refs.file.files = event.dataTransfer.files;
      //this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    }
  }
};
</script>
