var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body-wrapper" }, [
    _c("div", { staticClass: "body-content gray" }, [
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column is-one-quarter" }, [
          !_vm.user || !_vm.user.eventPublished
            ? _c("h1", [
                _vm._v(" Let's "),
                _c("br"),
                _vm._v(" get "),
                _c("br"),
                _vm._v(" started ")
              ])
            : _vm._e(),
          _vm.user && _vm.user.eventPublished
            ? _c("h1", [_vm._v(" Your"), _c("br"), _vm._v(" Profile ")])
            : _vm._e(),
          _c("div", { staticClass: "box is-hidden-mobile" }, [
            _c("div", { staticClass: "block" }, [
              _c("aside", { staticClass: "menu" }, [
                _c("p", { staticClass: "menu-label" }, [
                  _vm._v(" Account Set Up ")
                ]),
                _c("ul", { staticClass: "menu-list" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: { "is-active": _vm.step == 1 },
                        on: {
                          click: function($event) {
                            _vm.step = 1
                          }
                        }
                      },
                      [_vm._v("User Profile")]
                    )
                  ]),
                  _vm.user.performingArtist
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            class: { "is-active": _vm.step == 2 },
                            on: {
                              click: function($event) {
                                _vm.step = 2
                              }
                            }
                          },
                          [_vm._v("My Artists")]
                        )
                      ])
                    : _vm._e(),
                  _vm.user.venueManager
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            class: { "is-active": _vm.step == 3 },
                            on: {
                              click: function($event) {
                                _vm.step = 3
                              }
                            }
                          },
                          [_vm._v("My Venues")]
                        )
                      ])
                    : _vm._e(),
                  _vm.user.festivalManager
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            class: { "is-active": _vm.step == 4 },
                            on: {
                              click: function($event) {
                                _vm.step = 4
                              }
                            }
                          },
                          [_vm._v("My Festivals")]
                        )
                      ])
                    : _vm._e(),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: { "is-active": _vm.step == 5 },
                        on: {
                          click: function($event) {
                            _vm.step = 5
                          }
                        }
                      },
                      [_vm._v("Payment Information")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step == 1,
                expression: "step == 1"
              }
            ],
            staticClass: "column"
          },
          [
            _c("h4", [
              _vm._v(
                " Finalize your account, review your user name and add an image. "
              )
            ]),
            _c("div", { staticClass: "box" }, [
              _c("p", [
                _vm._v(
                  " What name should we use to refer to you? (not your artist name, venue name, or festival name, we'll take care of that later) "
                )
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("User Name")
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.name,
                            expression: "user.name"
                          }
                        ],
                        staticClass: "input is-primary",
                        attrs: { type: "text", placeholder: "User Name" },
                        domProps: { value: _vm.user.name },
                        on: {
                          change: _vm.saveUser,
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.user, "name", $event.target.value)
                          }
                        }
                      })
                    ]),
                    false
                      ? _c("p", { staticClass: "help is-danger" }, [
                          _vm._v(" Please enter a name. ")
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Roles")]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.performingArtist,
                        expression: "user.performingArtist"
                      }
                    ],
                    staticClass: "is-checkradio is-success",
                    class: {
                      "has-background-color": _vm.user.performingArtist
                    },
                    attrs: {
                      type: "checkbox",
                      id: "isPerformingArtist",
                      disabled: _vm.profiles && _vm.profiles.length > 0
                    },
                    domProps: {
                      checked: _vm.profiles && _vm.profiles.length > 0,
                      checked: Array.isArray(_vm.user.performingArtist)
                        ? _vm._i(_vm.user.performingArtist, null) > -1
                        : _vm.user.performingArtist
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.user.performingArtist,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.user,
                                  "performingArtist",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.user,
                                  "performingArtist",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.user, "performingArtist", $$c)
                          }
                        },
                        _vm.saveUser
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "isPerformingArtist" } }, [
                    _vm._v(" Performing Artist ")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.venueManager,
                        expression: "user.venueManager"
                      }
                    ],
                    staticClass: "is-checkradio is-success",
                    class: {
                      "has-background-color": _vm.user.venueManager
                    },
                    attrs: { type: "checkbox", id: "isVenueManager" },
                    domProps: {
                      checked: Array.isArray(_vm.user.venueManager)
                        ? _vm._i(_vm.user.venueManager, null) > -1
                        : _vm.user.venueManager
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.user.venueManager,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.user,
                                  "venueManager",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.user,
                                  "venueManager",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.user, "venueManager", $$c)
                          }
                        },
                        _vm.saveUser
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "isVenueManager" } }, [
                    _vm._v(" Venue Manager ")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.festivalManager,
                        expression: "user.festivalManager"
                      }
                    ],
                    staticClass: "is-checkradio is-success",
                    class: {
                      "has-background-color": _vm.user.festivalManager
                    },
                    attrs: { type: "checkbox", id: "isFestivalManager" },
                    domProps: {
                      checked: Array.isArray(_vm.user.festivalManager)
                        ? _vm._i(_vm.user.festivalManager, null) > -1
                        : _vm.user.festivalManager
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.user.festivalManager,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.user,
                                  "festivalManager",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.user,
                                  "festivalManager",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.user, "festivalManager", $$c)
                          }
                        },
                        _vm.saveUser
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "isFestivalManager" } }, [
                    _vm._v(" Festival Manager ")
                  ])
                ])
              ]),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    staticStyle: { display: "none" },
                    on: { click: _vm.facebook }
                  },
                  [_vm._v(" Facebook Me ")]
                )
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("User Image")
                      ]),
                      false ? _c("div", [_c("Avatar")], 1) : _vm._e(),
                      !_vm.showImagePicker && !_vm.showImageLoading
                        ? _c("div", { staticClass: "imagefield" }, [
                            _vm.user.avatar
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.user.avatar,
                                    alt: "User Image"
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                on: {
                                  click: function($event) {
                                    _vm.user.avatar = null
                                  }
                                }
                              },
                              [_vm._v(" Change Image ")]
                            )
                          ])
                        : _vm._e(),
                      _vm.showImagePicker
                        ? _c("UploadImage", {
                            attrs: { outputWidth: 230, outputHeight: 230 },
                            on: { imageCropped: _vm.saveImage }
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "buttons" }),
                      _vm.showImageLoading
                        ? _c("div", [
                            _c(
                              "progress",
                              {
                                staticClass: "progress is-primary",
                                attrs: { max: "100" },
                                domProps: { value: _vm.percentComplete }
                              },
                              [_vm._v(_vm._s(_vm.percentComplete))]
                            ),
                            _c("p", [_vm._v("Uploading image...")])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step == 2,
                expression: "step == 2"
              }
            ],
            staticClass: "column"
          },
          [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "box" },
              [
                false
                  ? _c("p", [
                      _vm._v(
                        " By creating an artist profile, you will be the owner of that profile. That means that you will have access to change information in the profile or delete it and create events for it. You can also add co-owners, if you’d like, who will have the same access. "
                      )
                    ])
                  : _vm._e(),
                _c("p", [_vm._v("My Artists")]),
                _vm._l(_vm.profiles, function(profile, index) {
                  return _c("ActEdit", {
                    key: index,
                    attrs: {
                      profile: profile,
                      editing: _vm.editingArtist == profile.id
                    },
                    on: { profileSet: _vm.saveProfile, edit: _vm.editArtist }
                  })
                }),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: {
                        disabled:
                          _vm.profiles &&
                          _vm.profiles[0] &&
                          !_vm.profiles[_vm.profiles.length - 1].id
                      },
                      on: {
                        click: function($event) {
                          return _vm.addAct()
                        }
                      }
                    },
                    [_vm._m(1), _c("span", [_vm._v("Add Artist")])]
                  )
                ])
              ],
              2
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step == 3,
                expression: "step == 3"
              }
            ],
            staticClass: "column"
          },
          [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "box" },
              [
                _vm._l(_vm.venues, function(profile, index) {
                  return _c("Venue", {
                    key: index,
                    attrs: {
                      profile: profile,
                      editing: _vm.editingVenue == profile.id,
                      editable: true
                    },
                    on: { profileSet: _vm.saveProfile, edit: _vm.editVenue }
                  })
                }),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: {
                        disabled:
                          _vm.profiles &&
                          _vm.profiles[0] &&
                          !_vm.profiles[_vm.profiles.length - 1].id
                      },
                      on: {
                        click: function($event) {
                          return _vm.addVenue()
                        }
                      }
                    },
                    [_vm._m(3), _c("span", [_vm._v("Add Venue")])]
                  )
                ])
              ],
              2
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step == 4,
                expression: "step == 4"
              }
            ],
            staticClass: "column"
          },
          [
            _vm._m(4),
            _c(
              "div",
              { staticClass: "box" },
              [
                _vm._l(_vm.festivals, function(festival, index) {
                  return _c("Festival", {
                    key: index,
                    attrs: {
                      festival: festival,
                      editing: _vm.editingFestival == festival.id,
                      editable: true
                    },
                    on: {
                      festivalChanged: _vm.saveFestival,
                      edit: _vm.editFestival
                    }
                  })
                }),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: {
                        disabled:
                          _vm.profiles &&
                          _vm.profiles[0] &&
                          !_vm.profiles[_vm.profiles.length - 1].id
                      },
                      on: {
                        click: function($event) {
                          return _vm.addFestival()
                        }
                      }
                    },
                    [_vm._m(5), _c("span", [_vm._v("Add Festival")])]
                  )
                ])
              ],
              2
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step == 5,
                expression: "step == 5"
              }
            ],
            staticClass: "column"
          },
          [
            _c("h4", [_vm._v("Finally, let's make sure you can get paid.")]),
            _vm.status == "payment" ||
            (!_vm.status && _vm.user.stripeStatus != "connected")
              ? _c("div", { staticClass: "box" }, [
                  _c("p", [
                    _vm._v(
                      "In order to produce paid events, we will need to connect to your bank account to make deposits. By default, we pass payments to you on a nightly (bank business days) basis. If you are doing free events, you can skip this step. "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " Gobo uses Stripe to process payments. Stripe pays you directly - minus a flat 5% fee that includes credit card processing. If you have an existing Stripe account, you can use that. If not, create one now to get started! "
                    )
                  ]),
                  !_vm.user.stripeLink
                    ? _c(
                        "a",
                        {
                          staticClass: "button is-primary",
                          class: { "is-loading": _vm.stripelinkStatus },
                          on: { click: _vm.createStripeLink }
                        },
                        [_vm._v("Connect Stripe")]
                      )
                    : _vm._e(),
                  _vm.stripelinkStatus && _vm.stripeProgress < 100
                    ? _c("p", [_vm._v("Requesting Stripe Link")])
                    : _vm._e(),
                  _vm.stripelinkStatus && _vm.stripeProgress == 100
                    ? _c("p", [_vm._v("Stripe Link Created")])
                    : _vm._e(),
                  _vm.stripelinkStatus
                    ? _c(
                        "progress",
                        {
                          staticClass: "progress is-primary",
                          attrs: { max: "100" },
                          domProps: { value: _vm.stripeProgress }
                        },
                        [_vm._v(_vm._s(_vm.stripeProgress) + "%")]
                      )
                    : _vm._e(),
                  _vm.stripelinkStatus && _vm.stripeProgress == 100
                    ? _c("p", [
                        _vm._v(
                          "Step 2. We have created a Stripe connection for your account. Click the button below to connect or create your Stripe account and link it to Gobo."
                        )
                      ])
                    : _vm._e(),
                  _vm.user.stripeLink
                    ? _c(
                        "a",
                        {
                          staticClass: "button is-primary",
                          attrs: { href: _vm.user.stripeLink.url }
                        },
                        [_vm._v("Complete Stripe Connection")]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.status && !_vm.user.stripeAccount
              ? _c("div", { staticClass: "box" }, [
                  _c(
                    "progress",
                    {
                      staticClass: "progress is-small is-primary",
                      attrs: { max: "100" }
                    },
                    [_vm._v("15%")]
                  ),
                  _c("p", [_vm._v("Completing Stripe set up...")])
                ])
              : _vm._e(),
            _vm.user.stripeStatus == "connected"
              ? _c("div", { staticClass: "box" }, [_vm._m(6)])
              : _vm._e()
          ]
        ),
        _c("div", {
          staticClass:
            "column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
        })
      ])
    ]),
    _c("div", { staticClass: "box stepbar" }, [
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column" }, [
          _c("div", { staticClass: "buttons is-right" }, [
            _vm.step > 1
              ? _c(
                  "button",
                  {
                    staticClass: "button",
                    on: {
                      click: function($event) {
                        _vm.step = _vm.step - 1
                      }
                    }
                  },
                  [_vm._v(" Back ")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button is-primary",
                on: {
                  click: function($event) {
                    return _vm.next()
                  }
                }
              },
              [_vm._v(" Next ")]
            )
          ])
        ]),
        _c("div", {
          staticClass:
            "column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
        })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [
        _c("h4", [_vm._v("Add artist profile(s) to our database")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [
        _c("h4", [_vm._v("Add the venues that you manage.")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [
        _c("h4", [_vm._v("Add the festivals that you manage.")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", { staticClass: "fas fa-check" }),
      _vm._v(" Stripe account connected!")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }