<template>
  <div class="body-wrapper">
    <div class="body-content">
      <div
        v-if="festivalSeasons && festivalSeasons.length > 0"
        class="container festyfest"
      >
        <h1>My Festivals</h1>
        <table id="eventTable">
          <tr class="header is-hidden-mobile">
            <th colspan="2">Event Details</th>
            <th>Sold</th>
            <th>Gross</th>
            <th>Status</th>
            <th></th>
          </tr>
          <tr v-for="season in festivalSeasons" :key="season.id">
            <td class="is-hidden-mobile">
              <EventDateBadge
                :date="season.start.toDate()"
                v-if="season.start"
              />
            </td>
            <td>
              <div class="synopsis">
                <div class="eventimagerapper">
                  <div class="imageholder">
                    <img :src="season.image" alt="" class="eventimage" />
                  </div>
                </div>
                <EventDateline :event="season" />
                <div class="eventTitle">
                  <router-link
                    :to="{
                      name: 'festivalSummary',
                      params: { festivalId: season.festivalId, id: season.id }
                    }"
                    v-if="
                      season.status &&
                        (season.status == 'published' ||
                          season.status == 'complete')
                    "
                    >{{ season.title }}</router-link
                  >
                  <router-link
                    :to="{
                      name: 'editFestival',
                      params: { festivalId: season.festivalId, id: season.id }
                    }"
                    v-if="
                      !season.status ||
                        (season.status != 'published' &&
                          season.status != 'complete')
                    "
                    >{{ season.title }}</router-link
                  >
                </div>
                <div class="venue">{{ season.venueName }}</div>
                <div class="is-clearfix"></div>
                <div
                  class="stats is-hidden-tablet"
                  v-if="season.audienceReport"
                >
                  <div class="col">
                    <div class="field">
                      <label class="label">Sold</label>
                      <div class="control">
                        <span
                          v-if="
                            season.audienceReport &&
                              season.audienceReport.totalSold
                          "
                          ><strong>{{
                            season.audienceReport.totalSold
                          }}</strong>
                          /{{ season.audienceReport.capacity }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="field">
                      <label class="label">Gross</label>
                      <div class="control">
                        <span v-if="season.audienceReport"
                          >${{ season.audienceReport.salesTotal }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="status is-hidden-tablet">
                  <label class="label">Status</label>
                  <span class="statusstatus">{{ season.status }}</span>
                </div>
              </div>
              <div class="menubar is-hidden-tablet">
                <div class="buttons mobilebar">
                  <button
                    class="button is-info"
                    :disabled="season.status != 'draft'"
                    @click="deleteDate(season)"
                  >
                    Cancel
                  </button>
                  <button
                    class="button is-info"
                    @click="
                      $router.push({
                        name: 'editEvent',
                        params: { id: season.id }
                      })
                    "
                  >
                    Edit
                  </button>
                  <button
                    class="button is-info"
                    @click="duplicateEvent(season.id)"
                  >
                    Duplicate
                  </button>
                </div>
              </div>
            </td>
            <td class="results is-hidden-mobile">
              <span
                v-if="season.audienceReport && season.audienceReport.totalSold"
                ><strong>{{ season.audienceReport.totalSold }}</strong> /{{
                  season.audienceReport.capacity
                }}</span
              >
            </td>
            <td class="results is-hidden-mobile">
              <span v-if="season.audienceReport"
                >${{ season.audienceReport.salesTotal }}</span
              >
            </td>
            <td class="results is-hidden-mobile">{{ season.status }}</td>
            <td class="is-hidden-mobile">
              <div
                class="dropdown"
                :class="{ 'is-active': editing == season.id }"
                @click="editing = season.id"
              >
                <div class="dropdown-trigger">
                  <button
                    class="button event-menu"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu3"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
                  <div class="dropdown-content" @blur="season.showMenu = false">
                    <router-link
                      class="dropdown-item"
                      :to="{
                        name: 'eventSummary',
                        params: { id: season.id }
                      }"
                      >Sales Summary</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :to="{
                        name: 'editFestival',
                        params: { festivalId: season.festivalId, id: season.id }
                      }"
                      >Edit Festival</router-link
                    >
                    <a class="dropdown-item" @click="duplicateEvent(season.id)"
                      >Duplicate Event</a
                    >
                    <a
                      v-if="
                        season.status == 'draft' ||
                          !season.audienceReport ||
                          season.audienceReport.totalSold == 0
                      "
                      class="dropdown-item"
                      @click="deleteDate(season)"
                      >Delete Event</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="container">
        <h1>My Events</h1>
        <table id="eventTable">
          <tr class="header is-hidden-mobile">
            <th colspan="2">Event Details</th>
            <th>Sold</th>
            <th>Gross</th>
            <th>Status</th>
            <th></th>
          </tr>
          <tr v-for="summary in eventSummarys" :key="summary.event.id">
            <td class="is-hidden-mobile">
              <EventDateBadge
                :date="summary.event.start.toDate()"
                v-if="summary.event.start"
              />
            </td>
            <td>
              <div class="synopsis">
                <div class="eventimagerapper">
                  <div class="imageholder">
                    <img :src="summary.event.image" alt="" class="eventimage" />
                  </div>
                </div>
                <EventDateline :event="summary.event" />
                <div class="eventTitle">
                  <router-link
                    :to="{
                      name: 'eventSummary',
                      params: { id: summary.event.id }
                    }"
                    v-if="
                      summary.event.status &&
                        (summary.event.status == 'published' ||
                          summary.event.status == 'complete')
                    "
                    >{{ summary.event.title }}</router-link
                  >
                  <router-link
                    :to="{
                      name: summary.event.seasonId
                        ? 'editFestivalEvent'
                        : 'editEvent',
                      params: { id: summary.event.id }
                    }"
                    v-if="
                      !summary.event.status ||
                        (summary.event.status != 'published' &&
                          summary.event.status != 'complete')
                    "
                    >{{ summary.event.title }}</router-link
                  >
                </div>
                <div class="venue">{{ summary.event.venueName }}</div>
                <div class="is-clearfix"></div>
                <div
                  class="stats is-hidden-tablet"
                  v-if="summary.audienceReport"
                >
                  <div class="col">
                    <div class="field">
                      <label class="label">Sold</label>
                      <div class="control">
                        <span
                          v-if="
                            summary.audienceReport &&
                              summary.audienceReport.totalSold
                          "
                          ><strong>{{
                            summary.audienceReport.totalSold
                          }}</strong>
                          /{{ summary.audienceReport.capacity }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="field">
                      <label class="label">Gross</label>
                      <div class="control">
                        <span v-if="summary.audienceReport"
                          >${{ summary.audienceReport.salesTotal }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="status is-hidden-tablet">
                  <label class="label">Status</label>
                  <span class="statusstatus">{{ summary.event.status }}</span>
                </div>
              </div>
              <div class="menubar is-hidden-tablet">
                <div class="buttons mobilebar">
                  <button
                    class="button is-info"
                    :disabled="summary.event.status != 'draft'"
                    @click="deleteDate(summary.event)"
                  >
                    Cancel
                  </button>
                  <button
                    class="button is-info"
                    @click="
                      $router.push({
                        name: summary.event.seasonId
                          ? 'editFestivalEvent'
                          : 'editEvent',
                        params: { id: summary.event.id }
                      })
                    "
                  >
                    Edit
                  </button>
                  <button
                    class="button is-info"
                    @click="duplicateEvent(summary.event.id)"
                  >
                    Duplicate
                  </button>
                </div>
              </div>
            </td>
            <td class="results is-hidden-mobile">
              <span
                v-if="
                  summary.audienceReport && summary.audienceReport.totalSold
                "
                ><strong>{{ summary.audienceReport.totalSold }}</strong> /{{
                  summary.audienceReport.capacity
                }}</span
              >
            </td>
            <td class="results is-hidden-mobile">
              <span v-if="summary.audienceReport"
                >${{ summary.audienceReport.salesTotal }}</span
              >
            </td>
            <td class="results is-hidden-mobile">{{ summary.event.status }}</td>
            <td class="is-hidden-mobile">
              <div
                class="dropdown"
                :class="{ 'is-active': editing == summary.event.id }"
              >
                <div class="dropdown-trigger" @click="editing = summary.event.id">
                  <button
                    class="button event-menu"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu3"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                  <div
                    class="dropdown-content"                
                  >
                    <router-link
                      class="dropdown-item"
                      :to="{
                        name: 'eventSummary',
                        params: { id: summary.event.id }
                      }"
                      >Sales Summary</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :to="{
                        name: summary.event.seasonId
                          ? 'editFestivalEvent'
                          : 'editEvent',
                        params: { id: summary.event.id }
                      }"
                      >Edit Event</router-link
                    >
                    <a
                      class="dropdown-item"
                      @click="duplicateEvent(summary.event.id)"
                      >Duplicated Event</a
                    >
                    <a
                      v-if="
                        summary.event.status == 'draft' ||
                          !summary.audienceReport ||
                          summary.audienceReport.totalSold == 0
                      "
                      class="dropdown-item"
                      @click="deleteDate(summary.event)"
                      >Delete Event</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventDateBadge from "@/components/event-date-badge";
import EventDateline from "@/components/event-dateline";
import store from "@/store";
import router from "../router";

export default {
  name: "events",
  data() {
    return {
      editing: ""
    };
  },
  components: {
    EventDateBadge,
    EventDateline
  },
  computed: {
    ...mapGetters({
      user: "user",
      eventSummarys: "eventSummarys",
      festivalSeasons: "festivalSeasons"
    })
  },
  methods: {
    async duplicateEvent(eventId) {
      console.log("Duplicating Event: ", eventId);
      var newid = await store.dispatch("duplicateEvent", eventId);
      router.push({ name: "editEvent", params: { id: newid } });
    },
    deleteDate(date) {
      console.log("beleting");
      date.userId = this.user.uid;
      store.dispatch("showConfirmation", {
        header: "Delete event?",
        message: "Are you sure you want to delete this event?",
        cta: "delete",
        action: "deleteEvent",
        object: date
      });
      console.log(date);
      //store.dispatch("deleteDate", date);
    }
  },
  created() {
    console.log("Get event summarys.");
    store.subscribe(mutation => {
      console.log(
        "route name",
        this.$route.name,
        "mutation type",
        mutation.type
      );
      if (
        mutation.type === "SET_USER" &&
        this.user &&
        this.user.uid &&
        this.eventSummarys.length == 0
      ) {
        this.$store.dispatch("fetchEventSummarys", this.user.uid);
        this.$store.dispatch("fetchFestivalSeasons", this.user.uid);
      }
    });
  },
  mounted() {
    // You need to add a guard here to see if there are no events, and if there is a user, and if so,
    // trigger this shit.
    if (this.user && this.user.uid && this.eventSummarys.length == 0) {
      this.$store.dispatch("fetchEventSummarys", this.user.uid);
      // this.$store.dispatch("fetchFestivalSeasons", this.user.uid);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.body-content {
  margin-bottom: 50px;
}
.timeline {
  font-weight: bold;
  font-size: 12px;
}
.cal-body {
  border: none;
  padding: 0;
}
.event-menu {
  width: 50px;
  min-width: 50px;
  height: 50px;
  &:hover {
    border-color: $active-green;
  }
}
.venue {
  font-size: 14px;
}
td.results {
  font-family: $family-secondary;
  font-size: 14px;
  text-transform: uppercase;
}
.stats {
  clear: both;
  display: flex;
  margin-top: 24px;
  .col {
    flex: 1;
    .label {
      margin-bottom: 0;
    }
    .control {
      font-size: 18px;
    }
  }
}
.status {
  margin-top: 10px;
  margin-bottom: 24px;
  .label {
    margin-bottom: 0;
  }
  .statusstatus {
    font-family: $family-secondary;
    text-transform: uppercase;
    font-size: 14px;
  }
}
.buttons.mobilebar {
  background-color: $gray6;
  .button,
  .button:hover,
  .button:not(:last-child):not(.is-fullwidth) {
    background-color: $gray6;
    color: $gray1;
    height: 42px;
    margin: 0;
    border-radius: 0;
  }
  .button.is-info[disabled] {
    border: none;
  }
}
.festyfest {
  margin-bottom: 60px;
}
@media screen and (max-width: 769px) {
  #eventTable tr td {
    padding: 15px 0;
  }
  .body-content {
    padding: 15px 0;
  }
  .synopsis {
    padding: 0 15px;
  }
  h1 {
    margin: 0 15px 25px;
  }
}
</style>