var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("First Name")]),
        _c("th", [_vm._v("Last Name")]),
        _c("th", [_vm._v("Email")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }