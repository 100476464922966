<template>
  <div class="body-wrapper" v-if="event">
    <div class="body-content gray">
      <div class="container">
        <div class="columns">
          <div class="column is-one-quarter">
            <h1 v-if="!user.eventPublished">
              Your <br />
              first <br />
              event!
            </h1>
            <h1 v-if="user.eventPublished">
              Create <br />
              an <br />
              event!
            </h1>
            <div class="box is-hidden-mobile">
              <div class="block">
                <aside class="menu">
                  <p class="menu-label">
                    Event Set Up
                  </p>
                  <ul class="menu-list">
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 1 }"
                        @click="setStep(1)"
                        >Basic Info</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 2 }"
                        @click="setStep(2)"
                        >Dates and Times</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 3 }"
                        @click="setStep(3)"
                        >Location</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 4 }"
                        @click="setStep(4)"
                        >Ticket Info</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 5 }"
                        @click="setStep(5)"
                        >Discount Codes</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 6 }"
                        @click="setStep(6)"
                        >Email Settings</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 7 }"
                        @click="setStep(7)"
                        >Preview and Publish</a
                      >
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 1">
            <h4>Basic Info</h4>
            <div class="box">
              <div class="field" ref="croppiecont">
                <label class="label">Event Image</label>
                <img
                  v-if="event.image"
                  :src="event.image"
                  class="eventimageheader"
                  alt=""
                />
                <button
                  v-if="event.image && event.status != 'complete'"
                  class="button"
                  @click="event.image = null"
                >
                  Use a Different Image
                </button>
                <UploadImage
                  v-if="!event.image && !cropping && !showImageLoading"
                  id="event"
                  :outputWidth="800"
                  :outputHeight="360"
                  v-on:imageCropped="crop"
                />
                <div v-if="showImageLoading">
                  <progress
                    class="progress is-primary"
                    :value="percentComplete"
                    max="100"
                    >{{ percentComplete }}</progress
                  >
                  <p>Uploading image...</p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Event Title</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Event Title"
                        v-model.lazy="event.title"
                        :disabled="event.status == 'complete'"
                        :class="{ 'is-danger': errors.title }"
                        @change="saveEvent()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.title">
                      {{ errors.title }}
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Event Type</label>
                    <div class="select">
                      <select
                        v-model="eventType"
                        :disabled="event.status == 'complete'"
                        @change="eventTypeChange()"
                      >
                        <option value="music">Music</option>
                        <option value="theater">Theater</option>
                        <option value="dance">Dance</option>
                        <option value="improv">Improv</option>
                        <option value="storytelling">Storytelling</option>
                        <option value="standup">Standup Comedy</option>
                        <option value="conferences"
                          >Conferences/Exhibitions</option
                        >
                        <option value="opera">Opera</option>
                        <option value="music theater">Music Theater</option>
                        <option value="art">Visual Art</option>
                        <option value="gatherings">Social Gatherings</option>
                        <option value="sports">Sports</option>
                        <option value="children">Children's Programs</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <p class="help is-danger" v-if="false">
                      Please enter a name.
                    </p>
                  </div>
                  <div class="field" v-if="eventType == 'other'">
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Event Type"
                        v-model.lazy="event.genre"
                        :disabled="event.status == 'complete'"
                        :class="{ 'is-danger': errors.genre }"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.genre">
                      {{ errors.genre }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Artist</label>
                <div v-for="(artist, index) in event.artists" :key="index">
                  <div
                    v-if="addingArtistImage == index"
                    style="margin: 20px 0 10px"
                  >
                    <UploadImage
                      :outputWidth="400"
                      :outputHeight="400"
                      :id="'artist-' + index"
                      v-on:imageCropped="saveArtistImage"
                    />
                  </div>
                  <div class="columns">
                    <div class="column is-narrow">
                      <div class="avatar-generic">
                        <img v-if="artist.image" :src="artist.image" alt="" />
                        <div
                          @click="addArtistImage(index)"
                          v-if="!artist.image"
                          class="missing-avatar"
                        >
                          <img
                            src="/img/icons/missing-image.png"
                            alt="add artist image"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <p class="artistname">
                        <strong>{{ artist.name }}</strong
                        >&nbsp;&nbsp;|&nbsp;&nbsp;{{ artist.type }}
                      </p>
                    </div>
                    <div class="column">
                      <p v-if="artist.owners" class="managedby">
                        {{ getowners(artist) }}
                      </p>
                      <p v-if="!artist.owners">
                        {{ artist.name }} is not using Gobo yet. Invite them to
                        help sell tickets with you.
                      </p>
                      <InviteUserStarter
                        v-if="!artist.owners"
                        :inviterName="user.name"
                        :inviterId="user.uid"
                        :profileName="artist.name"
                        :actId="artist.id"
                        :newProfile="true"
                      />
                    </div>
                    <div class="column is-narrow">
                      <span
                        class="icon is-medium delete"
                        @click="removeArtist(index)"
                        v-if="event.status != 'published'"
                        ><i class="fas fa-lg fa-trash"></i
                      ></span>
                      <span
                        class="icon is-medium info"
                        v-if="event.status == 'published'"
                        ><i class="fas fa-lg fa-info-circle"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div
                  class="buttons addartist"
                  v-if="(!profiles || profiles.length == 0) && !addingArtist"
                >
                  <button
                    class="button"
                    @click="addingArtist = true"
                    :disabled="
                      profiles &&
                        profiles[0] &&
                        !profiles[profiles.length - 1].id
                    "
                  >
                    <span class="icon is-small">
                      <i class="fas fa-plus"></i>
                    </span>
                    <span>Add Artist</span>
                  </button>
                </div>
                <AddArtist v-if="addingArtist" v-on:artistAdded="addArtist" />
                <div v-if="!event.actId && profiles && profiles.length > 0">
                  <div class="select">
                    <select
                      :disabled="event.status == 'complete'"
                      v-model="event.actId"
                      @change="actChanged()"
                    >
                      <option :value="null" disabled selected
                        >Select Artist</option
                      >
                      <option
                        v-for="profile in profiles"
                        :key="profile.id"
                        :value="profile.id"
                        >{{ profile.name }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Event Description</label>
                <label class="label"
                  >Add details like what to expect, list of performers or
                  sponsors.</label
                >
                <div class="control">
                  <quill-editor
                    ref="myQuillEditor"
                    :content="descriptionContent"
                    :options="editorOption"
                    :class="{ 'ql-focus': editorHasFocus }"
                    @blur="editorHasFocus = false"
                    @focus="editorHasFocus = true"
                    @change="saveEventDescription($event)"
                  />
                </div>
                <p class="help is-danger" v-if="errors.description">
                  {{ errors.description }}
                </p>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 2">
            <h4>Dates + Times</h4>
            <div class="box">
              <EventDate
                v-for="date in eventDates"
                :key="date.id"
                :date="date"
                :defaultDuration="duration"
                :editing="true"
                :disable="event.status == 'complete'"
                v-on:dateSet="updateDate"
                v-on:deleted="deleteDate"
              />
              <p class="buttons">
                <button class="button" :disabled="newDate" @click="newEvent">
                  <span class="icon is-medium">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span>Add date</span>
                </button>
              </p>
            </div>
          </div>

          <div class="column" v-show="step == 3">
            <h4>Location</h4>
            <div class="box">
              <div
                class="field"
                v-if="event.status != 'published' && event.status != 'complete'"
              >
                <label class="label">Venue Location</label>
                <div class="control has-icons-left" v-if="!venue">
                  <GmapAutocomplete
                    class="input"
                    @place_changed="setPlace"
                    :fields="fields"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                  </span>
                </div>
                <p class="notification is-danger" v-if="locationError">
                  {{ locationError }}
                </p>
              </div>
              <VenueMap v-if="venue && (!event.mapDisplay || !event.venueIsAlternative || event.mapDisplay != 'hidden')" :venue="venue" />
              <div v-if="venue && venue.owner">
                <p>{{ venue.name }} is using Gobo!</p>
                <Venue :profile="venue" :editable="false" />
              </div>
              <div v-if="venue && !venue.owner">
                <p>
                  {{ venue.name }} is not using Gobo yet. Invite them to help
                  sell tickets with you.
                </p>
                <InviteUserStarter
                  :inviterName="user.name"
                  :inviterId="user.uid"
                  :profileName="venue.name"
                  :venueId="venue.id"
                  :newProfile="true"
                />
              </div>
              <div class="control">
                <label class="checkbox">
                  <input
                    v-model="event.venueIsAlternative"
                    @change="saveEvent()"
                    type="checkbox"
                  />
                  Alternative Venue. Obscure location until purchase.
                </label>
              </div>
              <div class="control" v-if="event.venueIsAlternative" style="margin-top: 10px">
                <input
                  class="is-checkradio"
                  id="alternativeObscure"
                  type="radio"
                  name="alternativeObscure"
                  v-model="event.mapDisplay"
                  value="obscured"
                  @change="saveEvent"
                />
                <label for="alternativeObscure"
                  >Show map with obscured location</label
                >
                <input
                  class="is-checkradio"
                  id="alternativeHide"
                  type="radio"
                  v-model="event.mapDisplay"
                  value="hidden"
                  @change="saveEvent"
                />
                <label for="alternativeHide">Hide map</label>
              </div>
              <hr v-if="venue" />
              <div v-if="venue">
                <button class="button" @click="clearVenue">
                  Use a Different Venue
                </button>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 4">
            <h4>Ticket Info</h4>
            <div class="box">
              <p>
                You can create up to five named tiers of tickets (e.g.,
                Pre-sale, VIP, General Admission, Guest List, etc.)
              </p>
              <nav class="level">
                <div class="level-left">
                  <div class="field">
                    <label class="label">Total tickets per performance</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="number"
                        placeholder="Number of tickets"
                        v-model.number="event.capacity"
                        :disabled="event.status == 'complete'"
                        :class="{ 'is-danger': errors.capacity }"
                        @change="saveEvent()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                </div>
                <div class="level-right">
                  <div class="field">
                    <input
                      id="donation"
                      type="checkbox"
                      name="switchExample"
                      class="switch"
                      v-model="event.donation"
                      @change="setDonation()"
                    />
                    <label for="donation">Artist Donation</label>
                  </div>
                </div>
              </nav>
              <hr />
              <PricingTier
                v-for="tier in priceTiers"
                :key="tier.id"
                :tier="tier"
                :dates="eventDates"
                :flexible="flexibleQuantity"
                :disable="event.status == 'complete'"
                v-on:tierSet="saveTier"
                v-on:deleted="deleteTier"
              />
              <PricingTier
                v-if="newtier"
                :tier="newtier"
                :dates="eventDates"
                :flexible="flexibleQuantity"
                v-on:tierSet="saveTier"
                v-on:deleted="deleteTier"
              />
              <p class="buttons">
                <button
                  class="button"
                  :disabled="newtier || priceTiers.length > 4"
                  @click="newPriceTier"
                >
                  <span class="icon is-medium">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span>Add ticket tier</span>
                </button>
              </p>
              <div
                v-if="
                  priceTiers &&
                    priceTiers.length > 0 &&
                    priceTiers.filter(t => t.feeType != 'free').length > 0
                "
              ></div>
              <hr />
              <div class="field">
                <input
                  id="donation"
                  type="checkbox"
                  name="switchExample"
                  class="switch"
                  v-model="event.donation"
                  @change="saveEvent()"
                />
                <label for="donation">Add Donation Option</label>
              </div>
              <PricingTier
                v-if="event.donation"
                :tier="event.donationDetails"
                type="donation"
                :dates="eventDates"
                :flexible="flexibleQuantity"
                v-on:tierSet="saveDonation"
              />
            </div>
          </div>

          <div class="column" v-show="step == 5">
            <h4>Discount Codes</h4>
            <DiscountCodes
              :discounts="discounts"
              :dates="eventDates"
              :disabled="event.status == 'complete'"
              :eventId="event.id"
              v-on:hasDiscountsChange="setHasDiscount"
              v-on:newDiscount="newDiscount"
              v-on:codeChanged="saveDiscount"
            />
          </div>

          <div class="column" v-show="step == 6">
            <h4>Email Settings</h4>
            <div class="box">
              <div class="field">
                <label class="label">Email Message</label>
                <label class="label"
                  >Add any extra information you would like to include about the
                  event to be added to the end of the email confirmation
                  (parking, transportation, doors open time, etc.).</label
                >
                <div class="control">
                  <quill-editor
                    ref="emailMessageEditor"
                    :content="emailContent"
                    :options="editorOption"
                    :class="{ 'ql-focus': emailEditorHasFocus }"
                    @blur="emailEditorHasFocus = false"
                    @focus="emailEditorHasFocus = true"
                    @change="updateEmail($event)"
                  />
                </div>
              </div>
              <TestEmailSender type="confirmation" :eventId="event.id" />
            </div>
            <div class="box">
              <div class="control">
                <input
                  type="checkbox"
                  v-model="settings.sendReminderEmails"
                  id="showPreshowReminder"
                  @change="saveSettings"
                  :class="{
                    'has-background-color': settings.sendReminderEmails
                  }"
                  class="is-checkradio is-success"
                />
                <label for="showPreshowReminder">
                  Send Preshow Reminder Emails
                </label>
              </div>
              <div>
                <div class="control bumpadown">
                  <label class="label">Timing</label>
                </div>
                <div class="control">
                  <input
                    class="is-checkradio"
                    id="reminderDayOf"
                    type="radio"
                    name="reminderDayOf"
                    v-model.number="settings.reminderDaysBefore"
                    value="0"
                    @change="saveSettings"
                  />
                  <label for="reminderDayOf">Day of the Event</label>
                  <input
                    class="is-checkradio"
                    id="reminderOneDay"
                    type="radio"
                    v-model.number="settings.reminderDaysBefore"
                    value="1"
                    @change="saveSettings"
                  />
                  <label for="reminderOneDay">1 day before</label>
                  <input
                    class="is-checkradio"
                    id="reminderTwoDay"
                    type="radio"
                    name="reminderTwoDay"
                    v-model.number="settings.reminderDaysBefore"
                    value="2"
                    @change="saveSettings"
                  />
                  <label for="reminderTwoDay">2 days before</label>
                </div>
              </div>
              <div class="field" v-if="settings && settings.sendReminderEmails">
                <label class="label">Preshow Reminder Email</label>
                <label class="label"
                  >Add any extra information you would like to include about the
                  event to be added to the end of the preshow reminder email
                  (parking, transportation, doors open time, etc.).</label
                >
                <div class="control">
                  <quill-editor
                    ref="emailMessageEditor"
                    :content="emailReminderContent"
                    :options="editorOption"
                    :class="{ 'ql-focus': emailReminderEditorHasFocus }"
                    @blur="emailReminderEditorHasFocus = false"
                    @focus="emailReminderEditorHasFocus = true"
                    @change="updateReminderEmail($event)"
                  />
                </div>
                <TestEmailSender type="reminder" :eventId="event.id" />
              </div>
            </div>
            <div class="box">
              <div class="control">
                <input
                  type="checkbox"
                  id="sendThankyouEmails"
                  v-model="settings.sendThankyouEmails"
                  :class="{
                    'has-background-color': settings.sendThankyouEmails
                  }"
                  class="is-checkradio is-success"
                  @change="saveSettings"
                />
                <label for="sendThankyouEmails">
                  Send Postshow Thank-You Emails
                </label>
              </div>
              <div
                class="field bumpadown"
                v-if="settings && settings.sendThankyouEmails"
              >
                <label class="label">Postshow Thank-You Email</label>
                <label class="label"
                  >Add any extra information you would like to include about the
                  event to be added to the end of the postshow thank-you email
                  (survey, request for feedback, etc.).</label
                >
                <div class="control">
                  <quill-editor
                    ref="emailMessageEditor"
                    :content="emailThankyouContent"
                    :options="editorOption"
                    :class="{ 'ql-focus': emailThankyouEditorHasFocus }"
                    @blur="emailThankyouEditorHasFocus = false"
                    @focus="emailThankyouEditorHasFocus = true"
                    @change="updateThankyouEmail($event)"
                  />
                </div>
              </div>
              <TestEmailSender type="thankyou" :eventId="event.id" />
            </div>
          </div>

          <div class="column" v-show="step == 7">
            <h4>Preview + Publish</h4>
            <div class="box">
              <div class="columns">
                <div class="column is-half">
                  <div class="event-preview">
                    <div class="event-preview-header">
                      <img v-if="event.image" :src="event.image" alt="" />
                    </div>
                    <div class="event-preview-body">
                      <EventDateline :event="event" />
                      <h3>{{ event.title }}</h3>
                      <h5 v-if="venue">{{ venue.name }}</h5>
                      <button class="button is-fullwidth" @click="preview()">
                        Preview Event
                      </button>
                    </div>
                  </div>
                </div>
                <div class="column is-half">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="answer"
                        v-model="event.isPublic"
                        value="true"
                        @change="saveEvent()"
                      />
                      Public
                    </label>
                    <p class="radio-label">Shared on Gobo and searchable</p>
                    <label class="radio">
                      <input
                        type="radio"
                        name="answer"
                        v-model="event.isPublic"
                        value="false"
                        @change="saveEvent()"
                      />
                      Private
                    </label>
                    <p class="radio-label">
                      Only available to you and the people you share it with.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" v-if="cantPublish && event.status == 'draft'">
              <h4>Before you publish</h4>
              <p>
                There's a few things you need to do before you can publish your
                event.
              </p>
              <ul>
                <li v-if="!event.title">Please give your event a title</li>
                <li v-if="!event.genre">Set an event type</li>
                <li v-if="!event.act">Specify an artist or presenting group</li>
                <li v-if="!event.description">Give your event a description</li>
                <li v-if="!event.image">Add an event image</li>
                <li
                  v-if="
                    !eventDates ||
                      eventDates.length < 1 ||
                      (eventDates.length == 1 && !eventDates[0].id)
                  "
                >
                  Add at least one date
                </li>
                <li v-if="!event.venue">Set a location for your event</li>
                <li v-if="!event.capacity">Specify your event capacity</li>
                <li
                  v-if="
                    !priceTiers ||
                      priceTiers.length < 1 ||
                      (priceTiers.length == 1 && !priceTiers[0].id)
                  "
                >
                  Set at least one pricing tier for your event
                </li>
                <li
                  v-if="
                    event.maxPrice > 0 &&
                      (!user.stripeStatus || user.stripeStatus != 'connected')
                  "
                >
                  <router-link to="/profile/payment"
                    >Connect your bank account to recieve payments</router-link
                  >
                </li>
              </ul>
            </div>
          </div>

          <div
            class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
          ></div>
        </div>
      </div>
    </div>
    <div class="box stepbar">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="buttons is-right">
              <button class="button" v-if="step > 1" @click="step = step - 1">
                Back
              </button>
              <button
                class="button is-primary"
                v-if="step < 6"
                @click="next()"
                :disabled="cropping"
              >
                Next
              </button>
              <button
                class="button is-primary"
                v-if="step == 6"
                @click="next()"
              >
                Preview
              </button>
              <button
                class="button is-primary"
                v-if="event.status == 'draft' && step == 7"
                @click="publish()"
                :disabled="cantPublish"
              >
                Publish
              </button>
            </div>
          </div>
          <div
            class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.event-preview {
  border-radius: 6px;
}
.event-preview-header,
.event-preview-header img {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  line-height: 0;
}
.addartist {
  margin-top: 15px;
}
.event-preview-body {
  padding: 1em;
  border: 2px solid #f2f2f2;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.event-preview-body h3 {
  margin-bottom: 0.5em;
}
.event-preview-body h5 {
  margin-bottom: 1.2em;
}
.event-preview-body h6 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 0.5em;
}
.missing-avatar {
  padding: 5px;
  background: $gray4;
  width: 50px;
  height: 50px;
  border-radius: $radius;
}
.missing-avatar:hover {
  background-color: $gray3;
  cursor: pointer;
}
.missing-avatar img {
  width: 40px;
  height: 40px;
}
.radio-label {
  font-size: 16px;
  margin-top: 2px;
}
.upload {
  font-family: $family-primary;
  display: flex;
}
.eventimageheader {
  border-radius: 6px;
}
.ql-editor p {
  margin: 1em 0;
}
.ql-editor p:first-of-type {
  margin: 0 0 1em 0;
}
.bumpadown {
  margin-top: 10px;
}
.box .field:not(:first-child) {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .is-flex-mobile {
    flex: 1;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import EventDate from "@/components/eventDate";
import EventDateline from "@/components/event-dateline";
import PricingTier from "@/components/pricingTier";
import DiscountCodes from "@/components/editEvent/discount-codes";
import VenueMap from "@/components/venue-map";
import Venue from "@/components/venue";
import InviteUserStarter from "@/components/invite-user-starter";
import UploadImage from "@/components/upload-image";
import TestEmailSender from "@/components/test-email-sender";
import AddArtist from "@/components/editEvent/add-artist";
//import moment from "moment";
import firebase from "firebase/app";
import Bugsnag from "@bugsnag/browser";
import moment from "moment";

export default {
  name: "profile",
  props: ["id"],
  mounted() {},
  async created() {
    console.log("ID ", this.id);
    if (!this.id) {
      if (this.user && this.user.loggedIn && this.user.email)
        console.log("we've got a live one!", this.user);
      else
        store.subscribe(mutation => {
          if (mutation.type === "SET_USER") {
            var artist = null;
            var venue = null;
            if (this.profiles && this.profiles.length == 1) {
              artist = this.profiles[0];
              this.eventType = artist.type;
            }
            if (this.venues && this.venues.length == 1) venue = this.venues[0];
            store.dispatch("newEvent", { artist: artist, venue: venue });
            if (venue) store.dispatch("fetchVenue", venue.id);
          }
        });
    } else {
      store.dispatch("stashCurrentEventIdForDiscountsThisIsDumb", this.id);
      console.log("Fetching event ", this.id);
      if (!this.event || this.event.id != this.id)
        await store.dispatch("fetchEvent", this.id);
      if (this.event && this.event.description) {
        this.descriptionContent = this.event.description;
        this.eventType = this.event.genre;
      }
      console.log("Fetching email settings", this.id);
      if (this.settings && this.settings.emailMessage)
        this.emailContent = this.settings.emailMessage;
      if (this.settings && this.settings.emailReminderMessage)
        this.emailReminderContent = this.settings.emailReminderMessage;
      if (this.settings && this.settings.emailThankyouMessage)
        this.emailThankyouContent = this.settings.emailThankyouMessage;
      if (this.event.venue)
        await store.dispatch("fetchVenue", this.event.venue);
      if (this.event.stepsCompleted || this.event.copied) {
        this.step = this.event.stepsCompleted + 1;
        await store.dispatch("fetchEventDates");
        if (this.eventDates.length > 0) this.newDate = false;
        await store.dispatch("fetchPriceTiers");
        if (this.priceTiers.length > 0 || this.event.copied)
          this.newTier = false;
        if (this.event.stepsCompleted > 0 || this.event.copied)
          await store.dispatch("fetchDiscounts", this.id);
      }
    }
  },
  data() {
    return {
      step: 1,
      eventType: "music",
      addingArtist: false,
      addingArtistImage: null,
      newDate: true,
      newTier: true,
      editing: null,
      cropping: false,
      duration: null,
      showImageLoading: false,
      percentComplete: 0,
      locationError: "",
      ticketTotal: null,
      descriptionContent: "",
      emailContent: "",
      emailReminderContent: "",
      emailThankyouContent: "",
      errors: {},
      dirty: {},
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "link", "image"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        },
        placeholder: "Add your description here"
      },
      editorHasFocus: false,
      emailEditorHasFocus: false,
      emailReminderEditorHasFocus: false,
      emailThankyouEditorHasFocus: false,
      fields: [
        "place_id",
        "name",
        "formatted_phone_number",
        "website",
        "formatted_address",
        "address_components",
        "geometry"
      ]
    };
  },
  components: {
    EventDate,
    PricingTier,
    EventDateline,
    DiscountCodes,
    VenueMap,
    Venue,
    InviteUserStarter,
    UploadImage,
    TestEmailSender,
    AddArtist
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      profile: "getProfile",
      profiles: "profiles",
      event: "event",
      eventDates: "eventDates",
      venue: "venue",
      priceTiers: "priceTiers",
      newtier: "newtier",
      eventDate: "eventDate",
      eventTime: "eventTime",
      eventPrice: "eventPrice",
      settings: "settings",
      user: "user",
      discounts: "discounts",
      venues: "venues",
      userRequest: "userRequest"
    }),
    flexibleQuantity() {
      return this.event.capacity;
    },
    cantPublish() {
      if (
        !this.event.title ||
        !this.event.genre ||
        !this.event.act ||
        !this.event.description ||
        !this.event.image ||
        !this.event.venue ||
        !this.event.capacity
      )
        return true;
      if (
        !this.eventDates ||
        this.eventDates.length < 1 ||
        (this.eventDates.length == 1 && !this.eventDates[0].id)
      )
        return true;
      if (
        !this.priceTiers ||
        this.priceTiers.length < 1 ||
        (this.priceTiers.length == 1 && !this.priceTiers[0].id)
      )
        return true;
      return false;
    }
  },
  methods: {
    async next() {
      if (this.step == 1 && !this.event.id) {
        await this.saveNewEvent();
        this.$router.push({ name: "editEvent", params: { id: this.event.id } });
      } else {
        if (this.step > this.event.stepsCompleted)
          this.event.stepsCompleted = this.step;
        await store.dispatch("updateEvent", this.event);
      }
      this.step++;
    },
    async saveNewEvent() {
      this.event.stepsCompleted = 1;
      this.event.ownerId = this.user.uid;
      await store.dispatch("createEvent", this.event);
      console.log("Event id: " + this.event.id);
      store.dispatch(
        "stashCurrentEventIdForDiscountsThisIsDumb",
        this.event.id
      );
    },
    async setStep(step) {
      console.log("Step set: " + step);
      if (this.event.step == 1 && !this.event.id) {
        await this.saveNewEvent();
        this.$router.push({ name: "editEvent", params: { id: this.event.id } });
      }
      this.step = step;
    },
    addArtist(artist) {
      //console.log("Artist added to event", artist);
      if (!this.event.act) {
        this.event.act = artist.name;
        this.event.actId = artist.id;
        artist.headlining = true;
      } else artist.headlining = false;
      if (!this.event.artists) this.event.artists = [];
      else if (
        typeof this.event.artists === "object" &&
        this.event.artists.constructor !== Array
      ) {
        let oldart = this.event.artists;
        this.event.artists = [];
        for (var act in oldart) this.event.artists.push(act);
      }
      this.event.artists.push(artist);
      this.saveEvent();
      this.addingArtist = false;
    },
    removeArtist(index) {
      console.log("removing artist at index", index);
      this.event.artists.splice(index, 1);
      this.saveEvent();
    },
    addArtistImage(index) {
      console.log("adding image for artist", index);
      this.addingArtistImage = index;
    },
    saveArtistImage(image) {
      var storageRef = firebase.storage().ref();
      var imagestr =
        "performers/" + this.event.artists[this.addingArtistImage].id + ".jpg";
      console.log("saving image for artist", image, imagestr);
      var imageRef = storageRef.child(imagestr);
      this.showImagePicker = false;
      this.showImageLoading = true;
      var upload = imageRef.put(image);

      // Update percent complete
      upload.on(
        "state_changed",
        upload => {
          var progress = (this.percentComplete =
            (upload.bytesTransferred / upload.totalBytes) * 100);
          console.log("Upload is " + progress + "% done");
        },
        e => {
          console.log("Upload error: ", e);
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(url => {
            // this.image = url;
            console.log("url: ", url, this.event);
            // this.showImageLoading = false;

            // Update profile
            this.event.artists[this.addingArtistImage].image = url;
            this.saveEvent();
            store.dispatch(
              "savePerformerProfileImage",
              this.event.artists[this.addingArtistImage]
            );
            this.addingArtistImage = null;
          });
        }
      );
      // this.addingArtistImage = null;
    },
    async updateDate(date) {
      console.log("Date: " + JSON.stringify(date));
      if (date.id == null) {
        await store.dispatch("createDate", date);
        this.newDate = false;
      } else await store.dispatch("updateDate", date);
      this.duration = null;
      if (this.eventDates.length > 0) {
        var start = moment(this.eventDates[0].startTime, "h:mm A");
        var end = moment(this.eventDates[0].endTime, "h:mm A");
        this.duration = moment.duration(end.diff(start)).asMinutes();
      }
      // for (const date of this.eventDates) {
      // }
    },
    deleteDate(date) {
      console.log("beleting");
      store.dispatch("showConfirmation", {
        header: "Delete date?",
        message: "Are you sure you want to delete this event date?",
        cta: "delete",
        action: "deleteDate",
        object: date
      });
      console.log(date);
      //store.dispatch("deleteDate", date);
    },
    newEvent() {
      this.newDate = true;
      store.dispatch("addNewDate");
    },
    newPriceTier() {
      this.newTier = true;
      store.dispatch("addNewTier");
    },
    setPlace(e) {
      console.log(e);
      if (!e.address_components || e.address_components.length < 7) {
        this.locationError =
          "Invalid location. Location must have a valid address.";
        this.$analytics.logEvent("invalid_event_address", { address: e });
        Bugsnag.notify("Invalid address response.", e);
      } else {
        // An eight-part address contains a neighborhood. A seven-part does not.
        var offset = e.address_components.length == 7 ? 2 : 3;
        this.locationError = "";
        store.dispatch("setVenue", {
          id: e.place_id,
          name: e.name,
          phone: e.formatted_phone_number ? e.formatted_phone_number : null,
          website: e.website ? e.website : null,
          fullAddress: e.formatted_address,
          location: {
            lat: e.geometry.location.lat(),
            lng: e.geometry.location.lng()
          },
          address:
            e.address_components[0].long_name +
            " " +
            e.address_components[1].long_name,
          city: e.address_components[offset].long_name,
          state: e.address_components[offset + 2].long_name,
          zip: e.address_components[offset + 4].long_name
        });

        this.event.venue = e.place_id;
        this.event.venueName = e.name;
        store.dispatch("updateEvent", this.event);
      }
    },
    async saveTier(tier) {
      console.log("saving tier");
      if (tier.id == null) {
        await store.dispatch("createTier", tier);
        this.newTier = false;
      } else await store.dispatch("updateTier", tier);
    },
    async saveDonation(donation) {
      console.log("saving donation", donation);
      this.event.donationDetails = donation;
      this.saveEvent();
    },
    deleteTier(tier) {
      console.log("tier");
      store.dispatch("showConfirmation", {
        header: "Delete tier?",
        message: "Are you sure you want to delete this tier?",
        cta: "delete",
        action: "deleteTier",
        object: tier
      });
      console.log(tier);
    },
    saveSettings() {
      store.dispatch("updateEventSettings", this.settings);
    },
    saveEventDescription(event) {
      this.event.description = event.html;
      this.saveEvent();
    },
    updateEmail(event) {
      console.log("editor change!", event.html);
      var newsettings = this.settings;
      newsettings.emailMessage = event.html;
      store.dispatch("updateEventSettings", newsettings);
      //this.content = html;
    },
    updateReminderEmail(event) {
      console.log("editor change!", event.html);
      var newsettings = this.settings;
      newsettings.emailReminderMessage = event.html;
      store.dispatch("updateEventSettings", newsettings);
      //this.content = html;
    },
    updateThankyouEmail(event) {
      console.log("editor change!", event.html);
      var newsettings = this.settings;
      newsettings.emailThankyouMessage = event.html;
      store.dispatch("updateEventSettings", newsettings);
      //this.content = html;
    },
    setDonation() {
      if (this.event.donation && !this.event.donationDetails)
        this.event.donationDetails = {
          name: "Artist Donation",
          type: "sliding"
        };
      this.saveEvent();
    },
    async saveEvent() {
      if (this.event.venueIsAlternative && !this.event.mapDisplay) this.event.mapDisplay == "obscured";
      if (!this.event.id) {
        await this.saveNewEvent();
        this.$router.push({ name: "editEvent", params: { id: this.event.id } });
      } else store.dispatch("updateEvent", this.event);
    },
    saveImage(e) {
      console.log(e);
    },
    async crop(image) {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      this.cropping = false;
      this.showImageLoading = true;
      // let options = {
      //   type: "blob",
      //   size: { width: 800, height: 360 },
      //   format: "jpeg"
      // };
      var newevent = {
        ...this.event
      };
      var redirect = false;
      var output = image; //, output => {
      if (!this.event.id) {
        console.log("creating event");
        this.event.stepsCompleted = 1;
        this.event.ownerId = this.user.uid;
        await store.dispatch("createEvent", this.event);
        redirect = true;
      }
      var storageRef = firebase.storage().ref();
      var imagestr = "events/" + this.event.id + ".png";
      var imageRef = storageRef.child(imagestr);
      var upload = imageRef.put(output);

      upload.on(
        "state_changed",
        upload => {
          var progress = (this.percentComplete =
            (upload.bytesTransferred / upload.totalBytes) * 100);
          console.log("Upload is " + progress + "% done");
        },
        e => {
          console.log("Upload error: ", e);
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(url => {
            newevent = {
              ...this.event
            };
            newevent.image = url;
            store.dispatch("updateEvent", newevent).then(() => {
              if (redirect)
                this.$router.push({
                  name: "editEvent",
                  params: { id: this.event.id }
                });
              this.showImageLoading = false;
            });
          });
        }
      );
    },
    dragover(event) {
      event.preventDefault();
      event.currentTarget.classList.add("drop-active");
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    },
    drop(event) {
      event.preventDefault();
      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };
      //this.$refs.croppieRef = event.dataTransfer.files;
      //this.$refs.file.files = event.dataTransfer.files;
      //this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    },
    preview() {
      this.$router.push({ name: "previewEvent", id: this.event.id });
    },
    async publish() {
      var db = firebase.firestore();
      await db
        .collection("events")
        .doc(this.event.id)
        .update({ status: "publishMe" })
        .catch(function(error) {
          console.error("Error publishing event: ", error);
        });
      this.$router.push({ name: "eventPublished" });
    },
    eventTypeChange() {
      if (this.eventType != "other") this.event.genre = this.eventType;
      else this.event.genre = "";
    },
    actChanged() {
      if (this.event.actId) {
        for (const profile of this.profiles) {
          if (profile.id == this.event.actId) {
            this.event.act = profile.name;
            this.event.artists[profile.id] = profile;
          }
        }
        this.saveEvent();
      }
    },
    setHasDiscount(val) {
      console.log("setting has discount", val);
      this.event.hasDiscounts = val;
      this.saveEvent();
    },
    newDiscount() {
      store.dispatch("newDiscount");
    },
    saveDiscount(event) {
      store.dispatch("saveDiscount", event.discount, this.event.id);
    },
    getowners(artist) {
      if (artist.owners) {
        let names = "";
        let x = 0;
        for (const owner in artist.owners) {
          if (x == 1) names += ", ";
          console.log("owner: ", owner);
          names += artist.owners[owner].name;
          x = 1;
        }
        return "Managed by " + names;
      } else return "This artist is not using Gobo yet.";
    },
    clearVenue() {
      store.dispatch("clearVenue");
    }
    // removeArtist() {
    //   this.event.act = null;
    //   this.event.actId = null;
    //   this.event.artists = [];
    //   this.saveEvent();
    // }
  },
  watch: {
    event: {
      handler(newval) {
        // Mark dirties
        if (newval.title) this.dirty.title = true;
        if (this.eventType == "other" && newval.genre) this.dirty.genre = true;
        if (newval.description) this.dirty.description = true;
        if (newval.capacity) this.dirty.capacity = true;
        // Title is required
        if (this.dirty.title) {
          if (!newval.title)
            this.errors.title = "Please provide a title for this event.";
          else this.errors.title = null;
        }
        // Genre is required
        if (this.eventType == "other" && this.dirty.genre) {
          if (!newval.genre)
            this.errors.genre = "Please specify the type of event.";
          else this.errors.genre = null;
        }
        // Description is required
        if (this.dirty.description) {
          if (!newval.description)
            this.errors.description = "Please add an event description.";
          else this.errors.description = null;
        }
        // Capacity is required
        if (this.dirty.capacity) {
          if (!newval.capacity)
            this.errors.capacity =
              "Please enter a total number of tickets for this event greater than 0.";
          else this.errors.capacity = null;
        }
      },
      deep: true
    }
  }
};
</script>
