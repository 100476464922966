var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "control has-icons-left" },
    [
      _c("GmapAutocomplete", {
        staticClass: "input",
        attrs: { fields: _vm.fields },
        on: { place_changed: _vm.setPlace }
      }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-search" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }