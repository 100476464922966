<template>
  <div class="body-wrapper">
    <div class="body-content">
      <h1>
        <span
          class="icon backbutton"
          @click="
            $router.push({
              name: 'eventSummary',
              params: { id: event.id }
            })
          "
        >
          <i class="fas fa-chevron-left"></i>
        </span>
        {{ event.title }}
      </h1>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h3>{{ dateSummary.startDate }}</h3>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" @click="exportCsv">Export CSV</button>
          </div>
        </div>
      </div>
      <table id="eventTable" class="table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>email</th>
            <th>Quantity</th>
            <th>Checkedin</th>
            <th>Price Tier</th>
            <th>Discount Code</th>
            <th class="has-text-right">Price Each</th>
            <th class="has-text-right">Gross Total</th>
            <th class="has-text-right">Net After Fee</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="patron in audience" :key="patron.orderId">
            <td>{{ patron.firstName }}</td>
            <td>{{ patron.lastName }}</td>
            <td>{{ patron.email }}</td>
            <td>{{ patron.quantity }}</td>
            <td>{{ patron.checkedin }}</td>
            <td>{{ patron.tierName }}</td>
            <td>{{ patron.discountCode }}</td>
            <td class="has-text-right">${{ patron.price }}</td>
            <td class="has-text-right">${{ patron.total }}</td>
            <td class="has-text-right">${{ getNet(patron.total) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>{{ totalQty }}</th>
            <th>{{ totalCheckedin }}</th>
            <th colspan="3"></th>
            <th class="has-text-right">${{ totalDollars }}</th>
            <th class="has-text-right">${{ totalNet }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  props: ["eventId", "id"],
  computed: {
    ...mapGetters({
      dateSummary: "dateSummary",
      audience: "audience",
      event: "event"
    }),
    totalQty() {
      if (this.audience.length > 0)
        return this.audience
          .map(patron => patron.quantity)
          .reduce((prev, next) => prev + next);
      else return 0;
    },
    totalCheckedin() {
      if (this.audience.length > 0)
        return this.audience
          .map(patron => patron.checkedin)
          .reduce((prev, next) => prev + next);
      else return 0;
    },
    totalDollars() {
      if (this.audience.length > 0)
        return this.audience
          .map(patron => patron.total)
          .reduce((prev, next) => prev + next);
      else return 0;
    },
    totalNet() {
      if (this.audience.length > 0)
        return (
          this.audience
            .map(patron => patron.total)
            .reduce((prev, next) => prev + next) * 0.95
        ).toFixed(2);
      else return 0;
    }
  },
  methods: {
    exportCsv() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "First Name,Last Name,Email,OrderID,Status,Quantity,Checked In,Tier Name,Discount Code,Price,Total,Total After Fees\r\n";
      this.audience.forEach(function(line) {
        const net = (Math.round(line.total * 95) / 100).toFixed(2);
        csvContent += line.firstName + "," + line.lastName + "," + line.email + ","+ line.orderId + "," + line.status + "," + line.quantity + ","+ line.checkedin + "," 
          + line.tierName + "," + line.discountCode + "," + line.price + "," + line.total + "," + net + "\r\n";
      });
      // console.log(csvContent);
      const exportname = this.event.title.replace(/ /g,"") + this.dateSummary.startDate;
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", exportname + ".csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    getNet(ammount) {
      return (Math.round(ammount * 95) / 100).toFixed(2);
    }
  },
  async created() {
    console.log("Get event summarys.", {
      eventId: this.eventId,
      dateId: this.id
    });
    await store.dispatch("fetchEvent", this.eventId);
    await store.dispatch("fetchDateSummary", {
      eventId: this.eventId,
      dateId: this.id
    });
    await store.dispatch("fetchAudience", {
      eventId: this.eventId,
      dateId: this.id
    });
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
</style>
