<template>
  <div class="body-wrapper">
    <div class="body-content" v-if="event">
      <h1>{{ event.title }}</h1>
      <div v-if="analytics">
        <h3 class="chartlabel">Traffic</h3>
        <LineChart
          :chart-data="chartData"
          :height="chartHeight"
          :chart-options="chartOptions"
        />
        <div class="chartspacer"></div>
        <h3 class="chartlabel">Sources</h3>
        <LineChart
          :chart-data="sourcesByDay"
          :height="chartHeight"
          :chart-options="chartOptions"
        />
        <div class="chartspacer"></div>
        <div class="columns">
          <div class="column">
            <h3>Visits by Device</h3>
            <Doughnut
              :chart-data="deviceData"
              :height="lowerChartHeight"
              :width="lowerChartHeight"
            />
          </div>
          <div class="column">
            <h3>Referrals by Source</h3>
            <Doughnut
              :chart-data="sourceSummaryData"
              :height="lowerChartHeight"
            />
          </div>
          <div class="column">
            <h3>Social Traffic by Source</h3>
            <Doughnut :chart-data="socialData" :height="lowerChartHeight" />
          </div>
        </div>
      </div>
      <div class="chartspacer"></div>
      <h2>Sales</h2>
      <table id="eventTable">
        <tr>
          <th>Date</th>
          <th>Sold</th>
          <th>Gross</th>
        </tr>
        <tr v-for="summary in dateSummaries" :key="summary.dateId">
          <td>
            <router-link
              class="is-hidden-mobile"
              :to="{
                name: 'willcall',
                params: { eventId: event.id, id: summary.dateId }
              }"
              >{{ moment(summary.start.toDate()).format("MMMM Do h:mm a") }}
            </router-link>
            <router-link
              class="is-hidden-tablet"
              :to="{
                name: 'willcall',
                params: { eventId: event.id, id: summary.dateId }
              }"
              >{{ moment(summary.start.toDate()).format("MMM Do h:mm a") }}
            </router-link>
          </td>
          <td>
            <strong>{{ summary.totalSold }}</strong
            >/{{ summary.capacity }}
          </td>
          <td>${{ summary.salesTotal }}</td>
          <td>
            <ItemMenu>
              <router-link
                class="dropdown-item"
                :to="{
                  name: 'performanceSummary',
                  params: { eventId: event.id, id: summary.dateId }
                }"
                >Sales Report</router-link
              >
              <router-link
                class="dropdown-item"
                :to="{
                  name: 'willcall',
                  params: { eventId: event.id, id: summary.dateId }
                }"
                >Front of House</router-link
              >
            </ItemMenu>
          </td>
        </tr>
      </table>

      <div v-if="searchOrders || (visibleOrders && visibleOrders.length > 0)" style="margin-top: 50px;">
        <h2>Orders</h2>
        <div style="margin-bottom: 20px; display: flex">
          <div style="flex:1; margin-right: 20px;" class="control has-icons-left">
            <span class="icon is-small is-right">
              <i class="fas fa-search"></i>
            </span>
            <input
              class="input has-icons-left"
              type="text"
              v-model="orderSearch"
              v-on:keyup="searchOrders"
              placeholder="Search by name or email"
            />
          </div>
          <div><button class="button" @click="exportCsv">Export CSV</button></div>
        </div>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Order Created</th>
              <th>Name</th>
              <th>Email</th>
              <th>Discount Code</th>
              <th>Qty</th>
              <th>Donation</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in visibleOrders" :key="order.id">
              <td>{{ (order.created) ? moment(order.created.toDate()).format("M/D/YY LT") : "" }}</td>
              <td>{{ order.firstName }} {{ order.lastName }}</td>
              <td>{{ order.email }}</td>
              <td>{{ order.discountCode }}</td>
              <td>{{ order.quantity }}</td>
              <td>{{ order.donation }}</td>
              <td>${{ order.total.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
        <Paginator @pageChanged="setOrdersPage" :pageSize="10" :itemCount="(filteredOrders.length > 0) ? filteredOrders.length : orders.length" />
      </div>

      <div class="waitlist" v-if="waitlist && waitlist.length > 0">
        <h2>Waitlist</h2>
        <table class="table is-fullwidth">
          <tr>
            <th>#</th>
            <th>Email Address</th>
          </tr>
          <tr v-for="waitperson in waitlist" :key="waitperson.position">
            <td>{{ waitperson.position }}</td>
            <td>{{ waitperson.email }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import moment from "moment";
import ItemMenu from "@/components/itemMenu";
import Paginator from "@/components/paginator";
import { Line as LineChart } from "vue-chartjs/legacy";
import { Doughnut } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  ArcElement
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  ArcElement
);

ChartJS.defaults.font.family = "'Rubik', sans-serif";

export default {
  props: ["id"],
  data: function() {
    return {
      moment: moment,
      orderPage: 1,
      orderSearch: "",
      filteredOrders: [],
      chartDays: [],
      chartColors: ["#531753", "#666C86", "#F0C15C", "#22A588"],
      stringDays: [],
      visitorsByDay: [],
      sourcesByDay: { labels: [], datasets: [] },
      purchasesByDay: [],
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            suggestedMin: 0,
            suggestedMax: 10,
            ticks: {
              stepSize: 1
            }
          }
        }
      },
      chartHeight: 75,
      lowerChartHeight: 300
    };
  },
  computed: {
    ...mapGetters({
      eventSummary: "eventSummary",
      event: "event",
      dateSummaries: "dateSummaries",
      analytics: "analytics",
      waitlist: "waitlist",
      orders: "orders",
    }),
    deviceData() {
      return {
        labels: ["Desktop", "Mobile", "Tablet"],
        datasets: [
          {
            backgroundColor: ["#531753", "#666C86", "#F0C15C"],
            data: [
              this.analytics.visitsByDevice.Desktop,
              this.analytics.visitsByDevice.Mobile,
              this.analytics.visitsByDevice.Tablet
            ]
          }
        ]
      };
    },
    sourceSummaryData() {
      let data = {
        labels: [],
        datasets: [{ backgroundColor: [], data: [] }]
      };
      let x = 0;
      for (const prop in this.analytics.referralsBySource) {
        data.labels.push(prop);
        data.datasets[0].backgroundColor.push(this.chartColors[x]);
        x++;
        data.datasets[0].data.push(
          this.analytics.referralsBySource[prop].visits
        );
      }
      return data;
    },
    chartData() {
      return {
        labels: this.chartDays,
        datasets: [
          {
            label: "Unique Visitors",
            borderColor: "#531753",
            backgroundColor: "#531753",
            data: this.visitorsByDay
          },
          {
            label: "Purchases Made",
            borderColor: "#22A588",
            backgroundColor: "#22A588",
            data: this.purchasesByDay
          }
        ]
      };
    },
    sourceData() {
      const labels = ["#CCEDE5", "#3b5998", "#22A588", "#22A588"];
      var data = {
        labels: this.chartDays,
        datasets: [
          { label: "All traffic", data: this.analytics.trafficByDay.visitors }
        ]
      };
      var x = 0;
      for (const prop in this.analytics.dailyTraffic) {
        data.datasets.push({
          label: prop,
          borderColor: labels[x],
          data: this.analytics.dailyTraffic[prop]
        });
        x++;
      }
      return data;
    },
    socialData() {
      let data = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      };
      let x = 0;
      for (const prop in this.analytics.socialBySource) {
        data.datasets[0].backgroundColor.push(this.chartColors[x]);
        x++;
        data.labels.push(prop);
        data.datasets[0].data.push(this.analytics.socialBySource[prop].visits);
      }
      return data;
    },
    visibleOrders() {
      const showorders = (this.orderSearch != "") ? this.filteredOrders : this.orders;
      const begin = (this.orderPage - 1) * 10;
      // console.log(begin);
      const lastorder = ((begin + 10) < (showorders.length - 1)) ? begin + 10 : showorders.length;
      console.log("slicing", begin, lastorder);
      return showorders.slice(begin, lastorder);
    }
  },
  components: {
    ItemMenu,
    LineChart,
    Doughnut,
    Paginator
  },
  methods: {
    setOrdersPage(page) {
      // console.log("pageset", page);
      this.orderPage = page;
    },
    exportCsv() {
      console.log("exporrting");
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Order Created,Order ID,First Name,Last Name,Email,Quantity,Discount Code,Donation,Subtotal,Total,Total After Fees,Payment Intent ID\r\n";
      this.orders.forEach(function(line) {
        const net = (Math.round(line.total * 95) / 100).toFixed(2);
        csvContent += line.created.toDate() + "," + line.orderId + "," + line.firstName + "," + line.lastName + "," + line.email + ","+ line.quantity + ","
         + line.discountCode + "," + line.donation + ","  + line.subtotal + "," + line.total + "," + net + "," + line.paymentIntent + "\r\n";
      });
      // console.log(csvContent);
      const exportname = this.event.title.replace(/ /g,"") + "_orders";
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", exportname + ".csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    searchOrders() {
      console.log("find orders", this.orderSearch);
      if (!this.orderSearch) this.filteredOrders = [];
      else {
        var foundorders = this.orders.filter(order => {
          if (
            order.email &&
            (order.email + "")
              .toUpperCase()
              .indexOf(this.orderSearch.toUpperCase()) > -1
          )
            return true;
          if (
            order.firstName &&
            (order.firstName + "")
              .toUpperCase()
              .indexOf(this.orderSearch.toUpperCase()) > -1
          )
            return true;
          if (
            order.lastName &&
            (order.lastName + "")
              .toUpperCase()
              .indexOf(this.orderSearch.toUpperCase()) > -1
          )
          return true;
          return false;
          //   string email = pass.email ? pass.email.toUpperCase : "";
          //   const first = pass.firstName ? pass.firstName.toUpperCase : "";
          //   const last = pass.lastName ? pass.lastName.toUpperCase : "";
          //   return (
          //     email.indexOf(this.passSearch.toUpperCase()) > -1 ||
          //     first.indexOf(this.passSearch.toUpperCase()) > -1 ||
          //     last.toUpperCase().indexOf(this.passSearch.toUpperCase()) > -1
          //   );
        });
        this.filteredOrders = foundorders;
      }
    },
    // getOrderDate(date) {
    //   new Timestamp(date.seconds, date.nanoseconds).toDate().toLocaleString();
    // }
  },
  async created() {
    console.log("Get event summarys.");
    await store.dispatch("fetchEventSummary", this.id);
    await store.dispatch("fetchEvent", this.id);
    await store.dispatch("fetchEventWaitlist", this.id);
    await store.dispatch("fetchDateSummaries", this.id);
    await store.dispatch("fetchEventAnalytics", this.id);
    await store.dispatch("getOrders", this.id);
    if (this.event.status == "soldout") {
      console.log("soldout");
    }
    if (this.analytics) {
      console.log("start date ", this.analytics.published.toDate());
      var end = this.analytics.closes.toDate();
      for (
        var d = this.analytics.published.toDate();
        d <= end;
        d.setDate(d.getDate() + 1)
      ) {
        this.chartDays.push(new Date(d).getDate());
        let stringday = new moment(d).format("YYYYMMDD");
        this.stringDays.push(stringday);
        // Daily analytics
        if (
          Object.prototype.hasOwnProperty.call(
            this.analytics.dailyCounts,
            stringday
          )
        ) {
          this.visitorsByDay.push(
            this.analytics.dailyCounts[stringday].visitors == null
              ? 0
              : this.analytics.dailyCounts[stringday].visitors
          );
          this.purchasesByDay.push(
            this.analytics.dailyCounts[stringday].purchases == null
              ? 0
              : this.analytics.dailyCounts[stringday].purchases
          );
        } else {
          if (stringday <= new moment().format("YYYYMMDD"))
            this.visitorsByDay.push(0);
          if (stringday <= new moment().format("YYYYMMDD"))
            this.purchasesByDay.push(0);
        }
      }
      // Daily traffic by source
      if (this.analytics.dailyTraffic) {
        // First, setup the labels
        let colorCount = 0;
        this.sourcesByDay.labels = this.chartDays;
        //this.sourcesByDay.labels = ["All traffic"];
        for (const source in this.analytics.dailyTraffic) {
          colorCount++;
          if (colorCount >= this.chartColors.length) colorCount = 0;
          const dataset = {
            label: source,
            borderColor: this.chartColors[colorCount],
            backgroundColor: this.chartColors[colorCount],
            data: []
          };
          for (let y = 0; y < this.stringDays.length; y++) {
            if (
              this.analytics.dailyTraffic[source][this.stringDays[y]] &&
              this.analytics.dailyTraffic[source][this.stringDays[y]].visitors
            )
              dataset.data.push(
                this.analytics.dailyTraffic[source][this.stringDays[y]].visitors
              );
            else if (this.stringDays[y] <= new moment().format("YYYYMMDD"))
              dataset.data.push(0);
          }
          //if this.analytics.dailyTraffic[source].visitors
          this.sourcesByDay.datasets.push(dataset);
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.chartlabel {
  margin-bottom: 0;
}
.chartspacer {
  height: 30px;
}
.waitlist {
  margin-top: 50px;
}
</style>
