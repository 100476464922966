var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "venue-map" },
    [
      _c(
        "gmap-map",
        {
          staticStyle: { width: "100%", height: "300px" },
          attrs: {
            center: _vm.venue.location,
            zoom: 17,
            mapTypeControl: "false",
            options: _vm.options
          }
        },
        [
          _c("gmap-marker", {
            attrs: {
              position: _vm.venue.location,
              clickable: true,
              draggable: true,
              icon: "/img/icons/icon-pin.svg"
            },
            on: {
              click: function($event) {
                _vm.center = _vm.venue.location
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "map-legend" }, [
        _c("h3", [_vm._v(_vm._s(_vm.venue.name))]),
        _c("p", [_vm._v(_vm._s(_vm.venue.fullAddress))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }