<template>
  <div>
    <div>
      <input
        class="input has-icons-left"
        type="text"
        v-model="artistName"
        v-on:keyup="searchArtists"
        placeholder="Artist Name"
        @blur="addArtist"
      />
    </div>
    <div class="results" v-if="foundArtists && (foundArtists.length > 0 || artistName)">
      <div
        v-for="artist in foundArtists"
        :key="artist.id"
        class="columns selectable"
        @click="setArtist(artist)"
      >
        <div class="column is-narrow">
          <div class="avatar-generic">
            <img :src="artist.image" alt="" />
          </div>
        </div>
        <div class="column">
          <p class="artistname">
            <strong>{{ artist.name }}</strong>
          </p>
        </div>
        <div class="column">
          <p class="managedby">
            {{ getowners(artist) }}
          </p>
        </div>
      </div>
      <div
        v-if="artistName != ''"
        class="columns selectable"
        @click="addArtist"
      >
        <div class="column is-narrow">
          <div class="avatar-generic"></div>
        </div>
        <div class="column">
          <p class="artistname">
            <strong>{{ artistName }}</strong>
          </p>
        </div>
        <div class="column">
          <p class="managedby">New Artist Profile</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "addArtist",
  props: ["id"],
  data() {
    return {
      artistName: ""
    };
  },
  computed: {
    ...mapGetters({
      foundArtists: "foundArtists"
    }),
  },
  methods: {
    async addArtist() {
      const artist = await store.dispatch("createArtistProfile", {
        name: this.artistName
      });
      console.log("artie", artist);
      this.artistName = "";
      store.dispatch("clearFoundArtists");
      this.$emit("artistAdded", artist);
    },
    setArtist(artist) {
      this.artistName = "";
      store.dispatch("clearFoundArtists");
      this.$emit("artistAdded", artist);
    },
    searchArtists() {
      store.dispatch("findArtists", this.artistName);
    },
    getowners(artist) {
      if (artist.owners) {
        let names = "";
        let x = 0;
        for (const owner in artist.owners) {
          if (x == 1) names += ", ";
          console.log("owner: ", owner);
          names += artist.owners[owner].name;
          x = 1;
        }
        return "Managed by " + names;
      } else return "This artist is not using Gobo yet.";
    },
  }
};
</script>
