<template>
    <nav class="pagination is-centered" role="navigation" aria-label="pagination">
      <a class="pagination-previous" :class="{'is-disabled': page == 1}" @click="setPage(page - 1)">Previous</a>
      <a class="pagination-next" @click="setPage(page + 1)">Next</a>
      <ul class="pagination-list">
        <li><span v-if="page > 4" class="pagination-ellipsis">&hellip;</span></li>
        <li v-for="n in pages" :key="n">
          <a class="pagination-link" :class="{'is-current': getPage(n) == page}" aria-label="Goto page 1" @click="setPage(getPage(n))">{{ getPage(n) }}</a>
        </li>
        <li><span class="pagination-ellipsis">&hellip;</span></li>
      </ul>
    </nav>
</template>
<script>
export default {
  data: function() {
    return {
      page: 1
    };
  },
  props: {
    itemCount: Number,
    pageSize: Number
  },
  computed: {
    pages: function() {
        return Math.ceil(this.itemCount/this.pageSize);
    }
  },
  methods: {
    getPage(rank) {
        if (this.page < 5 || this.pages < 6) return rank;
        else return rank - 4 + this.page;
    },
    setPage(pageno) {
        this.page = pageno;
        this.$emit("pageChanged", pageno);
    }
  }
};
</script>