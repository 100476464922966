var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.festivalEvent
    ? _c("div", { staticClass: "body-wrapper" }, [
        _c("div", { staticClass: "body-content gray" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-one-quarter" }, [
                _vm.user.eventPublished
                  ? _c("h1", [
                      _vm._v(" Create "),
                      _c("br"),
                      _vm._v(" a festival "),
                      _c("br"),
                      _vm._v(" event! ")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "box is-hidden-mobile" }, [
                  _c("div", { staticClass: "block" }, [
                    _c("aside", { staticClass: "menu" }, [
                      _c("p", { staticClass: "menu-label" }, [
                        _vm._v(" Event Set Up ")
                      ]),
                      _c("ul", { staticClass: "menu-list" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 1 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(1)
                                }
                              }
                            },
                            [_vm._v("Basic Info")]
                          )
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 2 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(2)
                                }
                              }
                            },
                            [_vm._v("Dates and Times")]
                          )
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 5 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(5)
                                }
                              }
                            },
                            [_vm._v("Discount Codes")]
                          )
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { "is-active": _vm.step == 7 },
                              on: {
                                click: function($event) {
                                  return _vm.setStep(7)
                                }
                              }
                            },
                            [_vm._v("Preview and Publish")]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 1,
                      expression: "step == 1"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Basic Info")]),
                  _c("div", { staticClass: "box" }, [
                    _c(
                      "div",
                      { ref: "croppiecont", staticClass: "field" },
                      [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Event Image")
                        ]),
                        _vm.festivalEvent.image
                          ? _c("img", {
                              staticClass: "eventimageheader",
                              attrs: { src: _vm.festivalEvent.image, alt: "" }
                            })
                          : _vm._e(),
                        _vm.festivalEvent.image &&
                        _vm.festivalEvent.status != "complete"
                          ? _c(
                              "button",
                              {
                                staticClass: "button",
                                on: {
                                  click: function($event) {
                                    _vm.festivalEvent.image = null
                                  }
                                }
                              },
                              [_vm._v(" Use a Different Image ")]
                            )
                          : _vm._e(),
                        !_vm.festivalEvent.image &&
                        !_vm.cropping &&
                        !_vm.showImageLoading
                          ? _c("UploadImage", {
                              attrs: { outputWidth: 800, outputHeight: 360 },
                              on: { imageCropped: _vm.crop }
                            })
                          : _vm._e(),
                        false
                          ? _c(
                              "div",
                              {
                                staticClass: "dropzone",
                                on: {
                                  dragover: _vm.dragover,
                                  dragleave: _vm.dragleave,
                                  drop: _vm.croppie
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "columns is-vcentered" },
                                  [
                                    _vm._m(0),
                                    _vm._m(1),
                                    _c(
                                      "div",
                                      { staticClass: "column is-one-quarter" },
                                      [
                                        _c("input", {
                                          staticClass: "inputfile",
                                          attrs: {
                                            type: "file",
                                            name: "file",
                                            id: "file",
                                            accept: "image/x-png,image/jpeg"
                                          },
                                          on: { change: _vm.croppie }
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "button upload",
                                            attrs: { for: "file" }
                                          },
                                          [_vm._v(" Upload Image ")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.cropping
                          ? _c(
                              "div",
                              { staticClass: "croppiecont" },
                              [
                                _c("vue-croppie", {
                                  ref: "croppieRef",
                                  attrs: {
                                    enableOrientation: true,
                                    enableResize: false,
                                    boundary: {
                                      width: _vm.croppieWidth,
                                      height: _vm.croppieHeight
                                    },
                                    viewport: {
                                      width: _vm.cropWidth,
                                      height: _vm.cropHeight,
                                      type: "square"
                                    }
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "buttons is-centered" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "button is-flex-mobile",
                                        on: { click: _vm.cancel }
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "button is-primary is-flex-mobile",
                                        on: { click: _vm.crop }
                                      },
                                      [_vm._v(" Save Image ")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showImageLoading
                          ? _c("div", [
                              _c(
                                "progress",
                                {
                                  staticClass: "progress is-primary",
                                  attrs: { max: "100" },
                                  domProps: { value: _vm.percentComplete }
                                },
                                [_vm._v(_vm._s(_vm.percentComplete))]
                              ),
                              _c("p", [_vm._v("Uploading image...")])
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column" }, [
                        _c("div", { staticClass: "field" }, [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Event Title")
                          ]),
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.lazy",
                                  value: _vm.festivalEvent.title,
                                  expression: "festivalEvent.title",
                                  modifiers: { lazy: true }
                                }
                              ],
                              staticClass: "input is-primary",
                              class: { "is-danger": _vm.errors.title },
                              attrs: {
                                type: "text",
                                placeholder: "Event Title",
                                disabled: _vm.festivalEvent.status == "complete"
                              },
                              domProps: { value: _vm.festivalEvent.title },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.festivalEvent,
                                      "title",
                                      $event.target.value
                                    )
                                  },
                                  function($event) {
                                    return _vm.saveEvent()
                                  }
                                ]
                              }
                            })
                          ]),
                          _vm.errors.title
                            ? _c("p", { staticClass: "help is-danger" }, [
                                _vm._v(" " + _vm._s(_vm.errors.title) + " ")
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "field" }, [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Event Type")
                          ]),
                          _c("div", { staticClass: "select" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.festivalEvent.genre,
                                    expression: "festivalEvent.genre"
                                  }
                                ],
                                attrs: {
                                  disabled:
                                    _vm.festivalEvent.status == "complete"
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.festivalEvent,
                                        "genre",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function($event) {
                                      return _vm.eventTypeChange()
                                    }
                                  ]
                                }
                              },
                              [
                                _c("option", { attrs: { value: "music" } }, [
                                  _vm._v("Music")
                                ]),
                                _c("option", { attrs: { value: "theater" } }, [
                                  _vm._v("Theater")
                                ]),
                                _c("option", { attrs: { value: "dance" } }, [
                                  _vm._v("Dance")
                                ]),
                                _c("option", { attrs: { value: "improv" } }, [
                                  _vm._v("Improv")
                                ]),
                                _c(
                                  "option",
                                  { attrs: { value: "storytelling" } },
                                  [_vm._v("Storytelling")]
                                ),
                                _c("option", { attrs: { value: "standup" } }, [
                                  _vm._v("Standup Comedy")
                                ]),
                                _c(
                                  "option",
                                  { attrs: { value: "conferences" } },
                                  [_vm._v("Conferences/Exhibitions")]
                                ),
                                _c("option", { attrs: { value: "opera" } }, [
                                  _vm._v("Opera")
                                ]),
                                _c(
                                  "option",
                                  { attrs: { value: "music theater" } },
                                  [_vm._v("Music Theater")]
                                ),
                                _c("option", { attrs: { value: "art" } }, [
                                  _vm._v("Visual Art")
                                ]),
                                _c(
                                  "option",
                                  { attrs: { value: "gatherings" } },
                                  [_vm._v("Social Gatherings")]
                                ),
                                _c("option", { attrs: { value: "sports" } }, [
                                  _vm._v("Sports")
                                ]),
                                _c("option", { attrs: { value: "children" } }, [
                                  _vm._v("Children's Programs")
                                ]),
                                _c("option", { attrs: { value: "other" } }, [
                                  _vm._v("Other")
                                ])
                              ]
                            )
                          ]),
                          false
                            ? _c("p", { staticClass: "help is-danger" }, [
                                _vm._v(" Please enter a name. ")
                              ])
                            : _vm._e()
                        ]),
                        _vm.eventType == "other"
                          ? _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.lazy",
                                      value: _vm.festivalEvent.genre,
                                      expression: "festivalEvent.genre",
                                      modifiers: { lazy: true }
                                    }
                                  ],
                                  staticClass: "input is-primary",
                                  class: { "is-danger": _vm.errors.genre },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Event Type",
                                    disabled:
                                      _vm.festivalEvent.status == "complete"
                                  },
                                  domProps: { value: _vm.festivalEvent.genre },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.festivalEvent,
                                        "genre",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm.errors.genre
                                ? _c("p", { staticClass: "help is-danger" }, [
                                    _vm._v(" " + _vm._s(_vm.errors.genre) + " ")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Artist")
                        ]),
                        _vm._l(_vm.festivalEvent.artists, function(artist) {
                          return _c("div", { key: artist.id }, [
                            _c("div", { staticClass: "columns" }, [
                              _c("div", { staticClass: "column is-narrow" }, [
                                _c("div", { staticClass: "avatar-generic" }, [
                                  _c("img", {
                                    attrs: { src: artist.image, alt: "" }
                                  })
                                ])
                              ]),
                              _c("div", { staticClass: "column" }, [
                                _c("p", { staticClass: "artistname" }, [
                                  _c("strong", [_vm._v(_vm._s(artist.name))]),
                                  _vm._v("  |  " + _vm._s(artist.type) + " ")
                                ])
                              ]),
                              _c("div", { staticClass: "column" }, [
                                _c("p", { staticClass: "managedby" }, [
                                  _vm._v(_vm._s(_vm.getowners(artist)))
                                ])
                              ]),
                              _c("div", { staticClass: "column is-narrow" }, [
                                _vm.festivalEvent.status != "published"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "icon is-medium delete",
                                        on: { click: _vm.removeArtist }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-lg fa-trash"
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.festivalEvent.status == "published"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "icon is-medium info",
                                        on: { click: _vm.removeArtist }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-lg fa-info-circle"
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            !artist.owners
                              ? _c(
                                  "div",
                                  [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(artist.name) +
                                          " is not using Gobo yet. Invite them to help sell tickets with you. "
                                      )
                                    ]),
                                    _c("InviteUserStarter", {
                                      attrs: {
                                        inviterName: _vm.user.name,
                                        inviterId: _vm.user.uid,
                                        profileName: artist.name,
                                        actId: artist.id,
                                        newProfile: true,
                                        placeholderText: "Artist Email Address"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        }),
                        false
                          ? _c("div", { staticClass: "buttons" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  attrs: {
                                    disabled:
                                      _vm.profiles &&
                                      _vm.profiles[0] &&
                                      !_vm.profiles[_vm.profiles.length - 1].id
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addFestival()
                                    }
                                  }
                                },
                                [_vm._m(2), _c("span", [_vm._v("Add Artist")])]
                              )
                            ])
                          : _vm._e(),
                        !_vm.festivalEvent.actId
                          ? _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.artistName,
                                    expression: "artistName"
                                  }
                                ],
                                staticClass: "input has-icons-left",
                                attrs: {
                                  type: "text",
                                  placeholder: "Artist Name"
                                },
                                domProps: { value: _vm.artistName },
                                on: {
                                  keyup: _vm.searchArtists,
                                  blur: _vm.addArtist,
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.artistName = $event.target.value
                                  }
                                }
                              })
                            ])
                          : _vm._e(),
                        !_vm.festivalEvent.actId
                          ? _c(
                              "div",
                              { staticClass: "results" },
                              [
                                _vm._l(_vm.foundArtists, function(artist) {
                                  return _c(
                                    "div",
                                    {
                                      key: artist.id,
                                      staticClass: "columns selectable",
                                      on: {
                                        click: function($event) {
                                          return _vm.setArtist(artist)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "column is-narrow" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "avatar-generic" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: artist.image,
                                                  alt: ""
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("div", { staticClass: "column" }, [
                                        _c("p", { staticClass: "artistname" }, [
                                          _c("strong", [
                                            _vm._v(_vm._s(artist.name))
                                          ])
                                        ])
                                      ]),
                                      _c("div", { staticClass: "column" }, [
                                        _c("p", { staticClass: "managedby" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.getowners(artist)) +
                                              " "
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                }),
                                _vm.artistName != ""
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "columns selectable",
                                        on: { click: _vm.addArtist }
                                      },
                                      [
                                        _vm._m(3),
                                        _c("div", { staticClass: "column" }, [
                                          _c(
                                            "p",
                                            { staticClass: "artistname" },
                                            [
                                              _c("strong", [
                                                _vm._v(_vm._s(_vm.artistName))
                                              ])
                                            ]
                                          )
                                        ]),
                                        _vm._m(4)
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      2
                    ),
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Event Description")
                      ]),
                      _c("label", { staticClass: "label" }, [
                        _vm._v(
                          "Add details like what to expect, list of performers or sponsors."
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("quill-editor", {
                            ref: "myQuillEditor",
                            class: { "ql-focus": _vm.editorHasFocus },
                            attrs: {
                              content: _vm.descriptionContent,
                              options: _vm.editorOption
                            },
                            on: {
                              blur: function($event) {
                                _vm.editorHasFocus = false
                              },
                              focus: function($event) {
                                _vm.editorHasFocus = true
                              },
                              change: function($event) {
                                return _vm.saveEventDescription($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.errors.description
                        ? _c("p", { staticClass: "help is-danger" }, [
                            _vm._v(" " + _vm._s(_vm.errors.description) + " ")
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 2,
                      expression: "step == 2"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Dates + Times")]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _vm._l(_vm.eventDates, function(date) {
                        return _c("EventDate", {
                          key: date.id,
                          attrs: {
                            date: date,
                            editing: true,
                            disable:
                              _vm.festivalEvent.status == "complete" ||
                              _vm.festivalEvent.status == "published"
                          },
                          on: {
                            dateSet: _vm.updateDate,
                            deleted: _vm.deleteDate
                          }
                        })
                      }),
                      _c("p", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { disabled: _vm.newDate },
                            on: { click: _vm.newEvent }
                          },
                          [_vm._m(5), _c("span", [_vm._v("Add date")])]
                        )
                      ])
                    ],
                    2
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 5,
                      expression: "step == 5"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Discount Codes")]),
                  _c("DiscountCodes", {
                    attrs: {
                      discounts: _vm.discounts,
                      dates: _vm.eventDates,
                      disabled: _vm.festivalEvent.status == "complete",
                      eventId: _vm.festivalEvent.id
                    },
                    on: {
                      hasDiscountsChange: _vm.setHasDiscount,
                      newDiscount: _vm.newDiscount,
                      codeChanged: _vm.saveDiscount
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 7,
                      expression: "step == 7"
                    }
                  ],
                  staticClass: "column"
                },
                [
                  _c("h4", [_vm._v("Preview + Publish")]),
                  _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("div", { staticClass: "event-preview" }, [
                          _c("div", { staticClass: "event-preview-header" }, [
                            _vm.festivalEvent.image
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.festivalEvent.image,
                                    alt: ""
                                  }
                                })
                              : _vm._e()
                          ]),
                          _c(
                            "div",
                            { staticClass: "event-preview-body" },
                            [
                              _c("EventDateline", {
                                attrs: { event: _vm.festivalEvent }
                              }),
                              _c("h3", [
                                _vm._v(_vm._s(_vm.festivalEvent.title))
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "button is-fullwidth",
                                  on: {
                                    click: function($event) {
                                      return _vm.preview()
                                    }
                                  }
                                },
                                [_vm._v(" Preview Event ")]
                              )
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "column is-half" }, [
                        _c("div", { staticClass: "control" }, [
                          _c("label", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.festivalEvent.isPublic,
                                  expression: "festivalEvent.isPublic"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "answer",
                                value: "true"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.festivalEvent.isPublic,
                                  "true"
                                )
                              },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.festivalEvent,
                                      "isPublic",
                                      "true"
                                    )
                                  },
                                  function($event) {
                                    return _vm.saveEvent()
                                  }
                                ]
                              }
                            }),
                            _vm._v(" Public ")
                          ]),
                          _c("p", { staticClass: "radio-label" }, [
                            _vm._v("Shared on Gobo and searchable")
                          ]),
                          _c("label", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.festivalEvent.isPublic,
                                  expression: "festivalEvent.isPublic"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "answer",
                                value: "false"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.festivalEvent.isPublic,
                                  "false"
                                )
                              },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.festivalEvent,
                                      "isPublic",
                                      "false"
                                    )
                                  },
                                  function($event) {
                                    return _vm.saveEvent()
                                  }
                                ]
                              }
                            }),
                            _vm._v(" Private ")
                          ]),
                          _c("p", { staticClass: "radio-label" }, [
                            _vm._v(
                              " Only available to you and the people you share it with. "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm.cantPublish && _vm.festivalEvent.status == "draft"
                    ? _c("div", { staticClass: "box" }, [
                        _c("h4", [_vm._v("Before you publish")]),
                        _c("p", [
                          _vm._v(
                            " There's a few things you need to do before you can publish your event. "
                          )
                        ]),
                        _c("ul", [
                          !_vm.festivalEvent.title
                            ? _c("li", [
                                _vm._v(" Please give your event a title ")
                              ])
                            : _vm._e(),
                          !_vm.festivalEvent.genre
                            ? _c("li", [_vm._v("Set an event type")])
                            : _vm._e(),
                          !_vm.festivalEvent.act
                            ? _c("li", [_vm._v("Specify an act")])
                            : _vm._e(),
                          !_vm.festivalEvent.description
                            ? _c("li", [
                                _vm._v(" Give your event a description ")
                              ])
                            : _vm._e(),
                          !_vm.festivalEvent.image
                            ? _c("li", [_vm._v("Add an event image")])
                            : _vm._e(),
                          !_vm.eventDates ||
                          _vm.eventDates.length < 1 ||
                          (_vm.eventDates.length == 1 && !_vm.eventDates[0].id)
                            ? _c("li", [_vm._v(" Add at least one date ")])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c("div", {
                staticClass:
                  "column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "box stepbar" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }, [
                _c("div", { staticClass: "buttons is-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: { click: _vm.returnToFestival }
                    },
                    [_vm._v(" Return to Festival ")]
                  ),
                  _vm.step > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              _vm.step = _vm.step - 1
                            }
                          }
                        },
                        [_vm._v(" Back ")]
                      )
                    : _vm._e(),
                  _vm.step < 6
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          attrs: { disabled: _vm.cropping },
                          on: {
                            click: function($event) {
                              return _vm.next()
                            }
                          }
                        },
                        [_vm._v(" Next ")]
                      )
                    : _vm._e(),
                  _vm.step == 6
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          on: {
                            click: function($event) {
                              return _vm.next()
                            }
                          }
                        },
                        [_vm._v(" Preview ")]
                      )
                    : _vm._e(),
                  _vm.festivalEvent.status == "draft" && _vm.step == 7
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          attrs: { disabled: _vm.cantPublish },
                          on: {
                            click: function($event) {
                              return _vm.publish()
                            }
                          }
                        },
                        [_vm._v(" Publish ")]
                      )
                    : _vm._e()
                ])
              ]),
              _c("div", {
                staticClass:
                  "column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
              })
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-2 placeholder-img" }, [
      _c("img", { attrs: { src: "/img/icon-image.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column" }, [
      _c("p", [
        _c("strong", [_vm._v("Drag + drop")]),
        _vm._v(" or click "),
        _c("strong", [_vm._v("upload")]),
        _vm._v(" to add an image. ")
      ]),
      _c("p", { staticClass: "subtext" }, [
        _vm._v(
          " Use a high quality image: minimum 800 x 360 px, JPG or PNG, no larger than 10MB. "
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("div", { staticClass: "avatar-generic" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column" }, [
      _c("p", { staticClass: "managedby" }, [_vm._v("New Artist Profile")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-medium" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }