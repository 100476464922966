<template>
  <div>
    <div class="preview-banner">
      <div class="columns is-mobile">
        <div class="column is-one-fifth is-hidden-mobile"></div>
        <div class="column">
          <h3>Event Preview</h3>
          <button class="button is-pulled-right" @click="edit()">
            <span class="icon">
              <i class="fas fa-pen"></i>
            </span>
            <span>Edit Event</span>
          </button>
        </div>
        <div class="column is-one-fifth is-hidden-mobile"></div>
      </div>
    </div>
    <div class="body-wrapper">
      <div class="columns">
        <div class="column is-one-fifth is-hidden-mobile"></div>
        <div class="column maincont">
          <div class="imagewrap">
            <img :src="event.image" class="event-image" />
          </div>
          <div class="calendar">
            <div class="cal-stripe"></div>
            <EventDateBadge
              v-if="event && event.start"
              :date="event.start.toDate()"
            />
          </div>
          <div class="tix-button">
            <button
              class="button is-primary"
              @click="tickets()"
              :disabled="
                salesStatus == 'Sold Out' || salesStatus == 'Sales Closed'
              "
            >
              {{ salesStatus }}
            </button>
          </div>
          <div class="description indent">
            <div class="venue-map" v-if="venue" :class="{ hasMap: !event.mapDisplay || event.mapDisplay != 'hidden' }">
              <gmap-map
                v-if="!event.mapDisplay || event.mapDisplay != 'hidden'"
                :center="location"
                :zoom="event.venueIsAlternative ? 10 : 17"
                mapTypeControl="false"
                style="width: 300px; height: 200px"
                :options="options"
                zoomControl="false"
              >
                <gmap-marker
                  :position="location"
                  :clickable="true"
                  :draggable="true"
                  @click="center = location"
                ></gmap-marker>
              </gmap-map>
              <div class="map-legend">
                <h3>{{ venue.name }}</h3>
                <p v-if="!event.venueIsAlternative">{{ venue.fullAddress }}</p>
                <p v-if="event.venueIsAlternative">{{ venue.city }}</p>
              </div>
            </div>
            <div><EventDateline :event="event" /></div>
            <h1>{{ event.title }}</h1>
            <p v-if="!event.artists" class="act">{{ event.act }}</p>
            <p v-if="!event.artists" class="act">
              <img
                v-if="artist && artist.image"
                :src="artist.image"
                alt="artist.name"
              />
              <span v-if="!artist || !artist.urlslug">{{ event.act }}</span>
              <a v-if="artist && artist.urlslug" :href="'/artist/' + artist.urlslug">{{ artist.name }}</a>
            </p>
            <p class="act" v-for="artist in event.artists" :key="artist.id">
              <img
                v-if="artist && artist.image"
                :src="artist.image"
                alt="artist.name"
              />
              <span v-if="!artist || !artist.urlslug">{{ artist.name }}</span>
              <a
                v-if="artist && artist.urlslug"
                :href="'/artist/' + artist.urlslug"
                >{{ artist.name }}</a
              >
            </p>
            <div v-html="event.description"></div>
          </div>
          <div class="performances indent">
            <h3 v-if="eventDates && eventDates.length > 1">Event Dates & Times</h3>
            <h3 v-if="eventDates && eventDates.length == 1">Event Date & Time</h3>
            <table id="datetable">
              <colgroup>
                <col span="1" />
                <col span="1" />
                <col span="1" style="width: 30%" />
              </colgroup>
              <tr
                v-for="date in eventDates"
                :key="date.id"
                :class="{ pastevent: date.start.toDate() < Date.now() }"
              >
                <td>
                  <span class="is-hidden-mobile">{{
                    formatDate(date.startDate)
                  }}</span
                  ><span class="is-hidden-tablet">{{
                    formatShortDate(date.startDate)
                  }}</span>
                </td>
                <td>{{ formatTime(date.startTime) }}</td>
                <td>
                  <span v-for="tag in date.tags" :key="tag" class="tag is-info">
                    {{ tag }}
                  </span>
                  <span v-if="date.status == 'soldout'" class="soldout"
                    >Sold Out!</span
                  >
                  <span v-if="date.status == 'limited'" class="limited"
                    >Almost Gone!</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="column is-one-fifth"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.columns {
  max-width: 100%;
}
.maincont {
  max-width: 878px;
}
.tix-button {
  float: right;
  margin-top: 25px;
}
.timeline {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.pastevent {
  opacity: 0.6;
}
.performances {
  clear: both;
  margin-bottom: 50px;
}
#datetable td {
  padding: 0 20px;
}
.soldout {
  color: #531753;
  font-weight: bold;
}
.limited {
  color: $active-green;
  font-weight: bold;
}
.social {
  border-top: 2px solid $gray6;
  border-bottom: 2px solid $gray6;
  padding: 25px 0;
  margin: 50px 0 20px;
  display: flex;
  h3 {
    padding-top: 7px;
    margin-bottom: 0;
  }
  .links {
    display: flex;
    justify-content: right;
    flex: 1;
    .link {
      color: $gray1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray6;
      width: 40px;
      height: 40px;
      border-radius: $radius;
      margin-left: 10px;
    }
  }
}
.eventfooter {
  margin-top: 25px;
  p {
    font-size: 14px;
  }
}
// mobile
@media only screen and (max-width: 768px) {
  .body-wrapper {
    margin: 25px 0;
  }
  .event-image {
    border-radius: 0;
    margin: 0;
  }
  .calendar {
    margin-left: 15px;
  }
  .body-wrapper {
    margin: 0;
  }
  .indent {
    margin: 0 15px;
  }
  .venue-map {
    float: none;
    margin: 20px 30px;
  }
  .imagewrap {
    padding: 0 !important;
  }
  .tix-button {
    margin-right: 15px;
  }
  .maincont {
    padding-right: 0;
  }
}

// APP SPECIFIC HERE
.preview-banner {
  background-color: $muted-red;
  margin: 20px 0;
}
.preview-banner h3 {
  font-size: 1.3em;
  line-height: 2em;
  margin: 0 0 0 25px;
  display: inline-block;
}

// mobile
@media screen and (max-width: 768px) {
  .body-wrapper {
    margin: 25px 0;
  }
  .event-image {
    border-radius: 0;
    margin: 0;
  }
  .calendar {
    margin-left: 15px;
  }
  .body-wrapper {
    margin: 0;
  }
  .indent {
    margin: 0 15px;
  }
  .venue-map {
    float: none;
    margin: 20px 30px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import moment from "moment";
import EventDateBadge from "@/components/event-date-badge";
import EventDateline from "@/components/event-dateline";

export default {
  props: ["id"],
  components: {
    EventDateBadge,
    EventDateline
  },
  data() {
    return {
      fields: [
        "place_id",
        "name",
        "formatted_phone_number",
        "website",
        "formatted_address",
        "address_components",
        "geometry"
      ],
      options: {
        disableDefaultUI: true,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: false,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              }
            ]
          }
        ],
        mapId: "a7f1aa6c21b72aae"
      }
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      event: "event",
      eventDates: "eventDates",
      venue: "venue",
      priceTiers: "priceTiers",
      eventDate: "eventDate",
      eventTime: "eventTime",
      eventPrice: "eventPrice"
    }),
    location: function() {
      if (this.event.venueIsAlternative) {
        let newloc = this.venue.location;
        newloc.lat = parseFloat(newloc.lat.toFixed(2));
        newloc.lng = parseFloat(newloc.lng.toFixed(2));
        console.log("boo", newloc);
        return newloc;
      }
      return this.venue.location;
    },
    salesStatus: function() {
      let status = "Purchase Tickets";
      let futureDates = 0;
      let soldoutDates = 0;
      for (let x = 0; x < this.eventDates.length; x++) {
        if (
          this.eventDates[x].status == "soldout" &&
          this.eventDates[x].start &&
          this.eventDates[x].start.toDate() > Date.now()
        )
          soldoutDates++;
        if (
          this.eventDates[x].start &&
          this.eventDates[x].start.toDate() > Date.now()
        )
          futureDates++;
      }
      console.log("future dates", futureDates, Date.now());
      if (soldoutDates >= futureDates) status = "Sold Out";
      if (futureDates == 0) status = "Sales Closed";
      return status;
    }
  },
  async created() {
    console.log("ID ", this.id);
    if (!this.event || this.event.id != this.id)
      await store.dispatch("fetchEvent", this.id);
    if (this.event.venue) await store.dispatch("fetchVenue", this.event.venue);
    await store.dispatch("fetchEventDates");
    await store.dispatch("fetchPriceTiers");
  },
  methods: {
    edit() {
      var route = this.event.seasonId ? "editFestivalEvent" : "editEvent";
      this.$router.push({ name: route, id: this.event.id });
    },
    publish() {
      this.$router.push({ name: "eventPublished" });
    },
    tickets() {
      this.$emit("sellTickets");
    },
    formatDate(date) {
      return moment(date).format("dddd, MMMM Do YYYY");
    },
    formatShortDate(date) {
      return moment(date).format("dddd, MMM Do");
    },
    formatTime(time) {
      if (/.*[AaPp][Mm]/.test(time)) return time;
      else return moment(time, "HH:mm").format("h:mm A");
    }
  }
};
</script>
