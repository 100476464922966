<template>
  <div class="body-wrapper" v-if="season">
    <div class="body-content gray">
      <div class="container">
        <div class="columns is-gapless">
          <div class="column is-1 is-hidden-mobile"></div>
          <div class="column maincont" v-if="season">
            <h1>{{ season.title }}</h1>
            <div class="box">
              <div class="tabs">
                <ul>
                  <li
                    :class="{ 'is-active': tab == 'shows' }"
                    @click="tab = 'shows'"
                  >
                    <a>Shows</a>
                  </li>
                  <li
                    :class="{ 'is-active': tab == 'passes' }"
                    @click="tab = 'passes'"
                  >
                    <a>Pass Sales</a>
                  </li>
                </ul>
              </div>
              <div v-if="tab == 'shows'">
                <h1>My Events</h1>
                <table id="eventTable">
                  <tr class="header is-hidden-mobile">
                    <th>Event Details</th>
                    <th>Sold</th>
                    <th>Gross</th>
                    <th>Status</th>
                  </tr>
                  <EventSummary :summary="event" v-for="event in events" :key="event.id" />
                </table>
              </div>
              <div v-if="tab == 'passes'">
                <h2>Pass Sales</h2>
                <div
                  class="passresults"
                  v-for="passType in season.passes"
                  :key="passType.id"
                >
                  <h3>{{ passType.name }}</h3>
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Uses</th>
                        <th>Paid</th>
                      </tr>
                    </thead>
                    <PassRow
                      v-for="(pass, index) in getPasses(passType.id)"
                      :key="index"
                      :pass="pass"
                      :price="passType.price"
                      :editing="false"
                    />
                    <tfoot>
                      <tr>
                        <th colspan="3">Total</th>
                        <th>{{ getPasses(passType.id).length }}</th>
                        <th>{{ getTotalUses(passType.id) }}</th>
                        <th>
                          ${{ getPasses(passType.id).length * passType.price }}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-1 is-hidden-mobile"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import PassRow from "@/components/festivalSummary/pass-row";
import EventSummary from "@/components/event-summary";

export default {
  name: "FestivalSummary",
  props: ["festivalId", "id"],
  components: { PassRow, EventSummary },
  data() {
    return {
      tab: "shows"
    };
  },
  mounted() {},
  async created() {
    console.log(
      "Fest season ID ",
      this.id,
      this.festivalId,
      this.$route.params
    );
    if (!this.id) {
      if (this.season && this.festivals && this.festivals.length == 1) {
        this.selectFestival(this.festivals[0].id);
        this.step = 1;
      } else console.log("WTF batman");
    } else {
      console.log("Getting the season");
      await store.dispatch("fetchFestivalSeason", {
        festivalId: this.festivalId,
        seasonId: this.id
      });
      await store.dispatch("getFestivalPasses", {
        seasonId: this.id,
        festivalId: this.festivalId
      });
      if (!this.events || this.events.length == 0) await store.dispatch("fetchFestivalEventSummarys", this.id);
      console.log("events", this.events);
      this.seasonDescription = this.season.description;
      if (this.season.venue)
        await store.dispatch("fetchVenue", this.season.venue);
      await store.dispatch("fetchFestivalEvents", this.season.id);
      this.step = 1;
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      season: "season",
      seasonSettings: "seasonSettings",
      festivals: "festivals",
      user: "user",
      venue: "venue",
      events: "festivalEventSummarys",
      passes: "passes"
    })
  },
  methods: {
    getPasses(passId) {
      return this.passes.filter(pass => pass.passId == passId);
    },
    getTotalUses(passId) {
      if (!this.passes || this.passes.length == 0) return 0;
      return this.passes
        .filter(pass => pass.passId == passId)
        .reduce((n, { usedCount }) => (n + usedCount ? usedCount : 0), 0);
    }
  }
};
</script>

<style>
.passresults {
  margin-bottom: 50px;
}
</style>
