var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "croppiecont" + _vm.id }, [
    !_vm.cropping
      ? _c(
          "div",
          {
            staticClass: "dropzone",
            on: {
              dragover: _vm.dragover,
              dragleave: _vm.dragleave,
              drop: _vm.croppie
            }
          },
          [
            _c("div", { staticClass: "columns is-vcentered" }, [
              _vm._m(0),
              _vm._m(1),
              _c("div", { staticClass: "column is-one-quarter" }, [
                _c("input", {
                  staticClass: "inputfile",
                  attrs: {
                    type: "file",
                    name: "file",
                    id: "file",
                    accept: "image/x-png,image/jpeg"
                  },
                  on: { change: _vm.croppie }
                }),
                _c(
                  "label",
                  { staticClass: "button upload", attrs: { for: "file" } },
                  [_vm._v(" Upload Image ")]
                )
              ])
            ])
          ]
        )
      : _vm._e(),
    _vm.cropping
      ? _c(
          "div",
          { staticClass: "croppiecont" },
          [
            _c("vue-croppie", {
              ref: "croppieRef" + _vm.id,
              attrs: {
                enableOrientation: true,
                enableResize: false,
                enforceBoundary: false,
                boundary: {
                  width: _vm.croppieWidth,
                  height: _vm.croppieHeight
                },
                viewport: {
                  width: _vm.cropWidth,
                  height: _vm.cropHeight,
                  type: "square"
                }
              }
            }),
            _c("div", { staticClass: "controlbar" }, [
              _c("div", { staticClass: "imagecancel" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-flex-mobile is-light",
                    on: { click: _vm.cancel }
                  },
                  [_vm._v(" Cancel ")]
                )
              ]),
              _c("div", { staticClass: "imagesave" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-light is-flex-mobile",
                    on: { click: _vm.crop }
                  },
                  [_vm._v(" Save Image ")]
                )
              ])
            ]),
            _c("div", { staticClass: "bumper" })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-2 placeholder-img" }, [
      _c("img", { attrs: { src: "/img/icon-image.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column" }, [
      _c("p", [
        _c("strong", [_vm._v("Drag + drop")]),
        _vm._v(" or click "),
        _c("strong", [_vm._v("upload")]),
        _vm._v(" to add an image. ")
      ]),
      _c("p", { staticClass: "subtext" }, [
        _vm._v(
          " Use a high quality image: minimum 800 x 360 px, JPG or PNG, no larger than 10MB. Will save to PNG. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }